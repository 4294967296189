import { useEffect } from "react";
import api from "../../api";
import Header from "../components/Header";
import { MainResponse } from "../types/me";
import Main from "./components/Main";

export default function Add() {
  useEffect(() => {
    (async () => {
      try {
        const response = await api.get<MainResponse>("/auth/me", {
          headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
        });
        if (response.data.userData.type !== "admin") {
          window.location.href = "/login";
        }
      } catch (err) {
        window.location.href = "/login";
      }
    })();
  }, []);
  return (
    <>
      <Header />
      <Main />
    </>
  );
}
