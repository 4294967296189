import {
  Box,
  Heading,
  HStack,
  Text,
  Link as LinkStyle,
} from "@chakra-ui/layout";
import { useEffect, useState } from "react";
import { FiBell } from "react-icons/fi";
import { Link } from "react-router-dom";
import Logo from "../../../../assets/img/conexaonr2_rgb-pq.png";
import api from "../../../api";
import { useLoading } from "../../Providers/Loading";

interface UserData {
  id: number;
  name: string;
  surname: string;
  phone: string;
  email: string;
  created_at: Date;
  updated_at: Date;
}

interface IUser {
  userData: UserData;
  credits: number;
}

export default function Header() {
  const [name, setName] = useState("");
  const [credits, setCredits] = useState<any>();

  useEffect(() => {
    (async () => {
      const response = await api.get<IUser>("/auth/me", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });

      setCredits(response.data.credits);

      setName(
        `${response.data.userData.name} ${response.data.userData.surname}`
      );
    })();
  }, []);

  return (
    <>
      <Box
        bgColor="#07282B"
        w="100%"
        d={["none", "none", "flex", "flex"]}
        justifyContent="center"
        alignContent="center"
        p={2}
      >
        <Box w="70%">
          <Box
            w="100%"
            d="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <a href="/">
              <img src={Logo} alt="Conexão nr" width="200px" height="43px" />
            </a>
            <Box
              w="100%"
              d="flex"
              ml={8}
              flexDirection="column"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box
                w="100%"
                d="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box></Box>

                <Box
                  d="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Heading color="white" size="sm">
                    {name}
                  </Heading>

                  <Text color="green.300">{""}</Text>
                </Box>

                <HStack d="flex" flexDirection="row">
                  <FiBell size={"25"} color="white" />
                  <Box
                    d="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    color="white"
                  >
                    <Text as="i">Créditos</Text>
                    <Text fontSize="sm">
                      {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(credits)}
                    </Text>
                  </Box>
                </HStack>
              </Box>
              <Box w="100%" d="flex" justifyContent="space-between">
                <LinkStyle
                  as={Link}
                  to="/dashboard"
                  href="#"
                  color="white"
                  _hover={{ textDecoration: "none" }}
                >
                  Demandas
                </LinkStyle>
                <LinkStyle
                  href="#"
                  color="white"
                  _hover={{ textDecoration: "none" }}
                  onClick={() => {
                    localStorage.clear();
                    window.location.href = "/login";
                  }}
                >
                  Sair
                </LinkStyle>
                <LinkStyle
                  as={Link}
                  to="/dashboard/pacotes"
                  href="#"
                  color="white"
                  _hover={{ textDecoration: "none" }}
                >
                  Obter créditos
                </LinkStyle>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        bgColor="#07282B"
        w="100%"
        d={["flex", "flex", "none", "none"]}
        justifyContent="center"
        alignContent="center"
        p={2}
      >
        <a href="/">
          <img src={Logo} alt="Conexão nr" width="200px" height="43px" />
        </a>
      </Box>
    </>
  );
}
