import { Box, Heading, Link } from "@chakra-ui/layout";
import { Alert, AlertIcon, Button, Input, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Logo from "../../assets/img/conexaonr_rgb_preferencial.png";
import api from "../api";
import Verify from "../components/AutoLogin";

export default function Login() {
  let history = useHistory();
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({ status: false, data: "" });

  useEffect(() => {
    (async () => {
      if (await Verify(localStorage.getItem("jwt") || "")) {
        history.push("/dashboard");
      }
    })();
  }, [history]);

  async function login(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setError({ status: false, data: "" });

    try {
      const response = await api.get("/auth", {
        auth: {
          username: name,
          password: password,
        },
      });
      localStorage.setItem("jwt", response.data.token);
      try {
        const response = await api.get("/auth/me", {
          headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
        });
        if (localStorage.getItem("service-redirect")) {
          const id = localStorage.getItem("service-redirect");
          localStorage.removeItem("service-redirect");
          window.location.href = `/dashboard/service/${id}`;
        }
        if (response.data.userData.type !== "admin") {
          window.location.href = "/dashboard";
        } else {
          window.location.href = "/admin";
        }
      } catch (err) {}
    } catch (err) {
      setError({ status: true, data: "Credenciais invalidas." });
    }
  }
  return (
    <>
      <form onSubmit={login}>
        <Box
          d="flex"
          w="100%"
          h="100vh"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          bgColor="#fff"
        >
          <a href="/">
            <img src={Logo} alt="Conexão nr" width="200px" height="43px" />
          </a>
          <Box
            d="flex"
            mt={2}
            flexDirection="column"
            alignItems="center"
            bgColor="gray.100"
            w={["80%", "80%", "70%", "45%", "35%"]}
            p={8}
          >
            <Box textAlign="center" w="100%" mb={2}>
              <Heading fontSize="sm">Seja bem-vindo(a) a Conexão NR</Heading>
              <Text fontSize="xs" mt={2}>
                Acesse sua conta.
              </Text>
            </Box>
            <Box alignItems="left" w="100%" mb={2}>
              <label
                style={{
                  alignItems: "left",
                  fontWeight: "bold",
                  fontSize: ".75rem",
                }}
                htmlFor="email"
              >
                E-mail
              </label>
            </Box>
            <Input
              placeholder="Seu e-mail"
              id="email"
              mb={2}
              value={name}
              onChange={(e: any) => {
                setName(e.target.value);
              }}
              bgColor="white"
            />

            <Box alignItems="left" w="100%" mb={2}>
              <label
                style={{
                  alignItems: "left",
                  fontWeight: "bold",
                  fontSize: ".75rem",
                }}
                htmlFor="password"
              >
                Senha
              </label>
            </Box>

            <Input
              placeholder="Senha"
              type="password"
              id="password"
              mb={6}
              value={password}
              onChange={(e: any) => {
                setPassword(e.target.value);
              }}
              bgColor="white"
            />

            <Box d="flex" justifyContent="space-between" w="100%" mb={4}>
              <Button
                w="30%"
                colorScheme="gray"
                color="black"
                onClick={() => {
                  window.location.href = "/";
                }}
              >
                Voltar
              </Button>
              <Button w="65%" colorScheme="blue" type="submit">
                Login
              </Button>
            </Box>
            <Link href="/recovery" mt={2} mb={2}>
              Esqueci minha senha
            </Link>
            <Text mt={2} textAlign="center">
              Ainda não é prestador da Conexão NR?
            </Text>
            <Link href="/signup">Cadastre-se agora</Link>
            {error.status && (
              <Alert mt={2} status="error" rounded="md">
                <AlertIcon />
                {error.data}
              </Alert>
            )}
          </Box>
        </Box>
      </form>
    </>
  );
}
