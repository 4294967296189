import { Box, Flex } from "@chakra-ui/layout";
import { Alert, AlertIcon } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../api";
import Loading from "../../components/Loading";
import Login from "../../Login";
import Header from "../components/HeaderEmail";
import ItemCard from "../Fornecedor/Item";

// import { Container } from './styles';

const ServiceView: React.FC = () => {
  const [success, setSuccess] = useState({ stats: false, data: "" });
  const [error, setError] = useState({ stats: false, data: "" });
  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState<any>();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get(`/companys/service/${id}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
        });
        let newData: any[] = [];
        const element = response.data;
        const response2 = await api.get("/subs", {
          params: { id: element.id },
        });
        newData = { ...element, ...response2.data };
        console.log(newData);

        setItem(newData);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (!localStorage.getItem("jwt")) {
    localStorage.setItem("service-redirect", id);
    return <Login />;
  }

  return (
    <>
      <Header />
      <Flex w="100%" justifyContent="center" mt={5}>
        <ItemCard setSuccess={setSuccess} setError={setError} item={item} />
        {success.stats && (
          <Alert
            status="success"
            position="fixed"
            top={2}
            right={2}
            rounded="md"
            w="auto"
          >
            <AlertIcon />
            {success.data}
          </Alert>
        )}
        {error.stats && (
          <Alert
            status="error"
            position="fixed"
            top={2}
            right={2}
            rounded="md"
            w="auto"
          >
            <AlertIcon />
            {error.data}
          </Alert>
        )}
      </Flex>
    </>
  );
};

export default ServiceView;
