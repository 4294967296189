import { Box, Flex, Heading, Text } from "@chakra-ui/layout";
import {
  Alert,
  AlertIcon,
  Button,
  Checkbox,
  CheckboxGroup,
  CircularProgress,
  Input,
  Select,
  useToast,
} from "@chakra-ui/react";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { FaQuestionCircle } from "react-icons/fa";
import api from "../api";
import Footer from "../components/Footer";
import Header from "../components/Header";
import uf from "../components/UF";

interface iArea {
  id: number;
  name: string;
  visible: boolean;
  created_at: Date;
  updated_at: Date;
}

interface iNrs {
  id: number;
  name: string;
  visible: boolean;
  description: string;
  created_at: Date;
  updated_at: Date;
}

interface iUF {
  sigla: string;
  nome: string;
  cidades: string[];
}

interface iSelectedStateAndUF {
  uf: string;
  city: string;
}

export default function ThirdState() {
  type NewType = MutableRefObject<HTMLDivElement>;

  const QuestionMark = useRef() as NewType;
  const [questionMark, setQuestionMark] = useState(false);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [UFAll, setUFAll] = useState<iUF[]>(uf);
  const [areas, setAreas] = useState<iArea[]>([]);

  const [nrs, setNrs] = useState<iNrs[]>([]);

  // selectbox
  const [selectBox, setSelectBox] = useState<any[]>([]);
  const [selectBoxFake, setSelectBoxFake] = useState<any[]>([]);
  const [checkAll, setCheckAll] = useState<boolean>();
  const [realySelectBoxOfNrs, setRealySelectBoxOfNrs] = useState<any[]>([]);
  const [realySelectedArea, setRealySelectedArea] = useState<any[]>([]);

  const [esocial, setESocial] = useState("");
  const [software, setSoftware] = useState("");
  const [OtherSoftwareOfUser, setOtherSoftwareOfUser] = useState<string>("");
  const [UF, setUF] = useState("");
  const [selectedArea, setSelectedArea] = useState<string>("");

  const [StateAndCitySelected, setStateAndCitySelected] = useState<
    iSelectedStateAndUF[]
  >([]);

  const [estadoIndex, setEstadoIndex] = useState<number>();
  const [cidades, setCidades] = useState<string[]>([]);
  const [todasCidades, setTodasCidades] = useState(false);
  const [estadosSelecionadosFinais, setEstadosSelecionadosFinais] = useState<
    string[]
  >([]);
  const [cidadesSelecionadas, setCidadesSelecionadas] = useState<any[]>([]);
  const [cidadesSelecionadasFinais, setCidadesSelecionadasFinais] = useState<
    string[]
  >([]);
  const [todoTeritorioNacional, setTodoTeritorioNacional] = useState(false);

  const [error, setError] = useState({ data: "", status: false });
  const [success, setSuccess] = useState({ data: "", status: false });

  const toast = useToast();

  function arrayDiff(a1: any[], a2: any[]) {
    var a = [],
      diff = [];

    for (var i = 0; i < a1.length; i++) {
      a[a1[i]] = true;
    }

    for (var i = 0; i < a2.length; i++) {
      if (a[a2[i]]) {
        delete a[a2[i]];
      } else {
        a[a2[i]] = true;
      }
    }

    for (var k in a) {
      diff.push(k);
    }

    return diff;
  }

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get<iArea[]>("/area");
        setAreas(response.data.reverse());
      } catch (err) {}
      try {
        const response = await api.get<iNrs[]>("/search/all");
        setNrs(response.data.reverse().sort((a, b) => a.id - b.id));
      } catch (err) {}
      setLoading(false);
    })();
  }, []);

  async function setSelectedAreasButton() {
    toast({
      title: "Adicionado com sucesso!",
      status: "success",
      position: "top-right",
      duration: 2000,
    });
    if (selectBox.length > 0)
      setRealySelectBoxOfNrs([...realySelectBoxOfNrs, ...selectBox]);
    setRealySelectedArea([...realySelectedArea, selectedArea]);
    console.log([...realySelectBoxOfNrs, ...selectBox]);
    console.log([...realySelectedArea, selectedArea]);
    setAreas(
      areas.filter((item) => {
        if (
          ![...realySelectedArea, selectedArea].includes(item.id.toString())
        ) {
          return item;
        }
      })
    );
    setSelectBox([]);
    setSelectedArea("");
  }

  async function FindNrsByArea(id: string | number) {
    try {
      const response = await api.get<iNrs[]>("/nrs/find", {
        params: {
          id: id,
        },
      });
      setNrs(response.data.reverse());
    } catch (err) {}
  }

  function pushStateAndCitySelected() {
    if (estadoIndex === undefined) {
      toast({
        title: "Selecione algum estado.",
        status: "error",
        position: "top-right",
        duration: 2000,
      });
      return;
    }

    if (cidadesSelecionadas.length < 1) {
      toast({
        title: "Selecione alguma cidade.",
        status: "error",
        position: "top-right",
        duration: 2000,
      });
      return;
    }

    toast({
      title: "Estado e cidade adicionados.",
      status: "success",
      position: "top-right",
      duration: 2000,
    });

    setEstadosSelecionadosFinais([
      ...estadosSelecionadosFinais,
      UFAll[estadoIndex].sigla,
    ]);
    setCidadesSelecionadasFinais([
      ...cidadesSelecionadasFinais,
      ...cidadesSelecionadas,
    ]);
    setUFAll(UFAll.filter((_, index) => index !== estadoIndex));
    setCidadesSelecionadas([]);
    setEstadoIndex(undefined);
    setTodasCidades(false);
  }

  function SuccessAlert(message: string): void {
    setSuccess({ status: true, data: message });
    setTimeout(() => {
      setSuccess({ status: false, data: "" });
    }, 2000);
  }

  // function ErrorAlert(message: string): void {
  //   setSuccess({ status: true, data: message });
  //   setTimeout(() => {
  //     setSuccess({ status: false, data: "" });
  //   }, 2000);
  // }

  function ValidateInput(): Boolean {
    if (realySelectedArea.length === 0) {
      toast({
        title: "Selecione qual área sua empresa atua!",
        status: "error",
        position: "top-right",
        duration: 2000,
      });
      return false;
    }
    if (realySelectBoxOfNrs.length === 0) {
      setSelectBox(["null"]);
    }
    if (esocial.length === 0) {
      setError({
        data: "Selecione se você  e/ou sua empresa possuiem sistema informatizado de gestão da saúde e segurança do trabalho que atenda o e-Social.",
        status: true,
      });
      return false;
    }

    if (
      cidadesSelecionadasFinais.length === 0 ||
      estadosSelecionadosFinais.length === 0
    ) {
      toast({
        title: "Selecione quais estados e cidades sua empresa atende!",
        status: "error",
        position: "top-right",
        duration: 2000,
      });

      return false;
    }

    return true;
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event!.preventDefault();
    console.log("?");
    console.log(realySelectedArea);
    console.log(realySelectBoxOfNrs);
    setError({
      data: "",
      status: false,
    });

    const ufs: string[] = [];
    const city: string[] = [];
    if (UF === "All") {
      setStateAndCitySelected([{ city: "all", uf: UF }]);
    }
    for (let i = 0; i < StateAndCitySelected.length; i++) {
      const element = StateAndCitySelected[i];
      ufs.push(element.uf);
      city.push(element.city);
    }

    if (ValidateInput()) {
      if (software !== "Other") {
        try {
          await api.post(
            "/company/detail",
            {
              area: realySelectedArea.toString(),
              nrs: realySelectBoxOfNrs.toString(),
              esocial: esocial,
              software: software,
              uf: estadosSelecionadosFinais.toString(),
              city: cidadesSelecionadasFinais.toString(),
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            }
          );
          toast({
            title: "Adicionado com sucesso!",
            status: "success",
            position: "top-right",
            duration: 2000,
          });
          setTimeout(() => {
            window.location.href = "/dashboard";
          }, 2500);
        } catch (err: any) {
          console.log(err.response);
        }
      } else {
        try {
          await api.post(
            "/company/detail",
            {
              area: realySelectedArea.toString(),
              nrs: realySelectBoxOfNrs.toString(),
              esocial: esocial,
              software: OtherSoftwareOfUser.toString(),
              uf: ufs.toString(),
              city: city.toString(),
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            }
          );
          setSuccess({ data: "Adicionado com sucesso", status: true });
        } catch (err: any) {
          console.log(err.response);
        }
      }
    }
  }

  function FindCitysByState(index: number) {
    setEstadoIndex(index);
    console.log(UFAll[index].nome);
    setCidades(UFAll[index].cidades);
  }

  if (loading) {
    return (
      <>
        <Box
          w="100%"
          h="100vh"
          d="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress isIndeterminate color="green.700" />
        </Box>
      </>
    );
  }

  return (
    <>
      <Header />
      <form onSubmit={handleSubmit}>
        <Box
          p={2}
          as="main"
          d="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          w="100%"
          h="100%"
          position="relative"
        >
          {questionMark && (
            <Box w="100%" d="block" h="100%">
              <Box
                mt={6}
                p={2}
                w={["90%", "60%", "35%", "20%"]}
                bgColor="gray.100"
                rounded="md"
                d="block"
                position="absolute"
                zIndex="1"
                top={QuestionMark.current?.getBoundingClientRect().top}
                left={QuestionMark.current?.getBoundingClientRect().left}
              >
                Selecione apenas as áreas que possui capacidade/formação técnica
                para atender plenamente. Caso tenha alguma dúvida, entre em
                contato conosco.
              </Box>
            </Box>
          )}
          <Box
            bgColor="gray.100"
            w={["90%", "100%", "90%", "50%"]}
            d="flex"
            justifyContent="center"
            flexDirection="column"
            p={4}
            rounded="md"
          >
            <Box>
              <Box
                d="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="row"
                mb={4}
              >
                <Heading size="sm" mr={2} mt={2} textAlign="center">
                  Selecione abaixo todas as áreas que você e/ou sua empresa
                  prestam serviços (pode ser mais de uma)
                </Heading>
                <Box
                  w="auto"
                  h="auto"
                  ref={QuestionMark}
                  onMouseOver={() => {
                    setQuestionMark(true);
                  }}
                  onMouseOut={() => {
                    setQuestionMark(false);
                  }}
                >
                  <FaQuestionCircle />
                </Box>
              </Box>
              <label
                htmlFor="area"
                style={{ fontWeight: "bold", fontSize: ".75rem" }}
              >
                Área
              </label>
              <Select
                id="area"
                value={selectedArea}
                onChange={(e) => {
                  setSelectedArea(e.target.value);
                  FindNrsByArea(e.target.value);
                }}
                placeholder="Selecione"
                bgColor="white"
              >
                {areas.map((item) => (
                  <>
                    {item.visible === true && (
                      <option value={item.id} key={item.name}>
                        {item.name}
                      </option>
                    )}
                  </>
                ))}
              </Select>
            </Box>
            {selectedArea && nrs.length > 0 && (
              <>
                <Box mt={2} mb={4}>
                  <Text style={{ fontWeight: "bold", fontSize: ".85rem" }}>
                    Selecione quais Normas Regulamentadoras (NRs) que você e/ou
                    sua empresa possui(em) capacidade técnica para atendimento.
                  </Text>
                  <Box rounded="md" bgColor="white" p={2} mt={2}>
                    <Box as={Box} h={"sm"} overflowY="auto">
                      <Box mb={2}>
                        <Checkbox
                          isChecked={checkAll}
                          onChange={(e) => {
                            if (checkAll) {
                              setCheckAll(!checkAll);
                              setSelectBox([]);
                              setSelectBoxFake([]);
                            } else {
                              const all = nrs
                                .filter((item) => item.visible)
                                .map((item) => item.id.toString());
                              setCheckAll(!checkAll);
                              setSelectBox(all);
                              setSelectBoxFake(all);
                            }
                          }}
                          children="Todas NRs"
                        />
                      </Box>
                      <CheckboxGroup
                        value={selectBoxFake}
                        onChange={(e) => {
                          const checkedall =
                            arrayDiff(
                              nrs
                                .filter((item) => item.visible)
                                .map((item) => item.id.toString()),
                              e
                            ).length === 0
                              ? true
                              : false;
                          console.log(checkedall);
                          setCheckAll(checkedall);
                          setSelectBox(e);
                          setSelectBoxFake(e);
                        }}
                      >
                        {nrs.map((item) => (
                          <>
                            {item.visible === true && (
                              <Box mb={2} key={item.id}>
                                <Checkbox
                                  value={item.id.toString()}
                                  children={item.name}
                                />
                              </Box>
                            )}
                          </>
                        ))}
                      </CheckboxGroup>
                    </Box>
                  </Box>{" "}
                </Box>
              </>
            )}
            <Box>
              {selectedArea && (
                <>
                  <Button
                    colorScheme="blue"
                    mt={4}
                    mb={2}
                    onClick={setSelectedAreasButton}
                  >
                    Adicionar
                  </Button>
                </>
              )}
            </Box>
            <Box mt={6} mb={4}>
              <Text style={{ fontWeight: "bold", fontSize: ".85rem" }}>
                Você e/ou sua empresa possui(em) sistema para atendimento ao
                e-Social?
              </Text>
              <Select
                bgColor="white"
                mt={2}
                onChange={(e) => {
                  setESocial(e.target.value);
                  console.log(e.target.value);
                }}
                placeholder="Selecione"
                required
              >
                <option value="Sim">Sim</option>
                <option value="Não, porém pretendo adquirir">
                  Não, porém pretendo adquirir
                </option>
                <option value="Não, e não pretendo adquirir">
                  Não, e não pretendo adquirir
                </option>
              </Select>
            </Box>
            {esocial === "Sim" && (
              <Box mt={2} mb={4}>
                <Text style={{ fontWeight: "bold", fontSize: ".85rem" }}>
                  Qual sistema você e/ou sua empresa utiliza ou pretende
                  utilizar?
                </Text>
                <Select
                  bgColor="white"
                  mt={2}
                  required
                  onChange={(e) => {
                    setSoftware(e.target.value);
                  }}
                >
                  <option selected disabled>
                    Selecione
                  </option>
                  <option value="Sistema próprio">Sistema próprio</option>
                  <option value="Atento">Atento</option>
                  <option value="Fazsoft SMT">Fazsoft SMT</option>
                  <option value="Madu">Madu</option>
                  <option value="Medget">Medget</option>
                  <option value="Metra Web">Metra Web</option>
                  <option value="Nexo CS">Nexo CS</option>
                  <option value="Peoplenet">Peoplenet</option>
                  <option value="Personal Med (Totvs)">
                    Personal Med (Totvs)
                  </option>
                  <option value="Plustrade">Plustrade</option>
                  <option value="Sesmo">Sesmo</option>
                  <option value="SGG">SGG</option>
                  <option value="Sigo w3 Business">Sigo w3 Business</option>
                  <option value="SOC">SOC</option>
                  <option value="Não sei informar">Não sei informar</option>
                  <option value="Other">Outro</option>
                </Select>
              </Box>
            )}
            {esocial === "Não, porém pretendo adquirir" && (
              <Box mt={2}>
                <Text style={{ fontWeight: "bold", fontSize: ".85rem" }}>
                  Qual sistema você e/ou sua empresa utiliza ou pretende
                  utilizar?
                </Text>
                <Select
                  bgColor="white"
                  mt={2}
                  required
                  onChange={(e) => {
                    setSoftware(e.target.value);
                  }}
                >
                  <option selected disabled>
                    Selecione
                  </option>
                  <option value="Sistema próprio">Sistema próprio</option>
                  <option value="Atento">Atento</option>
                  <option value="Fazsoft SMT">Fazsoft SMT</option>
                  <option value="Madu">Madu</option>
                  <option value="Medget">Medget</option>
                  <option value="Metra Web">Metra Web</option>
                  <option value="Nexo CS">Nexo CS</option>
                  <option value="Peoplenet">Peoplenet</option>
                  <option value="Personal Med (Totvs)">
                    Personal Med (Totvs)
                  </option>
                  <option value="Plustrade">Plustrade</option>
                  <option value="Sesmo">Sesmo</option>
                  <option value="SGG">SGG</option>
                  <option value="Sigo w3 Business">Sigo w3 Business</option>
                  <option value="SOC">SOC</option>
                  <option value="Não sei informar">Não sei informar</option>
                  <option value="Other">Outro</option>
                </Select>
              </Box>
            )}

            {software === "Other" && (
              <Box mt={2} mb={4}>
                <Text mb={2} style={{ fontWeight: "bold", fontSize: ".85rem" }}>
                  Informe o nome do sistema
                </Text>
                <Input
                  value={OtherSoftwareOfUser}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setOtherSoftwareOfUser(e.target.value);
                  }}
                  bgColor="white"
                  required
                />
              </Box>
            )}
            <Box mt={2} p={2} bgColor="white" rounded="md">
              <Text style={{ fontWeight: "bold", fontSize: ".85rem" }}>
                Informe as regiões que você e/ou sua empresa atende(m)
                plenamente:
              </Text>
              <Checkbox
                mt={2}
                name="AllNacional"
                defaultChecked={todoTeritorioNacional}
                onChange={async (e) => {
                  if (e.target.checked === true) {
                    setTodoTeritorioNacional(!todoTeritorioNacional);
                    setEstadosSelecionadosFinais(["All"]);
                    setCidadesSelecionadasFinais(["All"]);
                  } else {
                    setTodoTeritorioNacional(!todoTeritorioNacional);
                    setEstadosSelecionadosFinais([]);
                    setCidadesSelecionadasFinais([]);
                    setUFAll(uf);
                  }
                }}
              >
                Todo o Território Nacional
              </Checkbox>
              {!todoTeritorioNacional && (
                <>
                  <Box mt={6}>
                    <Text fontWeight="bold" fontSize=".85rem">
                      Estado
                    </Text>
                    <Select
                      mt={2}
                      placeholder="Selecione"
                      value={estadoIndex}
                      onChange={(e) => FindCitysByState(Number(e.target.value))}
                    >
                      {UFAll.map((item, index) => (
                        <option value={index}>{item.nome}</option>
                      ))}
                    </Select>
                  </Box>
                  {estadoIndex !== undefined && (
                    <Box px={0} mt={4}>
                      <Text fontWeight="bold" fontSize=".85rem">
                        Cidades
                      </Text>
                      <Box mt={2} maxH="20vh" overflowY="scroll">
                        <CheckboxGroup
                          colorScheme="green"
                          defaultValue={cidadesSelecionadas}
                          onChange={(e) => {
                            if (e[e.length - 1] === "All") {
                              setCidadesSelecionadas(["All"]);
                            } else {
                              setCidadesSelecionadas(e);
                            }
                          }}
                        >
                          <Checkbox
                            value="All"
                            onChange={() => {
                              setTodasCidades(!todasCidades);
                            }}
                          >
                            Todas cidades
                          </Checkbox>
                          {!todasCidades && (
                            <Flex flexDir="column">
                              {cidades &&
                                cidades.map((item) => (
                                  <Checkbox value={item}>{item}</Checkbox>
                                ))}
                            </Flex>
                          )}
                        </CheckboxGroup>
                      </Box>
                    </Box>
                  )}
                  <Box mt={4}>
                    <Button
                      colorScheme="blue"
                      onClick={() => pushStateAndCitySelected()}
                    >
                      Adicionar
                    </Button>
                  </Box>
                </>
              )}
            </Box>
            <Box
              w="100%"
              d="flex"
              justifyContent="right"
              alignItems="revert"
              mt={4}
            >
              <Button type="submit" colorScheme="blue" mr={2}>
                Salvar
              </Button>
              <Button
                bgColor="gray.400"
                colorScheme="gray"
                color="white"
                onClick={() => {
                  window.location.href = "/second-state";
                }}
                _hover={{ backgroundColor: "gray.600" }}
              >
                Cancelar
              </Button>
            </Box>
          </Box>
          {error.status && (
            <Box position="fixed" right={2} top={20}>
              <Alert status="error" mt={2}>
                <AlertIcon />
                {error.data}
              </Alert>
            </Box>
          )}
          {success.status && (
            <Box position="fixed" right={2} top={20}>
              <Alert
                status="success"
                sx={{ transition: "all 0.4s ease" }}
                mt={2}
              >
                <AlertIcon />
                {success.data}
              </Alert>
            </Box>
          )}
        </Box>
      </form>
      <Footer />
    </>
  );
}
