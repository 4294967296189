import { Box, Link, Text } from "@chakra-ui/layout";
import { Button, Input } from "@chakra-ui/react";
import { AxiosResponse } from "axios";
import { useState } from "react";
import InputMask from "react-input-mask";
import { useHistory } from "react-router-dom";
import API from "../api/index";
import Footer from "../components/Footer";
import Header from "../components/Header";

interface Response {
  token: string;
}

export default function Register() {
  let history = useHistory();
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [phoneDDD, setPhoneDDD] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState({ data: "", status: false });

  async function validationInputs(): Promise<Boolean> {
    const validEmail = RegExp("@");
    const phone = `${phoneDDD}${phoneNumber}`;
    if (!validEmail.test(email)) {
      setError({
        data: "E-mail invalido!",
        status: true,
      });
      return false;
    }
    if (phone.length < 12 || phone.length > 12) {
      setError({
        data: "Numero de telefone invalido!",
        status: true,
      });
      return false;
    }
    if (password !== confirmPassword) {
      setError({
        data: "Suas senhas não são iguais invalido!",
        status: true,
      });
      return false;
    }

    if (password.length < 6) {
      setError({
        data: "A senha deve conter no mínimo 6 caracteres",
        status: true,
      });
      return false;
    }

    return true;
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setError({
      data: "",
      status: false,
    });
    const phone = `${phoneDDD}${phoneNumber}`;
    localStorage.setItem("ddd", phoneDDD);
    localStorage.setItem("phone-number", phoneNumber);
    if (await validationInputs()) {
      try {
        const response: AxiosResponse<Response> = await API.post("/auth", {
          name,
          surname,
          phone,
          email,
          password,
        });

        localStorage.setItem("jwt", response.data.token);
        history.push("/second-state");
      } catch (err: any) {
        if (err.response.data.data === "User already exists.") {
          setError({ data: "Usuário já existe", status: true });
        }
      }
    }
  }

  return (
    <>
      <Header />
      <Box
        d="flex"
        w="100%"
        flexDirection="column"
        alignItems="center"
        mt={6}
        mb={6}
      >
        <Text
          sx={{ fontWeight: "bold", textAlign: "center" }}
          fontSize={["sm", "md", "md", "md", "md", "md"]}
        >
          Bem-vindo(a) a Conexão NR
        </Text>

        <Text sx={{ fontWeight: "bold", textAlign: "center" }}>
          Cadastre-se e receba gratuitamente Pedidos de Orçamento
        </Text>

        <Box w={["90%", "50%"]} bgColor="gray.100" mt={4} p={6} rounded="md">
          <form onSubmit={handleSubmit}>
            <Box>
              <label
                htmlFor="name"
                style={{ fontWeight: "bold", fontSize: ".75rem" }}
              >
                Nome
              </label>
              <Input
                required
                bgColor="white"
                id="name"
                maxlength="50"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Box>
            <Box>
              <label
                htmlFor="surname"
                style={{ fontWeight: "bold", fontSize: ".75rem" }}
              >
                Sobrenome
              </label>
              <Input
                required
                bgColor="white"
                id="surname"
                maxlength="50"
                value={surname}
                onChange={(e) => {
                  setSurname(e.target.value);
                }}
              />
            </Box>
            <Box>
              <label
                htmlFor="phone"
                style={{ fontWeight: "bold", fontSize: ".75rem" }}
              >
                Telefone
              </label>
              <Box w="100%" d="flex">
                <Input
                  required
                  w={["18%", "18%"]}
                  mr={2}
                  bgColor="white"
                  as={InputMask}
                  mask="99"
                  id="phone"
                  value={phoneDDD}
                  onChange={(e) => {
                    setPhoneDDD(e.target.value);
                  }}
                />
                <Input
                  required
                  w={["100%", "100%"]}
                  bgColor="white"
                  as={InputMask}
                  mask="99999-9999"
                  id="phone"
                  value={phoneNumber}
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                  }}
                />
              </Box>
            </Box>
            <Box>
              <label
                htmlFor="email"
                style={{ fontWeight: "bold", fontSize: ".75rem" }}
              >
                E-mail
              </label>
              <Input
                required
                bgColor="white"
                id="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Box>
            <Box>
              <label
                htmlFor="password"
                style={{ fontWeight: "bold", fontSize: ".75rem" }}
              >
                Senha
              </label>
              <Input
                required
                bgColor="white"
                id="password"
                type="password"
                maxlength="10"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </Box>
            <Box>
              <label
                htmlFor="confirm-password"
                style={{ fontWeight: "bold", fontSize: ".75rem" }}
              >
                Confirmar Senha
              </label>
              <Input
                required
                bgColor="white"
                type="password"
                id="confirm-password"
                maxlength="10"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
              />
            </Box>
            <Box mb={2} mt={4}>
              <Text fontSize="sm">
                Ao clicar em “Criar Conta” você estará concordando com a{" "}
                <Link href="#" color="blue">
                  Política de Privacidade e com os Termos e Condições Gerais de
                  Uso
                </Link>{" "}
                da Conexão NR
              </Text>
            </Box>
            <Box d="flex" justifyContent="right" w="100%">
              <Button colorScheme="blue" type="submit">
                Criar Conta
              </Button>
            </Box>
            {error.status && (
              <>
                <Text color="red">{error.data}</Text>
              </>
            )}
          </form>
        </Box>
      </Box>
      <Footer />
    </>
  );
}
