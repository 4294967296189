import { Box, Flex } from "@chakra-ui/layout";
import { Button, Collapse, Image, Text } from "@chakra-ui/react";
import React, { ReactNode, useState } from "react";
import closeSquare from "./close-square.svg";
import openSquare from "./open-square.svg";

// import { Container } from './styles';

type CardProps = {
  children: ReactNode;
  name?: string;
};

const CardDePerguntas: React.FC<CardProps> = ({ name, children }) => {
  const [toggle, setToggle] = useState(false);

  return (
    <Box w="100%" p={4} shadow="base" rounded="md">
      <Flex alignItems="center">
        <Button
          bg="transparent"
          px="0"
          _hover={{}}
          _focus={{}}
          _active={{}}
          onClick={() => {
            toggle ? setToggle(false) : setToggle(true);
          }}
        >
          <Image
            transition="all ease .3s"
            w="32px"
            h="32px"
            src={toggle ? closeSquare : openSquare}
          />
        </Button>

        <Text ml={2} fontSize="1xl" fontWeight="bold">
          {name}
        </Text>
      </Flex>
      <Collapse in={toggle} animateOpacity>
        <Box>{children}</Box>
      </Collapse>
    </Box>
  );
};

export default CardDePerguntas;
