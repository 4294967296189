import { Box, Flex, HStack } from "@chakra-ui/layout";
import { IconButton, Table, Tbody, Td, Thead, Tr } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BiFolder } from "react-icons/bi";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import api from "../../api";
import Header from "../components/Header";

export function DemandasInscritas() {
  const MySwal = withReactContent(Swal);
  const [demandas, setDemandas] = useState([]);

  useEffect(() => {
    api
      .get("/subs/show", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        setDemandas(response.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Header />
      <Flex w="100%" justifyContent="center" mt={4}>
        <Box w="100%" maxW="1200px">
          <Table variant="striped">
            <Thead>
              <Tr>
                <Td>Nome</Td>
                <Td>Ação</Td>
              </Tr>
            </Thead>
            <Tbody>
              {demandas.map((item: any) => (
                <>
                  <Tr>
                    <Td>
                      {item.user_name} {item.user_surname} -{" "}
                      {item.corporate_name}
                    </Td>

                    <Td>
                      <HStack spacing={2}>
                        <IconButton
                          colorScheme="green"
                          aria-label="View"
                          onClick={() => {
                            MySwal.fire(
                              `${item.user_name} ${item.user_surname} - ${item.corporate_name}`,
                              `
                            Email: ${item.email}
                            <br/>
                            Telefone: ${item.phone}
                            <br/> 
                            Localização: ${item.street} / ${item.uf} - ${
                                item.city
                              }
                            <br/>
                            Departamento do contratante: ${item.department_name}
                            <br/>
                            Cargo do contratante: ${
                              item.role ? item.role : item.department_name
                            }
                            <br/>
                            Numero de colaboradores: ${item.number_of_employees}
                            <br/>
                            Tipo de empresa: ${item.company_type}
                            <br/>
                            Ramo da empresa: ${item.branch_of_the_company}
                            <br/>
                            Observação: ${item.about}
                            <br/>
                            Serviços: ${item.nrs.map(
                              (item: string) => `<br/>${item}`
                            )}
                            <br/> 
                            Urgência: ${item.urgency}
                            `,
                              "info"
                            );
                          }}
                          icon={<BiFolder />}
                        />
                      </HStack>
                    </Td>
                  </Tr>
                </>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Flex>
    </>
  );
}
