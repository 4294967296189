import { CloseIcon } from "@chakra-ui/icons";
import { Box, Heading, HStack } from "@chakra-ui/layout";
import {
  Alert,
  AlertIcon,
  Button,
  IconButton,
  Input,
  Table,
  Tbody,
  Td,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BiEdit, BiTrash } from "react-icons/bi";
import api from "../../../api";
import Pagination from "./components/Pagination";

interface Meta {
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
  first_page: number;
  first_page_url: string;
  last_page_url: string;
  next_page_url: string;
  previous_page_url?: any;
}

interface Datum {
  id: number;
  name: string;
  created_at: Date;
  updated_at: Date;
  status: boolean;
}

interface Data {
  meta: Meta;
  data: Datum[];
}

interface Page {
  url: string;
  page: number;
  isActive: boolean;
}

interface Areas {
  data: Data;
  pages: Page[];
}

export default function Main() {
  const [areas, setAreas] = useState<Datum[]>([]);
  const [BackUPareas, setBackUPareas] = useState<Datum[]>([]);
  const [pages, setPages] = useState<Page[]>([]);
  const [error] = useState({ status: false, data: "" });
  const [success, setSuccess] = useState({ status: false, data: "" });
  const [confirmDelete, setConfirmDelete] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get<Areas>("/admin/area", {
          headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
          params: { page: 1 },
        });

        const data: Datum[] = [];
        for (let i = 1; i <= response.data.pages.length; i++) {
          try {
            const response2 = await api.get<Areas>("/admin/area", {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
              params: { page: i },
            });

            for (let j = 0; j < response2.data.data.data.length; j++) {
              const element = response2.data.data.data[j];
              data.push(element);
            }
          } catch (err) {}
        }
        setAreas(response.data.data.data);
        setBackUPareas(data);
        setPages(response.data.pages);
      } catch (err) {}
    })();
  }, []);

  useEffect(() => {}, []);

  async function deleteArea() {
    try {
      await api.delete("/admin/area", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          id: localStorage.getItem("areaId"),
        },
      });
      setSuccess({ status: true, data: "Área deletada com sucesso!" });
      const response = await api.get<Areas>("/admin/area", {
        headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
        params: { page: 1 },
      });
      setAreas(response.data.data.data);
      setBackUPareas(response.data.data.data);
      window.location.reload();
      // setTimeout(() => {
      //   setSuccess({ status: false, data: "" });
      // }, 1800);
    } catch (err) {}
  }

  async function Searching(name: string) {
    if (name !== "") {
      const data: Datum[] = [];
      for (let i = 0; i < BackUPareas.length; i++) {
        const element = BackUPareas[i];
        if (element.name.toLowerCase().indexOf(name.toLowerCase()) > -1) {
          data.push(element);
        }
      }
      setAreas(data);
    } else {
      const response = await api.get<Areas>("/admin/area", {
        headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
        params: { page: 1 },
      });
      setAreas(response.data.data.data);
    }
  }

  return (
    <Box
      as="main"
      position="relative"
      overflow="hidden"
      h="100%"
      minH={["100vh"]}
    >
      <Box p={[0, 4]}>
        <Heading p={[1, 1]}>Área</Heading>
        <Box mt={2}>
          <Box d="flex" justifyContent="space-between">
            <Box
              w={["60%", "60%", "20%", "20%"]}
              d="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Input
                w="100%"
                placeholder="Nome da área"
                onChange={(e) => {
                  Searching(e.target.value);
                }}
              />
            </Box>
            <Button
              colorScheme="green"
              w={["16%", "16%", "8%", "8%"]}
              onClick={() => {
                window.location.href = "/admin/area/add";
              }}
            >
              Novo
            </Button>
          </Box>
          <Box w="100%" overflow="auto">
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Td>Nome</Td>
                  <Td>Situação</Td>
                  <Td>Ação</Td>
                </Tr>
              </Thead>
              <Tbody>
                {areas.map((item) => (
                  <Tr>
                    <Td>{item.name}</Td>
                    <Td>
                      {item.status === true && "Ativo"}
                      {!item.status && "Inativo"}
                    </Td>
                    <Td>
                      <HStack spacing={2}>
                        <IconButton
                          colorScheme="green"
                          aria-label="Edit"
                          onClick={() => {
                            localStorage.setItem("itemId", `${item.id}`);
                            window.location.href = "/admin/area/edit";
                          }}
                          icon={<BiEdit />}
                        />
                        <IconButton
                          colorScheme="red"
                          aria-label="Delete"
                          onClick={() => {
                            setConfirmDelete(true);
                            localStorage.setItem("areaId", `${item.id}`);
                          }}
                          icon={<BiTrash />}
                        />
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>

            {success.status && (
              <Alert
                status="success"
                mt={2}
                position="absolute"
                w="20%"
                top={2}
                right={2}
              >
                <AlertIcon />
                {success.data}
              </Alert>
            )}

            {error.status && (
              <Alert
                status="error"
                mt={2}
                position="absolute"
                w="20%"
                top={2}
                right={2}
              >
                <AlertIcon />
                {error.data}
              </Alert>
            )}
          </Box>
          <Box d="flex" justifyContent="right">
            <Pagination pages={pages} setAreas={setAreas} />
          </Box>
        </Box>
      </Box>
      {confirmDelete && (
        <Box
          position="fixed"
          top={0}
          w="100vw"
          height="100%"
          bgColor="rgba(0,0,0,0.3)"
          d="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            bgColor="white"
            p={8}
            rounded="md"
            alignItems="center"
            d="flex"
            flexDirection="column"
          >
            <Box
              sx={{ border: "4px solid #E53E53", borderRadius: "50%" }}
              mb={6}
            >
              <CloseIcon color="red.500" w={16} h={16} m={4} />
            </Box>
            <Heading size="md">
              Tem certeza que deseja excluir o registro?
            </Heading>
            <HStack mt={2}>
              <Button
                colorScheme="gray"
                onClick={() => {
                  setConfirmDelete(false);
                }}
              >
                Cancelar
              </Button>
              <Button colorScheme="red" onClick={deleteArea}>
                Excluir
              </Button>
            </HStack>
          </Box>
        </Box>
      )}
    </Box>
  );
}
