import { CloseIcon } from "@chakra-ui/icons";
import { Box, Heading, HStack } from "@chakra-ui/layout";
import {
  Button,
  IconButton,
  Input,
  Table,
  Tbody,
  Td,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useState } from "react";
import { useEffect } from "react";
import { BiEdit, BiTrash } from "react-icons/bi";
import api from "../../api";
import Header from "../components/Header";
import Pagination from "./components/Pagination";
import { Datum, Page, RootObject } from "./types/response";

export default function Credits() {
  const [page, setPage] = useState<Page[]>([]);
  const [BackupPage, setBackupPage] = useState<Datum[]>([]);
  const [itens, setItens] = useState<Datum[]>([]);
  const [confirmExclude, setConfirmDelete] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get("/auth/me", {
          headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
        });
        if (response.data.userData.type !== "admin") {
          window.location.href = "/login";
        }
      } catch (err) {
        window.location.href = "/login";
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get<RootObject>("/packages", {
          params: { page: 1 },
        });

        const data: Datum[] = [];

        for (let i = 1; i <= response.data.pages.length; i++) {
          try {
            const response2 = await api.get<RootObject>("/packages", {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
              params: { page: i },
            });

            for (let j = 0; j < response.data.data.data.length; j++) {
              const element = response2.data.data.data[j];
              data.push(element);
            }
          } catch (err) {}
        }

        setBackupPage(data);
        setPage(response.data.pages);
        setItens(response.data.data.data);
      } catch (err) {}
    })();
  }, []);

  async function Searching(name: string) {
    if (name !== "") {
      const data: Datum[] = [];
      for (let i = 0; i < BackupPage.length; i++) {
        const element = BackupPage[i];
        if (element.name.toLowerCase().indexOf(name.toLowerCase()) > -1) {
          data.push(element);
        }
      }
      setItens(data);
    } else {
      const response = await api.get<RootObject>("/packages", {
        headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
        params: { page: 1 },
      });
      setItens(response.data.data.data);
    }
  }

  const deleteCredit = async () => {
    try {
      await api.delete("/admin/packages", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          id: `${localStorage.getItem("deleteCredit")}`,
        },
      });
      window.location.reload();
    } catch (err) {}
  };

  return (
    <>
      <Header />
      <Box as="main" position="relative">
        <Box m={[1, 4]}>
          <Heading>Crédito</Heading>
          <Box mt={8}>
            <Box d="flex" justifyContent="space-between">
              <Box
                w={["60%", "60%", "20%", "20%"]}
                d="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Input
                  w="100%"
                  placeholder="Nome do Pacote"
                  onChange={(e) => {
                    Searching(e.target.value);
                  }}
                />
              </Box>
              <Button
                colorScheme="green"
                w={["16%", "16%", "8%", "8%"]}
                onClick={() => {
                  window.location.href = "/admin/credits/add";
                }}
              >
                Novo
              </Button>
            </Box>

            <Table variant="striped">
              <Thead>
                <Tr>
                  <Td>Nome do Pacote</Td>
                  <Td>Situação</Td>
                  <Td>Ação</Td>
                </Tr>
              </Thead>
              <Tbody>
                {itens.map((item) => (
                  <>
                    <Tr>
                      <Td>{item.name}</Td>
                      <Td>{item.status ? "Ativo" : "Inativo"}</Td>
                      <Td>
                        <HStack spacing={2}>
                          <IconButton
                            colorScheme="green"
                            aria-label="Edit"
                            onClick={() => {
                              localStorage.setItem("creditId", `${item.id}`);
                              window.location.href = "/admin/credits/edit";
                            }}
                            icon={<BiEdit />}
                          />
                          <IconButton
                            colorScheme="red"
                            aria-label="Delete"
                            onClick={() => {
                              localStorage.setItem(
                                "deleteCredit",
                                `${item.id}`
                              );
                              setConfirmDelete(true);
                            }}
                            icon={<BiTrash />}
                          />
                        </HStack>
                      </Td>
                    </Tr>
                  </>
                ))}
              </Tbody>
            </Table>
          </Box>
          <Box d="flex" justifyContent="right" w="100%">
            <Pagination pages={page} setAreas={setItens} />
          </Box>
        </Box>
      </Box>
      {confirmExclude && (
        <Box
          position="fixed"
          top={0}
          w="100vw"
          height="100%"
          bgColor="rgba(0,0,0,0.3)"
          d="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            bgColor="white"
            p={8}
            rounded="md"
            alignItems="center"
            d="flex"
            flexDirection="column"
          >
            <Box
              sx={{ border: "4px solid #E53E53", borderRadius: "50%" }}
              mb={6}
            >
              <CloseIcon color="red.500" w={16} h={16} m={4} />
            </Box>
            <Heading size="md">
              Tem certeza que deseja excluir o registro?
            </Heading>
            <HStack mt={2}>
              <Button
                colorScheme="gray"
                onClick={() => {
                  setConfirmDelete(false);
                }}
              >
                Cancelar
              </Button>
              <Button colorScheme="red" onClick={deleteCredit}>
                Excluir
              </Button>
            </HStack>
          </Box>
        </Box>
      )}
    </>
  );
}
