import { WarningTwoIcon } from "@chakra-ui/icons";
import { Box, Heading, HStack } from "@chakra-ui/layout";
import { Alert, AlertIcon, Button, Input, Select } from "@chakra-ui/react";
import { useEffect } from "react";
import { useState } from "react";
import api from "../../../../../api";

interface Area {
  id: number;
  name: string;
  status: boolean;
  created_at: Date;
  updated_at: Date;
}

export default function Main() {
  const [name, setName] = useState("");
  const [area, setArea] = useState<Area[]>([]);
  const [selectedArea, setSelectedArea] = useState("default");
  const [MessageCancel, setMessageCancel] = useState(false);

  const [error, setError] = useState({ status: false, data: "" });

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get<Area[]>("/area", {
          headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
        });

        setArea(response.data);
      } catch (err) {}
    })();
  }, []);

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (selectedArea === "default") {
      setError({ status: true, data: "Selecione uma área" });
      return;
    }

    try {
      if (selectedArea !== "default") {
        await api.post(
          "/admin/nrs",
          { name, area_id: selectedArea },
          {
            headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
          }
        );

        window.location.href = "/admin/nrs";
      } else {
        setError({ status: true, data: "Selecione uma Área" });
      }
    } catch (err) {}
  }

  return (
    <Box
      d={["flex", "flex", "flex", "flex"]}
      flexDirection="column"
      // justifyContent="center"
      alignItems="center"
      w="100%"
      maxH="100vh"
    >
      <Box w={["80%", "80%", "40%", "40%"]}>
        <Heading>Cadastrar NR</Heading>
        <form onSubmit={handleSubmit}>
          <Box mt={8}>
            <label htmlFor="Nome">Nome</label>
            <Input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              id="Nome"
              required
            />
          </Box>
          <Box mt={8}>
            <label htmlFor="Nome">Área</label>
            <Select
              value={selectedArea}
              onChange={(e) => {
                setSelectedArea(e.target.value);
              }}
              id="Nome"
              required
            >
              <option value="default" disabled>
                Selecione
              </option>
              {area.map((item) => (
                <option value={item.id}>{item.name}</option>
              ))}
            </Select>
          </Box>
          {error.status && (
            <Alert status="error" mt={2} rounded="md">
              <AlertIcon />
              {error.data}
            </Alert>
          )}
          <HStack spacing={["auto", "auto", "auto", "auto"]} mt={2}>
            <Button
              onClick={() => {
                // window.location.href = "/admin/nrs";
                setMessageCancel(true);
              }}
            >
              Cancelar
            </Button>
            <Button colorScheme="green" type="submit">
              Salvar
            </Button>
          </HStack>
        </form>
      </Box>
      {MessageCancel && (
        <Box
          position="fixed"
          background="rgba(0,0,0,0.2)"
          w="100%"
          height="100%"
          d="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            bgColor="white"
            p={[4, 8]}
            m={[2, 0]}
            mt="-28"
            d="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            rounded="md"
          >
            <Box
              sx={{ border: "4px solid #ECC94B", borderRadius: "50%" }}
              mb={6}
            >
              <WarningTwoIcon color="yellow.400" w={16} h={16} m={4} />
            </Box>
            <Heading size="md" textAlign="center">
              Tem certeza que deseja cancelar?
              <br /> Os dados informados serão perdidos.
            </Heading>

            <HStack mt={2}>
              <Button
                colorScheme="gray"
                onClick={() => {
                  setMessageCancel(false);
                }}
              >
                Cancelar
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  window.location.href = "/admin/nrs";
                }}
              >
                Sair
              </Button>
            </HStack>
          </Box>
        </Box>
      )}
    </Box>
  );
}
