import { useRef } from "react";
import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Heading,
} from "@chakra-ui/react";
import Input from "../../../components/Input";
import Select from "../Select";
import UF from "../../../../components/UF";
import { useState } from "react";

interface FormData {
  UF: string;
  City: string;
  Street: string;
  Contact: string;
  About: string;
}

interface Code {
  name: string;
  value: string;
}

export default function SecondForm() {
  const formRef = useRef<FormHandles>(null);
  const [City, setCity] = useState<Code[]>([]);
  // const [UFSelected, setUFSelected] = useState("");

  const [success] = useState({ status: false, data: "" });
  const [error, setError] = useState({ status: false, data: "" });

  function Citys(param: string) {
    const value: Code[] = [];
    // setUFSelected(param);
    const data = UF.filter((e) => {
      return e.nome === param;
    });
    for (let i = 0; i < data[0].cidades.length; i++) {
      const element = data[0].cidades[i];
      value.push({ name: element, value: element });
    }
    setCity(value);
  }

  const handleSubmit: SubmitHandler<FormData> = (data) => {
    if (data.UF === "default") {
      setError({ status: true, data: "Selecione um estado" });
      return;
    }
    if (data.City === "default") {
      setError({ status: true, data: "Selecione uma cidade" });
      return;
    }
    if (data.Contact === "default") {
      setError({ status: true, data: "Selecione uma forma de contato" });
      return;
    }
    setError({ status: false, data: "" });

    localStorage.setItem("secondDemanda", JSON.stringify(data));

    setTimeout(() => {
      window.location.href = "/demanda/third-state";
    }, 1000);
  };
  return (
    <>
      <Box
        maxW={["90vw", "40vw"]}
        // maxH={["70vh", "100vh"]}
        w="100%"
        h="auto"
        bgColor="gray.100"
        rounded="md"
        boxShadow="sm"
        p={2}
        mt={2}
        mb={2}
      >
        <Breadcrumb fontWeight="medium" fontSize="sm" mb={4}>
          <BreadcrumbItem color="grey">
            <BreadcrumbLink href="#">Informações Principais</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink href="#" isCurrentPage>
              Localização
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink href="#" color="grey">
              Contato
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Box d="flex" justifyContent="center" alignItems="center">
          <Heading size="lg" mb={4}>
            Localização e Observações
          </Heading>
        </Box>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Select
            name="UF"
            label="Estado"
            onChange={(e: any) => {
              console.log(e.target.value);
              Citys(e.target.value);
            }}
            selectData={[
              { name: "AC", value: "Acre" },
              { name: "AL", value: "Alagoas" },
              { name: "AM", value: "Amazonas" },
              { name: "AP", value: "Amapá" },
              { name: "BA", value: "Bahia" },
              { name: "CE", value: "Ceará" },
              { name: "DF", value: "Distrito Federal" },
              { name: "ES", value: "Espírito Santo" },
              { name: "GO", value: "Goiás" },
              { name: "MA", value: "Maranhão" },
              { name: "MG", value: "Minas Gerais" },
              { name: "MS", value: "Mato Grosso do Sul" },
              { name: "MT", value: "Mato Grosso" },
              { name: "PA", value: "Pará" },
              { name: "PB", value: "Paraíba" },
              { name: "PE", value: "Pernambuco" },
              { name: "PI", value: "Piauí" },
              { name: "PR", value: "Paraná" },
              { name: "RJ", value: "Rio de Janeiro" },
              { name: "RN", value: "Rio Grande do Norte" },
              { name: "RO", value: "Rondônia" },
              { name: "RR", value: "Roraima" },
              { name: "RS", value: "Rio Grande do Sul" },
              { name: "SC", value: "Santa Catarina" },
              { name: "SE", value: "Sergipe" },
              { name: "SP", value: "São Paulo" },
              { name: "TO", value: "Tocantins" },
            ]}
          />
          <Select name="City" label="Cidade" selectData={City} />
          <Input name="Street" label="Bairro" required />
          <Select
            name="Contact"
            label="Qual tipo de contato você prefere receber?"
            selectData={[
              { name: "Somente de empresas", value: "Somente de empresas" },
              {
                name: "Somente de Profissionais Autônomos",
                value: "Somente de Profissionais Autônomos",
              },
              { name: "Não tenho preferência", value: "Não tenho preferência" },
            ]}
          />

          <Input name="About" label="Alguma observação adicional?" />

          {success.status && (
            <Box w="100%" mt={2} mb={2}>
              <Alert status="success" rounded="md">
                <AlertIcon />
                {success.data}
              </Alert>
            </Box>
          )}

          {error.status && (
            <Box w="100%" mt={2} mb={2}>
              <Alert status="error" rounded="md">
                <AlertIcon />
                {error.data}
              </Alert>
            </Box>
          )}

          <Box>
            <Button colorScheme="green" type="submit">
              Avançar
            </Button>
          </Box>
        </Form>
      </Box>
    </>
  );
}
