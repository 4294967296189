import { Image } from "@chakra-ui/image";
import { Box, Grid, Link, Text } from "@chakra-ui/layout";
import Facebook from "../../../assets/img/facebook1.png";
import Instagram from "../../../assets/img/instagram1.png";
import Linkedin from "../../../assets/img/linkedin1.png";

export default function Footer() {
  return (
    <Box
      d="flex"
      justifyContent="center"
      alignItems="center"
      bgColor="green.300"
      w="100%"
      pt={8}
      pb={8}
      h="100%"
    >
      <Grid
        templateColumns={[
          "repeat(1, 1fr)",
          "repeat(1, 1fr)",
          "repeat(1, 1fr)",
          "repeat(1, 1fr)",
          "repeat(3, 1fr)",
        ]}
        w="70%"
        color="white"
      >
        <Box d="flex" flexDirection="column" alignItems="left">
          <Text sx={{ fontWeight: "bolder" }}>Institucional</Text>
          <Link fontWeight="normal" href="/conexaonr">
            A Conexão NR
          </Link>
          <Link fontWeight="normal" href="/missao-visao-valores">
            Missão, Visão e Valores
          </Link>
          <Link fontWeight="normal" href="/perguntas-frequentes">
            Perguntas Frequentes
          </Link>
          <Link fontWeight="normal" href="#">
            Política de Privacidade
          </Link>
          <Link fontWeight="normal" href="#">
            Termos e Condições Gerais de Uso
          </Link>
        </Box>
        <Box d="flex" flexDirection="column" alignItems="left" mt={[2, 0]}>
          <Text sx={{ fontWeight: "bold" }}>Mapa do Site</Text>
          <Link sx={{ textDecoration: "none" }} fontWeight="normal" href="/">
            Início
          </Link>
          <Link fontWeight="normal" href="/login">
            Login
          </Link>
          <Link fontWeight="normal" href="/signup">
            Seja um Parceiro
          </Link>
          <Link fontWeight="normal" href="/comofunciona">
            Como Funciona
          </Link>
          <Link fontWeight="normal" href="/faleconosco" mb={[2, 0]}>
            Fale Conosco
          </Link>
        </Box>
        <Box d="flex" flexDirection="column" alignItems="left">
          <Text sx={{ fontWeight: "bold" }}>Redes Sociais</Text>
          <Box d="flex" mt={4}>
            <Link
              href="https://www.instagram.com/conexao.nr"
              target="_blank"
              _focus={{}}
              _hover={{}}
            >
              <Image ml={2} src={Instagram} alt="Instagram" />
            </Link>
            <Link
              href="https://www.facebook.com/conexao.nr"
              target="_blank"
              _focus={{}}
              _hover={{}}
            >
              <Image ml={2} src={Facebook} alt="Facebook" />
            </Link>
            <Link
              href="https://www.linkedin.com/company/conex%C3%A3o-nr"
              target="_blank"
              _focus={{}}
              _hover={{}}
            >
              <Image ml={2} src={Linkedin} alt="Linkedin" />
            </Link>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
}
