import { Box, Heading, Stack, Text } from "@chakra-ui/layout";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { CircularProgress, Radio, RadioGroup } from "@chakra-ui/react";
import { useState } from "react";
import Fisica from "./components/Fisica";
import Juridica from "./components/Juridica";
import { useEffect } from "react";
import api from "../api/index";
import { AxiosResponse } from "axios";

interface UserData {
  id: number;
  name: string;
  surname: string;
  phone: string;
  email: string;
  created_at: Date;
  updated_at: Date;
}

interface IUser {
  userData: UserData;
  credits: number;
}

export default function SecondState() {
  const [value, setValue] = useState("1");
  const [name, setName] = useState("");
  const [surname, setSurName] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const response: AxiosResponse<IUser> = await api.get("/auth/me", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        setName(response.data.userData.name);
        setSurName(response.data.userData.surname);
        setLoading(false);
      } catch (err) {
        window.location.href = "/signup";
      }
    })();
  }, []);

  if (loading) {
    return (
      <>
        <Box
          w="100%"
          h="100vh"
          d="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress isIndeterminate color="green.700" />
        </Box>
      </>
    );
  }

  return (
    <>
      <Header />
      <Box d="flex" alignItems="center" flexDirection="column" mb={2} mt={2}>
        <Heading size="sm" textAlign="center" mt={2} mb={2}>
          Conta criada com sucesso!
        </Heading>
        <Heading size="sm" textAlign="center" mb={3}>
          Conclua seu cadastro abaixo. Leva menos de 5 minutos.
        </Heading>

        <Box
          d="flex"
          w={["85%", "65%", "55%", "40%"]}
          alignItems="left"
          flexDirection="column"
        >
          <Box d="flex" mb={3}>
            <Text mr={2}>*Tipo de Pessoa: </Text>
            <RadioGroup onChange={setValue} value={value}>
              <Stack direction="row">
                <Radio value="1">Física</Radio>
                <Radio value="2">Jurídica</Radio>
              </Stack>
            </RadioGroup>
          </Box>
          {value === "1" && <Fisica userName={name} userSurname={surname} />}
          {value === "2" && <Juridica userName={name} userSurname={surname} />}
        </Box>
      </Box>
      <Footer />
    </>
  );
}
