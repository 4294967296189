import { useRef } from "react";
import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Checkbox,
  Heading,
  Link,
} from "@chakra-ui/react";
import Input from "../../../components/Input";
import Select from "../Select";
import { useState } from "react";
import InputMask from "react-input-mask";
import api from "../../../../api";

interface FormData {
  Name: string;
  Surname: string;
  CorporateName: string;
  DepartmentName: string;
  Role: string;
  OtherRole: string;
  Email: string;
  ConfirmEmail: string;
  Phone: string;
}

export default function SecondForm() {
  const formRef = useRef<FormHandles>(null);
  const [Departamento, setDepartamento] = useState("");
  const [Role, setRole] = useState("");
  const [error, setError] = useState({ status: false, data: "" });
  const [success, setSuccess] = useState({ status: false, data: "" });
  const [accept, setAccept] = useState(false);

  const handleSubmit: SubmitHandler<FormData> = async (data) => {
    // console.log(data);
    // console.log(accept);
    // eslint-disable-next-line
    let re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    setError({
      status: false,
      data: "",
    });
    setSuccess({ status: false, data: "" });

    if (!re.exec(data.Email)) {
      setError({
        status: true,
        data: "O e-mail informado é inválido",
      });
      return;
    }

    if (data.Email !== data.ConfirmEmail) {
      setError({
        status: true,
        data: "Os endereços de e-mail não coincidem",
      });
      return;
    }

    if (!accept) {
      setError({
        status: true,
        data: "Você precisa aceitar os Termos e Condições Gerais de Uso e Políticas de Privacidade",
      });
      return;
    }

    let nrs = JSON.parse(localStorage.getItem("service") || "");
    let primary = JSON.parse(localStorage.getItem("primary") || "");
    let location = JSON.parse(localStorage.getItem("secondDemanda") || "");
    try {
      const response = await api.post("/demanda", {
        nrs: nrs,
        primary,
        location,
        contact: data,
      });
      if (response.data.data !== "Email já cadastrado") {
        setSuccess({ status: true, data: "Pedido enviado com sucesso" });
        setTimeout(() => {
          window.location.href = "/";
        }, 2500);
      } else {
        setError({ status: true, data: "O e-mail informado já existe" });
      }
    } catch (err: any) {
      console.log(err.response);
    }
  };
  return (
    <>
      <Box
        maxW={["90vw", "40vw"]}
        // minH={["70vh", "100vh"]}
        w="100%"
        h="auto"
        bgColor="gray.100"
        rounded="md"
        boxShadow="sm"
        p={2}
        mt={2}
        mb={2}
      >
        <Breadcrumb fontWeight="medium" fontSize="sm" mb={4}>
          <BreadcrumbItem color="grey">
            <BreadcrumbLink href="#">Informações Principais</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink href="#" color="grey">
              Localização
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink href="#" isCurrentPage>
              Contato
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Box d="flex" justifyContent="center" alignItems="center">
          <Heading size="lg" mb={4}>
            Contato
          </Heading>
        </Box>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input name="Name" label="Nome" required />
          <Input name="Surname" label="Sobrenome" required />
          <Input name="CorporateName" label="Nome da empresa" required />
          <Select
            selectData={[
              { name: "Proprietário(a) / CEO", value: "Proprietário(a) / CEO" },
              { name: "Administrativo", value: "Administrativo" },
              { name: "Atendimento", value: "Atendimento" },
              { name: "Comercial", value: "Comercial" },
              { name: "Compras", value: "Compras" },
              { name: "Diretoria", value: "Diretoria" },
              { name: "Facilities", value: "Facilities" },
              { name: "Financeiro", value: "Financeiro" },
              { name: "Jurídico", value: "Jurídico" },
              { name: "Logística", value: "Logística" },
              { name: "Manutenção", value: "Manutenção" },
              { name: "Marketing", value: "Marketing" },
              { name: "Produção", value: "Produção" },
              { name: "Operacional", value: "Operacional" },
              { name: "RH  Recursos Humanos", value: "RH  Recursos Humanos" },
              {
                name: "Segurança e/ou Medicina do Trabalho / SESMT",
                value: "Segurança e/ou Medicina do Trabalho / SESMT",
              },
              {
                name: "TI – Tecnologia da Informação",
                value: "TI – Tecnologia da Informação",
              },
              { name: "Outro", value: "Other" },
            ]}
            name="DepartmentName"
            label="Seu departamento"
            onChange={(e: any) => {
              if (
                e.target.value === "Proprietário(a) / CEO" ||
                e.target.value === "Segurança e/ou Medicina do Trabalho / SESMT"
              ) {
                setRole(e.target.value);
              }
              setDepartamento(e.target.value);
            }}
            required
          />
          {Departamento === "Other" && (
            <Input
              name="OtherDepartmentName"
              label="Informe seu departamento"
              required
            />
          )}
          {Departamento === "Segurança e/ou Medicina do Trabalho / SESMT" && (
            <>
              <Select
                selectData={[
                  {
                    name: "Auxiliar de Enfermagem do Trabalho",
                    value: "Auxiliar de Enfermagem do Trabalho",
                  },
                  {
                    name: "Enfermeiro(a) do Trabalho",
                    value: "Enfermeiro(a) do Trabalho",
                  },
                  {
                    name: "Enfermeiro(a) do Trabalho",
                    value: "Enfermeiro(a) do Trabalho",
                  },
                  {
                    name: "Engenheiro(a) de Segurança do Trabalho",
                    value: "Engenheiro(a) de Segurança do Trabalho",
                  },
                  {
                    name: "Ergonomista",
                    value: "Ergonomista",
                  },
                  {
                    name: "Fisioterapeuta Ocupacional",
                    value: "Fisioterapeuta Ocupacional",
                  },
                  {
                    name: "Fonoaudiólogo(a)",
                    value: "Fonoaudiólogo(a)",
                  },
                  {
                    name: "Higienista Ocupacional",
                    value: "Higienista Ocupacional",
                  },
                  {
                    name: "Médico(a) do Trabalho",
                    value: "Médico(a) do Trabalho",
                  },
                  {
                    name: "Técnico(a) de Segurança do Trabalho",
                    value: "Técnico(a) de Segurança do Trabalho",
                  },
                  {
                    name: "Outro",
                    value: "Other",
                  },
                ]}
                name="Role"
                label="Cargo"
                onChange={(e: any) => {
                  setRole(e.target.value);
                }}
                required
              />
              {Role === "Other" && (
                <Input name="OtherRole" label="Informe seu cargo" required />
              )}
            </>
          )}
          {Departamento !== "Segurança e/ou Medicina do Trabalho / SESMT" &&
            Departamento !== "Proprietário(a) / CEO" && (
              <>
                <Select
                  selectData={[
                    { name: "Analista", value: "Analista" },
                    { name: "Auxiliar", value: "Auxiliar" },
                    { name: "Consultor(a)", value: "Consultor(a)" },
                    { name: "Coordenador(a)", value: "Coordenador(a)" },
                    { name: "Diretor(a)", value: "Diretor(a)" },
                    { name: "Enfermeiro(a)", value: "Enfermeiro(a)" },
                    { name: "Engenheiro(a)", value: "Engenheiro(a)" },
                    { name: "Ergonomista", value: "Ergonomista" },
                    { name: "Especialista", value: "Especialista" },
                    { name: "Estagiário(a)", value: "Estagiário(a)" },
                    { name: "Executivo(a)", value: "Executivo(a)" },
                    { name: "Gerente", value: "Gerente" },
                    { name: "Gestor(a)", value: "Gestor(a)" },
                    { name: "Higienista", value: "Higienista" },
                    { name: "Médico(a)", value: "Médico(a)" },
                    { name: "Presidente", value: "Presidente" },
                    { name: "Sanitarista", value: "Sanitarista" },
                    { name: "Secretário(a)", value: "Secretário(a)" },
                    { name: "Supervisor(a)", value: "Supervisor(a)" },
                    { name: "Suporte", value: "Suporte" },
                    { name: "Técnico(a)", value: "Técnico(a)" },
                    { name: "Tecnólogo(a)", value: "Tecnólogo(a)" },
                    { name: "Trainee", value: "Trainee" },

                    { name: "Outro", value: "Other" },
                  ]}
                  name="Role"
                  label="Cargo"
                  onChange={(e: any) => {
                    setRole(e.target.value);
                  }}
                  required
                />
                {Role === "Other" && (
                  <Input name="OtherRole" label="Informe seu cargo" required />
                )}
              </>
            )}

          <Input
            name="Email"
            label="E-mail (preferencialmente corporativo)"
            required
          />
          <Input name="ConfirmEmail" label="Confirme o email" required />
          <Input
            name="Phone"
            label="Telefone"
            as={InputMask}
            mask="(99) 99999-9999"
            required
          />
          <Checkbox
            mb={2}
            onChange={(e) => {
              if (e.target.checked) {
                setAccept(true);
              } else {
                setAccept(false);
              }
            }}
          >
            Concordo com os{" "}
            <Link color="blue.500" colorScheme="blue">
              Termos e Condições Gerais de Uso
            </Link>{" "}
            e{" "}
            <Link color="blue.500" colorScheme="blue">
              Políticas de Privacidade
            </Link>{" "}
            da Conexão NR
          </Checkbox>
          <Box w="100%" justifyContent="right">
            <Button colorScheme="green" type="submit">
              Enviar Pedido de Orçamento
            </Button>
          </Box>
        </Form>
        {error.status && (
          <Alert status="error" mt={2} rounded="md">
            <AlertIcon />
            {error.data}
          </Alert>
        )}
        {success.status && (
          <Alert status="success" mt={2} rounded="md">
            <AlertIcon />
            {success.data}
          </Alert>
        )}
      </Box>
    </>
  );
}
