import { Box } from "@chakra-ui/layout";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Form from "./components/Form";
import Background from "../../../assets/img/bg_demanda_second.jpg";

export default function DemandaSecondSetup() {
  return (
    <>
      <Header />
      <Box
        d="flex"
        sx={{
          backgroundImage: `url(${Background})`,
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
        justifyContent="center"
        alignItems="center"
        h="auto"
        w="100%"
      >
        <Box
          d="flex"
          bgColor="rgba(0,0,0,0.4)"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          minH="90vh"
          w="100%"
        >
          <Form />
        </Box>
      </Box>
      <Footer />
    </>
  );
}
