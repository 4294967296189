import { Box, Divider, Flex, Grid, Heading, Text } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";
import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import api from "../../../api";
import { useLoading } from "../../Providers/Loading";

const ItemCard: React.FC<{
  item: any;
  setSuccess: any;
  setError: any;
}> = ({ item, setSuccess, setError }) => {
  const { credits } = useLoading();

  const MySwal = withReactContent(Swal);

  async function subscribe(service: number | string) {
    try {
      const response = await api.post(
        "/subs",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            id: service,
          },
        }
      );
      if (response.status === 200) {
        setSuccess({ stats: true, data: "Inscrição realizada com sucesso" });
        setTimeout(() => {
          setSuccess({ stats: false, data: "" });
          window.location.reload();
        }, 2000);
      }
    } catch (err: any) {
      setError({ stats: true, data: err.response.data.data });
      setTimeout(() => {
        setError({ stats: false, data: "" });
      }, 2000);
    }
  }

  function showBoxSubscriptions(item: string) {
    MySwal.fire({
      title: "Realizar inscrição",
      html: (
        <Flex
          flexDir="column"
          w="100%"
          justifyContent={"center"}
          alignItems="center"
        >
          <Flex gridGap={6}>
            <Text fontWeight="bold">Saldo atual: </Text>
            <Text>{credits}</Text>
          </Flex>

          <Flex gridGap={6}>
            <Text fontWeight="bold">Inscrição: </Text>
            <Text> 10</Text>
          </Flex>

          <Flex gridGap={6}>
            <Text fontWeight="bold">Novo Saldo: </Text>
            <Text> {Number(credits) - 10}</Text>
          </Flex>
        </Flex>
      ),
      confirmButtonText: "Confirmar",
      denyButtonText: "Cancelar",
      showDenyButton: true,
    })
      .then(async (e) => {
        if (e.isConfirmed) {
          await subscribe(item);
        }
      })
      .catch(() => {});
  }

  return (
    <>
      <Box>
        <Box position="relative">
          <Heading fontSize="xl" fontWeight="black" color="blue.500">
            {item.title}
          </Heading>

          <Grid
            templateColumns={[
              "repeat(1, 1fr)",
              "repeat(1, 1fr)",
              "repeat(2, 1fr)",
              "repeat(2, 1fr)",
            ]}
          >
            <Box>
              <Box color="black">
                <Text fontWeight="bold">Serviços:</Text>
                <Text>
                  {item.description.split("\\n").map((it: any, i: any) => (
                    <Text y={"x" + i}>
                      {" "}
                      -{it.replace(new RegExp(",", "g"), "")}
                    </Text>
                  ))}
                </Text>
              </Box>

              <Box>
                <Box d="flex">
                  <Text fontWeight="bold" mr={1}>
                    Ramo/Tipo Empresa:
                  </Text>
                  <Text>
                    {item.branch_of_the_company} / {item.type}
                  </Text>
                </Box>

                <Box d="flex">
                  <Text fontWeight="bold" mr={1}>
                    Região:
                  </Text>
                  <Text>
                    {item.uf} / {item.region} / {item.street}
                  </Text>
                </Box>

                <Box d="flex">
                  <Text fontWeight="bold" mr={1}>
                    Observação:
                  </Text>
                  <Text>{item.about}</Text>
                </Box>

                <Box d="flex">
                  <Text fontWeight="bold" mr={1}>
                    Status:
                  </Text>
                  {item.status && (
                    <Text color="green.400" fontWeight="bold">
                      Em aberto
                    </Text>
                  )}
                  {!item.status && (
                    <Text color="red.400" fontWeight="bold">
                      Fechado
                    </Text>
                  )}
                </Box>
              </Box>
            </Box>

            <Box d="flex" textAlign="right" flexDirection="column">
              <Box d="flex" justifyContent={["left", "left", "right", "right"]}>
                <Text fontWeight="bold" mr={1}>
                  Colaboradores:
                </Text>
                <Text>
                  {item.contributors.indexOf("Mais") > -1 ? (
                    <>{item.contributors}</>
                  ) : (
                    <>
                      Entre{" "}
                      {
                        item.contributors
                          .replace(new RegExp(" ", "g"), "")
                          .split(",")[0]
                      }{" "}
                      e{" "}
                      {
                        item.contributors
                          .replace(new RegExp(" ", "g"), "")
                          .split(",")[1]
                      }
                    </>
                  )}
                </Text>
              </Box>

              <Box d="flex" justifyContent={["left", "left", "right", "right"]}>
                <Text fontWeight="bold" mr={1}>
                  Urgência:
                </Text>
                <Text>{item.urgency}</Text>
              </Box>

              <Box d="flex" justifyContent={["left", "left", "right", "right"]}>
                <Text fontWeight="bold" mr={1}>
                  Publicado em:
                </Text>
                <Text>
                  {new Date(item.published_date).toLocaleDateString("pt-BR")}
                </Text>
              </Box>

              <Box d="flex" justifyContent={["left", "left", "right", "right"]}>
                <Text fontWeight="bold" mr={1}>
                  Expira em:
                </Text>
                <Text color="red">
                  {new Date(item.expires_date).toLocaleDateString("pt-BR")}
                </Text>
              </Box>

              <Box d="flex" justifyContent={["left", "left", "right", "right"]}>
                <Text fontWeight="bold" mr={1}>
                  Custo Inscrição
                </Text>
                <Text>{item.price} créditos</Text>
              </Box>
            </Box>
          </Grid>

          <Box d="flex" justifyContent="space-between" alignItems="center">
            <Text fontWeight="bold">Inscritos: {item.subs}</Text>
            <Button
              disabled={new Date() > new Date(item.expires_date)}
              colorScheme="blue"
              onClick={() => {
                showBoxSubscriptions(item.id);
              }}
            >
              Inscreva-se
            </Button>
          </Box>
          <Divider mt={2} mb={2} />
        </Box>
      </Box>
    </>
  );
};

export default ItemCard;
