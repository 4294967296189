import { Button } from "@chakra-ui/button";
import { Box, Flex, Stack } from "@chakra-ui/layout";
import { Textarea } from "@chakra-ui/textarea";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import api from "../../../api";
import Header from "../../components/Header";

// import { Container } from './styles';

const CmsHome: React.FC = () => {
  const MySwal = withReactContent(Swal);
  const [title, setTitle] = useState("");
  const [subtitle, setSubTitle] = useState("");
  const [about, setAbout] = useState("");
  const [item1, setItem1] = useState("");
  const [item2, setItem2] = useState("");
  const [item3, setItem3] = useState("");

  function CloseWindow() {
    MySwal.fire({
      icon: "warning",
      title: "Tem certeza que deseja cancelar?",
      text: "Os dados informados serão perdidos.",
      confirmButtonText: "Continuar",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
    }).then((response) => {
      if (response.isConfirmed) {
        return (window.location.href = "/admin/cms");
      }
    });
  }

  async function getDataOfPage() {
    try {
      const response = await api.get("/pages", { params: { page: "home" } });
      setTitle(response.data.title);
      setSubTitle(response.data.subtitle);
      setAbout(response.data.about);
      setItem1(response.data.item1);
      setItem2(response.data.item2);
      setItem3(response.data.item3);
    } catch (err) {}
  }

  useEffect(() => {
    getDataOfPage();
  }, []);

  async function handleSubmit(e: React.FormEvent<HTMLDivElement>) {
    e.preventDefault();
    try {
      await api.put(
        "/pages",
        { title, subtitle, about, item1, item2, item3 },
        {
          params: { page: "home" },
          headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
        }
      );
      MySwal.fire({
        icon: "success",
        text: "Dados salvos com sucesso.",
      }).then((response) => {
        if (response.isConfirmed) {
          return (window.location.href = "/admin/cms");
        }
      });
    } catch (err) {}
  }

  return (
    <>
      <Header />
      <Flex justifyContent="center" mt={3}>
        <Stack
          as="form"
          w={["90%", "50%"]}
          direction="column"
          spacing={3}
          onSubmit={handleSubmit}
        >
          <Box>
            <label>Titulo</label>
            <Textarea
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </Box>
          <Box>
            <label>Sub-titulo</label>
            <Textarea
              value={subtitle}
              onChange={(e) => {
                setSubTitle(e.target.value);
              }}
            />
          </Box>
          <Box>
            <label>Sobre</label>
            <Textarea
              value={about}
              onChange={(e) => {
                setAbout(e.target.value);
              }}
            />
          </Box>
          <Box>
            <label>Item 01</label>
            <Textarea
              value={item1}
              onChange={(e) => {
                setItem1(e.target.value);
              }}
            />
          </Box>
          <Box>
            <label>Item 02</label>
            <Textarea
              value={item2}
              onChange={(e) => {
                setItem2(e.target.value);
              }}
            />
          </Box>
          <Box>
            <label>Item 03</label>
            <Textarea
              value={item3}
              onChange={(e) => {
                setItem3(e.target.value);
              }}
            />
          </Box>
          <Stack direction={["column", "row"]}>
            <Button w="100%" colorScheme="green" type="submit">
              Salvar
            </Button>
            <Button w="100%" colorScheme="red" onClick={CloseWindow}>
              Cancelar
            </Button>
          </Stack>
        </Stack>
      </Flex>
    </>
  );
};

export default CmsHome;
