import { Box, Flex, Heading, Stack, Text } from "@chakra-ui/layout";
import React, { useContext, useEffect, useState } from "react";
import api from "../../api";
import CardDePerguntas from "../../components/CardDePerguntas";
import Header from "../components/Header";
import { LoadingContext } from "../Providers/Loading";
import { PackageComponent } from "./iPackageComponent";

const Packages: React.FC = () => {
  const [itens, setItens] = useState<any[]>([]);
  const { setCredits, setName, setLoading, setRole, setAreas, setServices } =
    useContext(LoadingContext);

  useEffect(() => {
    (async () => {
      const response = await api.get<any>("/auth/me", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });

      setCredits(response.data.credits);

      setName(
        `${response.data.userData.name} ${response.data.userData.surname}`
      );
    })();

    (async () => {
      try {
        const response = await api.get<any>("/details/pf", {
          headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
        });
        setRole(response.data.profission);
        setLoading(false);
      } catch (err) {
        try {
          const response = await api.get<any>("/details/pj", {
            headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
          });
          setRole(response.data.profission);
          setLoading(false);
        } catch (err) {}
      }
    })();

    (async () => {
      try {
        const response = await api.get("/dashboard/packages");
        const itens = response.data.sort((a: any, b: any) => a.price - b.price);
        console.log(itens);
        setItens(itens);
      } catch (err) {}
    })();
  }, [setAreas, setCredits, setLoading, setName, setRole, setServices]);

  return (
    <Box overflowY="scroll">
      <Header />
      <Box d="flex" flexDirection="column" m={4} alignItems="center">
        <Box d="flex" justifyContent="center" w="100%" py={2} mb={4}>
          <Box w={["100%", "70%"]}>
            <Heading sx={{ fontStyle: "" }}>Pacotes</Heading>
          </Box>
        </Box>

        <Box d="flex" justifyContent={"center"} w="100%">
          <Box
            d="grid"
            w={["100%", "70%"]}
            gridGap={8}
            gridTemplateColumns={[
              "1fr",
              "repeat(2, 1fr)",
              "repeat(3, 1fr)",
              "repeat(3, 1fr)",
              "repeat(4, 1fr)",
            ]}
            gridTemplateRows={"1fr"}
            mb={8}
          >
            {itens.map((item) => (
              <>
                {item.status && (
                  <PackageComponent
                    title={item.name}
                    price={item.price}
                    credits={item.credits}
                    demanda={item.ndemanda}
                    id={item.id}
                    key={item.id}
                  />
                )}
              </>
            ))}
          </Box>
        </Box>
        <Flex flexDir="column" w={["100%", "70%"]} alignItems="center" mt={8}>
          <Heading size="md">Informações Úteis</Heading>
          <Text mt={4}>
            Pagamentos com cartão de crédito (liberação imediata) ou por boleto.
          </Text>
          <Text>
            Não é assinatura. Você só será cobrado quando você comprar um Pacote
            de Créditos.
          </Text>
          <Text mb={6}>
            Quando acabarem seus créditos, adquira um novo Pacote que melhor lhe
            atende.
          </Text>
          <br />

          <Heading size="md">Perguntas Frequentes</Heading>

          <Stack w="100%" spacing={5}>
            <CardDePerguntas name="O que são os créditos?">
              <Text>
                Créditos são a moeda virtual da Conexão NR, os quais permitem
                que você se inscreva nas demandas recebidas
              </Text>

              <Text>
                Receber as oportunidades é gratuito. Os créditos somente são
                usados quando você se inscreve na demanda. Escolha o Pacote de
                Créditos que melhor lhe atende.
              </Text>
            </CardDePerguntas>

            <CardDePerguntas name="Como funciona a liberação dos créditos?">
              <Text>
                Pagamentos realizados com cartão geram liberação imediata dos
                créditos em sua conta, conforme o Pacote adquirido e após
                recebermos a confirmação pela administradora do cartão.
              </Text>

              <Text>
                Caso o pagamento seja feito por boleto, a liberação é em até 24
                horas úteis, dentro do horário bancário.
              </Text>
            </CardDePerguntas>

            <CardDePerguntas name="Como sei quantos créditos ainda tenho disponível?">
              <Text>
                Na tela principal de sua Conta, é possível visualizar seu saldo
                de créditos e adquirir novos Pacotes, caso necessite.
              </Text>
            </CardDePerguntas>

            <CardDePerguntas name="Terei que utilizar créditos a cada mensagem trocada com o solicitante?">
              <Text>
                Não. A utilização de créditos está ligada à sua inscrição na
                demanda.
              </Text>
              <Text>
                Uma vez inscrito na demanda, você terá acesso aos contatos do
                solicitante e fará todo contato de forma direta.
              </Text>
            </CardDePerguntas>
            <CardDePerguntas name="Como entro em contato com o solicitante?">
              <Text>
                Após se inscrever na demanda, você terá acesso aos contatos do
                solicitante e fará todo contato de forma direta.
              </Text>
            </CardDePerguntas>
            <CardDePerguntas name="Preciso responder todas as oportunidades que recebo?">
              <Text>
                Não. Você poderá responder somente àquelas demandas que achar
                interessantes e que acredita ter mais chances de prestar seu
                serviço.
              </Text>
            </CardDePerguntas>
            <CardDePerguntas name="A demanda que me inscrevi parece falsa. O que eu faço?">
              <Text>
                Caso suspeite que a demanda que você se inscreveu seja falsa,
                por qualquer motivo, entre em contato conosco imediatamente para
                que possamos verificar, eventualmente banir o usuário e
                ressarcir os créditos utilizados.
              </Text>
            </CardDePerguntas>
            <CardDePerguntas name="Quantos prestadores de serviços podem se cadastrar em um Pedido de Orçamento?">
              <Text>
                No máximo 05 (cinco), como forma de prezar pela qualidade para
                ambos os lados.
              </Text>
              <Text>
                Todos prestadores de serviços qualificados para um determinado
                Pedido de Orçamento serão acionados. Inscreva-se e entre em
                contato com a empresa o mais rápido possível. A agilidade
                contará muito neste momento para ter sucesso!
              </Text>
            </CardDePerguntas>
            <CardDePerguntas name="A Conexão NR faz intermediação entre as partes?">
              <Text>
                Não. Nosso objetivo é conectar empresas e prestadores de
                serviços.
              </Text>
              <Text>
                Toda negociação é feita diretamente entre as partes, as quais
                não pagarão nenhum tipo de comissão a Conexão NR, caso esta seja
                concretizada.
              </Text>
            </CardDePerguntas>
          </Stack>
        </Flex>
      </Box>
    </Box>
  );
};

export default Packages;
