import { Box, Heading, HStack, Stack } from "@chakra-ui/layout";
import { Button, Input, Radio, RadioGroup, Select } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import api from "../../../api";
import Header from "../../components/Header";
import { MainResponse } from "../../types/me";

interface Area {
  id: number;
  name: string;
  status: boolean;
  created_at: Date;
  updated_at: Date;
}

export default function NrsPageChangeAdmin() {
  const [area, setArea] = useState<Area[]>([]);

  const [name, setName] = useState("");
  const [selectedArea, setSelectedArea] = useState("default");
  const [status, setStatus] = useState("true");

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get<MainResponse>("/auth/me", {
          headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
        });
        if (response.data.userData.type !== "admin") {
          window.location.href = "/login";
        }
      } catch (err) {
        window.location.href = "/login";
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get("/admin/nrs/show", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            id: localStorage.getItem("itemId"),
          },
        });

        const response2 = await api.get<Area[]>("/area", {
          headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
        });
        setArea(response2.data);

        console.log(response.data);
        setName(response.data.name);
        setSelectedArea(response.data.area_id);
        setStatus(`${response.data.status}`);
      } catch (err) {}
    })();
  }, []);

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    try {
      await api.put(
        "/admin/nrs",
        { name, area_id: selectedArea, status },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            id: `${localStorage.getItem("itemId")}`,
          },
        }
      );
      window.location.href = "/admin/nrs";
    } catch (err) {}
  }

  return (
    <>
      <Header />
      <Box
        d="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        w="100%"
      >
        <Heading>Editar NR</Heading>
        <Box w={["70%", "20%"]}>
          <form onSubmit={handleSubmit}>
            <Box d="flex" alignItems="left" flexDirection="column">
              <label htmlFor="name">Nome</label>
              <Input
                id="name"
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
                required
              />
            </Box>
            <Box d="flex" alignItems="left" flexDirection="column" mb={2}>
              <label htmlFor="Status">Área</label>
              <Select
                id="Status"
                value={selectedArea}
                onChange={(e) => {
                  setSelectedArea(e.target.value);
                }}
                required
              >
                <option value="default" selected disabled>
                  Selecione
                </option>
                {area.map((item) => (
                  <option value={item.id}>{item.name}</option>
                ))}
              </Select>
            </Box>
            <Box>
              <RadioGroup onChange={setStatus} value={status}>
                <Stack direction="row">
                  <Radio value="true">Ativo</Radio>
                  <Radio value="false">Inativo</Radio>
                </Stack>
              </RadioGroup>
            </Box>
            <HStack spacing="auto" mt={2}>
              <Button
                onClick={() => {
                  window.location.href = "/admin/nrs";
                }}
              >
                Cancelar
              </Button>
              <Button colorScheme="green" type="submit">
                Salvar
              </Button>
            </HStack>
          </form>
        </Box>
      </Box>
    </>
  );
}
