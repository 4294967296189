import { Box, Grid, Link, Text } from "@chakra-ui/layout";
import {
  Alert,
  AlertIcon,
  Button,
  Heading,
  IconButton,
  Input,
} from "@chakra-ui/react";
import { AxiosResponse } from "axios";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { BiTrash } from "react-icons/bi";
import Background from "../../assets/img/bg_header.png";
import HeadHuman from "../../assets/img/capacete2.png";
import Shield from "../../assets/img/escudo3.png";
import Ferramenta from "../../assets/img/ferramenta2.png";
import api from "../api/index";
import {
  getServices,
  iService,
  setDefaultService,
  setService,
} from "../components/Demanda/index";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Loading from "../components/Loading";

interface Services {
  title: string;
  match: number;
  id: number;
}

function Home() {
  type NewType = MutableRefObject<HTMLInputElement>;

  const InputRef = useRef() as NewType;
  const SearchingRef = useRef() as NewType;
  const [search, setSearch] = useState<string>();
  const [buttonTyping, setButtonTyping] = useState(false);
  const [services, setServices] = useState<Services[]>([]);
  const [success, setSuccess] = useState({
    status: false,
    data: "NR Adicionado com sucesso!",
  });

  const [loading, setLoading] = useState(true);

  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [about, setAbout] = useState("");
  const [item1, setItem1] = useState("");
  const [item2, setItem2] = useState("");
  const [item3, setItem3] = useState("");

  const [nrSelected, setNrSelect] = useState<iService[]>([]);

  async function getDataOfPage() {
    try {
      const response = await api.get("/pages", { params: { page: "home" } });
      setTitle(response.data.title);
      setSubtitle(response.data.subtitle);
      setAbout(response.data.about);
      setItem1(response.data.item1);
      setItem2(response.data.item2);
      setItem3(response.data.item3);
      setLoading(false);
    } catch (err) {}
  }

  useEffect(() => {
    getDataOfPage();
    setNrSelect(getServices());
    console.log(getServices());
    console.log(SearchingRef.current?.getBoundingClientRect().top);
  }, []);

  function deleteSelectedService(id: number) {
    const array = nrSelected;
    const newArray = array.filter((item) => {
      return item.id !== id;
    });

    setDefaultService(newArray);
    setNrSelect(newArray);
  }

  function SuccessAlert() {
    setSuccess({ status: true, data: "NR Adicionado com sucesso!" });
    setTimeout(() => {
      setSuccess({ status: false, data: success.data });
    }, 1300);
  }

  function SearchService(searchText: string) {
    var searchTextParsed = searchText.trim() + " ";

    (async () => {
      const response: AxiosResponse<Services[]> = await api.get(`/search`, {
        headers: {
          query: `${Buffer.from(searchTextParsed, "utf-8").toString("base64")}`,
        },
      });
      setServices(response.data);
      setButtonTyping(true);
    })();
  }

  async function InputValue(e: any) {
    if (e.target.value.length !== 0) {
      setSearch(e.target.value);
      SearchService(e.target.value);

      return;
    }
    setSearch(e.target.value);
    setButtonTyping(false);
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Header />
      <Box
        as="main"
        w="100%"
        h="100vh"
        d="flex"
        flexDirection="column"
        alignItems="center"
        position="relative"
      >
        <Box
          w="100%"
          d="flex"
          h="100%"
          maxH={["500px", "300px"]}
          height="100%"
          flexDirection="column"
          alignItems="center"
          bgImage={Background}
          bgSize="cover"
          bgRepeat="no-repeat"
          bgPosition="center center"
          justifyContent="center"
          color="white"
        >
          <Heading size="md" mt={6} textAlign="center">
            {title}
          </Heading>

          <Box w="100%" d="flex" justifyContent="center" mt={6} mb={6}>
            <Input
              w={["80%", "80%", "45%", "45%"]}
              placeholder="Digite aqui as palavras chaves dos serviços e selecione"
              size="md"
              bg="white"
              color="gray"
              ref={InputRef}
              onChange={(e: any) => {
                InputValue(e);
              }}
              sx={{
                outline: "none !important",
                border: "0 !important",
                zIndex: 1,

                _focus: {
                  outline: "none !important",
                  border: "0 !important",
                },
              }}
              value={search}
            />
          </Box>

          {buttonTyping && (
            <>
              {" "}
              <Box
                position="absolute"
                ref={SearchingRef}
                p={4}
                top={
                  InputRef.current?.getBoundingClientRect().top -
                  InputRef.current?.getBoundingClientRect().height +
                  10
                }
                w={InputRef.current?.getBoundingClientRect().width}
                bgColor="white"
                color="#000"
                style={{
                  borderBottomRightRadius: ".375rem",
                  borderBottomLeftRadius: ".375rem",
                  zIndex: 1,
                }}
              >
                {services &&
                  services.map((item, i) => (
                    <>
                      {i < 7 && (
                        <>
                          <Box
                            key={item.id}
                            d="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            bgColor="gray.100"
                            rounded="md"
                            mb={2}
                            p={2}
                          >
                            <Text isTruncated mr={2}>
                              {item.title}
                            </Text>
                            <Link
                              color="blue.400"
                              onClick={() => {
                                const value = { id: item.id, name: item.title };

                                if (
                                  nrSelected.filter((arrayItem) => {
                                    return arrayItem.id === value.id;
                                  }).length === 0
                                ) {
                                  setService({ id: item.id, name: item.title });

                                  setNrSelect([...nrSelected, value]);
                                  SearchService("");
                                  SuccessAlert();
                                  setSearch("");
                                }
                              }}
                            >
                              Adicionar
                            </Link>
                          </Box>
                        </>
                      )}
                    </>
                  ))}
                {nrSelected.length > 0 && (
                  <Box
                    bgColor="white"
                    color="#000"
                    boxShadow="xs"
                    rounded="md"
                    p={2}
                  >
                    {nrSelected.map((item) => (
                      <Box
                        d="flex"
                        justifyContent="space-between"
                        mb={2}
                        alignItems="center"
                      >
                        <Text isTruncated>{item.name}</Text>
                        <IconButton
                          colorScheme="red"
                          aria-label="delete"
                          onClick={() => {
                            deleteSelectedService(item.id);
                          }}
                          icon={<BiTrash />}
                        />
                      </Box>
                    ))}
                    <Box d="flex" justifyContent="right">
                      <Button
                        colorScheme="green"
                        onClick={() => {
                          window.location.href = "/demanda";
                        }}
                      >
                        Próximo Passo
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
            </>
          )}

          {subtitle.split("\n").map((item, index) => (
            <Heading
              size="sm"
              textAlign="center"
              mt={`${index > 0 ? 5 : 0}`}
              mb={`${index > 0 ? 5 : 0}`}
            >
              {item}
            </Heading>
          ))}
        </Box>

        <Box
          mt={6}
          mb={6}
          d="flex"
          mx={[8, 0]}
          flexDirection="column"
          alignItems="center"
        >
          <Heading size="md" mb={8} textAlign="center">
            O que é a Conexão NR?
          </Heading>

          <Text maxW="290px" sx={{ fontWeight: "500" }} textAlign="center">
            A 1ª plataforma do Brasil especializada na contratação de serviços
            de Saúde e Segurança do Trabalho.
          </Text>

          <Text maxW="290px" mt={2} sx={{ fontWeight: "500" }} textAlign="center">
            Conectamos de forma rápida e fácil, prestadores com empresas que
            necessitam deste tipo serviço.
          </Text>
        </Box>

        <Box w="100%" ml={4} mr={4} mt={4} mb={4}>
          <Grid
            templateColumns={["repeat(1, 1fr)", "repeat(3, 1fr)"]}
            gap={[8, 0]}
          >
            <Box
              d="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="flex-start"
              w="100%"
            >
              <img src={Ferramenta} alt="Ferramenta" />
              <Heading size="sm" mt={2} mb={2}>
                1 - Faça seu Pedido
              </Heading>
              {item1.split("\n").map((item) => (
                <>
                  <Text textAlign="center">{item}</Text>
                </>
              ))}
            </Box>
            <Box
              d="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="flex-start"
              w="100%"
            >
              <img src={HeadHuman} alt="Capacete" />
              <Heading size="sm" mt={2} mb={2}>
                2 - Receba gratuitamente até 5 contatos
              </Heading>
              {item2.split("\n").map((item) => (
                <>
                  <Text textAlign="center">{item}</Text>
                </>
              ))}
            </Box>
            <Box
              d="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="flex-start"
              w="100%"
              mb={[2, 0]}
            >
              <img src={Shield} alt="Escudo" />
              <Heading size="sm" mt={2} mb={2}>
                3 - Avalie e feche
              </Heading>
              {item3.split("\n").map((item) => (
                <>
                  <Text textAlign="center">{item}</Text>
                </>
              ))}
            </Box>
          </Grid>
        </Box>

        <Box textAlign="center" mb={4} mt={4}>
          <Heading size="md" mb={6}>
            Prestadores da Conexão NR:
          </Heading>
          <Text>Segurança do Trabalho</Text>
          <Text>Medicina do Trabalho</Text>
          <Text>Higiene Ocupacional</Text>
          <Text>Assistência Técnica em Perícias</Text>
          <Text>Gestão Ambiental e Sanitarismo</Text>
          <Text>EPI - Equipamentos de Proteção Individual</Text>
          <Text>EPC - Equipamentos de Proteção Coletiva</Text>
          <Text>Equipamentos de Combate a Incêndios</Text>
          <Text>Sinalização de Segurança</Text>
          <Text>Sistemas de Gestão de SST</Text>
        </Box>

        <Box
          d="flex"
          color="white"
          bgColor="green.700"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          w={["100%"]}
          h="100%"
          p={4}
          textAlign="center"
        >
          <Text sx={{ fontWeight: "bold" }} mb={2}>
            Possui uma empresa ou é profissional de SST e quer ser Parceiro da
            Conexão NR?
          </Text>
          <Button
            colorScheme="yellow"
            sx={{ fontWeight: "bold", fontStyle: "italic" }}
            p={[2, 2, 8, 8]}
            onClick={() => {
              window.location.href = "/signup";
            }}
          >
            Cadastre-se agora e conquiste novos clientes
          </Button>
        </Box>
        {success.status && (
          <Alert
            position="absolute"
            top={2}
            right={2}
            w="auto"
            status="success"
          >
            <AlertIcon />
            {success.data}
          </Alert>
        )}
        <Footer />
      </Box>
    </>
  );
}

export default Home;
