import { WarningTwoIcon } from "@chakra-ui/icons";
import { Box, Heading, HStack, Stack } from "@chakra-ui/layout";
import {
  Alert,
  AlertIcon,
  Button,
  Input,
  Radio,
  RadioGroup,
  Select,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useState } from "react";
import api from "../../../../../api";

interface Area {
  id: number;
  name: string;
  status: boolean;
  created_at: Date;
  updated_at: Date;
}

export default function Main() {
  const [name, setName] = useState("");
  const [area, setArea] = useState<Area[]>([]);
  const [nrs, setNrs] = useState<Area[]>([]);
  const [selectedArea, setSelectedArea] = useState("default");
  const [selectedNr, setSelectedNr] = useState("null");
  const [MessageCancel, setMessageCancel] = useState(false);
  const [status, setStatus] = useState("");

  const [error, setError] = useState({ status: false, data: "" });

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get<Area[]>("/area", {
          headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
        });

        const response2 = await api.get("/search/all");
        setNrs(response2.data);
        setArea(response.data);
      } catch (err) {}
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get("/admin/services/show", {
          params: { item: `${localStorage.getItem("ServiceIdEdit")}` },
          headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
        });
        setName(response.data.name);
        setSelectedArea(response.data.area);
        setSelectedNr(response.data.nr);
        setStatus(`${response.data.status}`);
      } catch (err) {}
    })();
  }, []);

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setError({ status: false, data: "" });

    if (selectedArea === "default") {
      setError({ status: true, data: "Selecione uma Área" });
      return;
    }
    if (selectedNr !== "null") {
      try {
        await api.put(
          "/admin/services",
          {
            id: `${localStorage.getItem("ServiceIdEdit")}`,
            name,
            nr: selectedNr,
            area: selectedArea,
            status,
          },
          {
            headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
          }
        );
        window.location.href = "/admin/services";
      } catch (err) {}
    } else {
      try {
        await api.put(
          "/admin/services",
          {
            id: `${localStorage.getItem("ServiceIdEdit")}`,
            name,
            nr: selectedNr,
            area: selectedArea,
            status,
          },
          {
            headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
          }
        );
        window.location.href = "/admin/services";
      } catch (err) {}
    }
  }

  return (
    <Box
      d={["flex", "flex", "flex", "flex"]}
      flexDirection="column"
      // justifyContent="center"
      alignItems="center"
      w="100%"
      maxH="100vh"
    >
      <Box w={["80%", "80%", "40%", "40%"]}>
        <Heading>Editar Serviço</Heading>
        <form onSubmit={handleSubmit}>
          <Box mt={8}>
            <label htmlFor="Nome">Nome</label>
            <Input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              id="Nome"
              required
            />
          </Box>

          <Box mt={8}>
            <label htmlFor="Nrs">NRs</label>
            <Select
              value={selectedNr}
              onChange={(e) => {
                setSelectedNr(e.target.value);
              }}
              id="Nrs"
            >
              <option value="null" selected disabled>
                Selecione
              </option>
              {nrs.map((item) => (
                <option value={item.id}>{item.name}</option>
              ))}
            </Select>
          </Box>

          <Box mt={8}>
            <label htmlFor="Nome">Área</label>
            <Select
              value={selectedArea}
              onChange={(e) => {
                setSelectedArea(e.target.value);
              }}
              id="Area"
              required
            >
              <option value="default" selected disabled>
                Selecione
              </option>
              {area.map((item) => (
                <option value={item.id}>{item.name}</option>
              ))}
            </Select>
          </Box>
          <Box mt={8}>
            <label htmlFor="State">Situação</label>
            <RadioGroup value={status} onChange={setStatus}>
              <Stack direction="row">
                <Radio value="true">Ativo</Radio>
                <Radio value="false">Inativo</Radio>
              </Stack>
            </RadioGroup>
          </Box>
          {error.status && (
            <Alert status="error" mt={2} rounded="md">
              <AlertIcon />
              {error.data}
            </Alert>
          )}
          <HStack spacing={["auto", "auto", "auto", "auto"]} mt={2}>
            <Button
              onClick={() => {
                // window.location.href = "/admin/nrs";
                setMessageCancel(true);
              }}
            >
              Cancelar
            </Button>
            <Button colorScheme="green" type="submit">
              Salvar
            </Button>
          </HStack>
        </form>
      </Box>

      {MessageCancel && (
        <Box
          position="fixed"
          background="rgba(0,0,0,0.2)"
          w="100%"
          height="100%"
          d="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            bgColor="white"
            p={[4, 8]}
            m={[2, 0]}
            mt="-28"
            d="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            rounded="md"
          >
            <Box
              sx={{ border: "4px solid #ECC94B", borderRadius: "50%" }}
              mb={6}
            >
              <WarningTwoIcon color="yellow.400" w={16} h={16} m={4} />
            </Box>
            <Heading size="md" textAlign="center">
              Tem certeza que deseja cancelar?
              <br /> Os dados informados serão perdidos.
            </Heading>

            <HStack mt={2}>
              <Button
                colorScheme="gray"
                onClick={() => {
                  setMessageCancel(false);
                }}
              >
                Cancelar
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  window.location.href = "/admin/services";
                }}
              >
                Sair
              </Button>
            </HStack>
          </Box>
        </Box>
      )}
    </Box>
  );
}
