import { Box, Heading, HStack, Stack } from "@chakra-ui/layout";
import { Button, Input, Radio, RadioGroup } from "@chakra-ui/react";
import { useEffect } from "react";
import { useState } from "react";
import api from "../../../../api";

export default function Main() {
  const [name, setName] = useState("");
  const [status, setStatus] = useState("false");

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get("/admin/area/show", {
          params: {
            id: localStorage.getItem("itemId"),
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        console.log(response.data);
        setName(response.data.name);
        setStatus(`${response.data.status}`);
      } catch (err) {}
    })();
  }, []);

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      await api.put(
        "/admin/area",
        {
          id: localStorage.getItem("itemId"),
          name,
          status,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      window.location.href = "/admin";
    } catch (err) {}
  }
  return (
    <Box
      d="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      w="100%"
    >
      <Heading>Editar Área</Heading>
      <Box w={["70%", "20%"]}>
        <form onSubmit={handleSubmit}>
          <Box d="flex" alignItems="left" flexDirection="column">
            <label htmlFor="name">Nome</label>
            <Input
              id="name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              required
            />
          </Box>
          <Box mt={2}>
            <RadioGroup onChange={setStatus} value={status}>
              <Stack direction="row">
                <Radio value="true">Ativo</Radio>
                <Radio value="false">Inativo</Radio>
              </Stack>
            </RadioGroup>
          </Box>
          <HStack spacing="auto" mt={2}>
            <Button
              onClick={() => {
                window.location.href = "/admin";
              }}
            >
              Cancelar
            </Button>
            <Button colorScheme="green" type="submit">
              Salvar
            </Button>
          </HStack>
        </form>
      </Box>
    </Box>
  );
}
