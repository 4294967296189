import {
  Box,
  Flex,
  Heading,
  Link as LinkStyle,
  Stack,
  Text,
} from "@chakra-ui/layout";
import { Button, HStack, Image } from "@chakra-ui/react";
import { useState } from "react";
import { Link as Linking } from "react-router-dom";
import Logo from "../../../../assets/img/conexaonr2_rgb-pq.png";
import { useLoading } from "../../Providers/Loading";
import MenuClose from "./MenuClose.svg";
import MenuIcon from "./MenuIcon.svg";

export default function HeaderEmail() {
  const { credits, name } = useLoading();

  const [toggle, setToggle] = useState(false);

  return (
    <>
      <Box
        bgColor="#07282B"
        w="100%"
        d={["none", "none", "flex", "flex"]}
        justifyContent="center"
        alignContent="center"
        p={2}
      >
        <Box w="70%">
          <Box
            w="100%"
            d="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <img
              src={Logo}
              alt="Conexão nr"
              width="200px"
              height="43px"
              onClick={() => (window.location.href = "/")}
              style={{ cursor: "pointer" }}
            />

            <Box
              w="100%"
              d="flex"
              ml={8}
              flexDirection="column"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box
                w="100%"
                d="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box></Box>

                <Box
                  d="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mb={4}
                >
                  <Heading color="white" size="sm">
                    {name}
                  </Heading>
                </Box>

                <HStack d="flex" flexDirection="row">
                  <Box
                    d="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    color="white"
                  >
                    <Text as="i">Créditos</Text>
                    <Text fontSize="sm">{credits}</Text>
                  </Box>
                </HStack>
              </Box>
              <Box w="100%" d="flex" justifyContent="space-between">
                <LinkStyle
                  as={Linking}
                  to="/dashboard"
                  href="#"
                  color="white"
                  _hover={{ textDecoration: "none" }}
                >
                  Demandas Abertas
                </LinkStyle>
                <LinkStyle
                  as={Linking}
                  to="/demandasinscritas"
                  href="#"
                  color="white"
                  _hover={{ textDecoration: "none" }}
                >
                  Demandas Inscritas
                </LinkStyle>

                <LinkStyle
                  as={Linking}
                  to="/dashboard/profile"
                  href="#"
                  color="white"
                  _hover={{ textDecoration: "none" }}
                >
                  Meus dados
                </LinkStyle>
                <LinkStyle
                  as={Linking}
                  to="/dashboard/pacotes"
                  href="#"
                  color="white"
                  _hover={{ textDecoration: "none" }}
                >
                  Comprar Créditos
                </LinkStyle>
                <LinkStyle
                  href="#"
                  color="white"
                  _hover={{ textDecoration: "none" }}
                  onClick={() => {
                    localStorage.clear();
                    window.location.href = "/login";
                  }}
                >
                  Sair
                </LinkStyle>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Flex
        d={["flex", "flex", "none", "none"]}
        bg="#07282B"
        direction="column"
        color="white"
      >
        <Box
          p={2}
          w="100%"
          d={["flex", "flex", "none", "none"]}
          as="header"
          alignItems="center"
          justifyContent="space-between"
        >
          <a href="/">
            <img src={Logo} alt="Conexão nr" width="200px" height="43px" />
          </a>
          <Box
            d="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            color="white"
          >
            <Text as="i">Créditos</Text>
            <Text fontSize="sm">{credits}</Text>
          </Box>
          <Box>
            <Button
              bg="transparent"
              _focus={{}}
              _active={{}}
              _hover={{}}
              onClick={() => {
                toggle ? setToggle(false) : setToggle(true);
              }}
            >
              <Image src={toggle ? MenuClose : MenuIcon} />
            </Button>
          </Box>
        </Box>
        {toggle && (
          <>
            <Stack direction="column" p={5} spacing={4}>
              <Box>{name}</Box>
              <Box>
                {" "}
                <Text as="i">Créditos</Text>
                <Text fontSize="sm">{credits}</Text>
              </Box>
              <LinkStyle
                as={Linking}
                to="/dashboard"
                color="white"
                _hover={{ textDecoration: "none" }}
              >
                Demandas Abertas
              </LinkStyle>
              <LinkStyle
                as={Linking}
                to="/demandasinscritas"
                href="#"
                color="white"
                _hover={{ textDecoration: "none" }}
              >
                Demandas Inscritas
              </LinkStyle>

              <LinkStyle
                as={Linking}
                to="/dashboard/profile"
                href="#"
                color="white"
                _hover={{ textDecoration: "none" }}
              >
                Meus dados
              </LinkStyle>
              <LinkStyle
                as={Linking}
                to="/dashboard/pacotes"
                color="white"
                _hover={{ textDecoration: "none" }}
              >
                Comprar Créditos
              </LinkStyle>
              <LinkStyle
                href="#"
                color="white"
                _hover={{ textDecoration: "none" }}
                onClick={() => {
                  localStorage.clear();
                  window.location.href = "/login";
                }}
              >
                Sair
              </LinkStyle>
            </Stack>
          </>
        )}
      </Flex>
    </>
  );
}
