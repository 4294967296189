import { AddIcon } from "@chakra-ui/icons";
import { Box, Flex, Stack, Text } from "@chakra-ui/layout";
import { Button, IconButton, Input, Radio, RadioGroup } from "@chakra-ui/react";
import React, { FormEvent, useEffect, useState } from "react";
import { useParams } from "react-router";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Header from "../../../Admin/components/Header";
import api from "../../../api";

// import { Container } from './styles';

const ParceiroEdit: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const [name, setName] = useState("");
  const [social, setSocial] = useState("");
  const [cpf, setCpf] = useState("");
  const [status] = useState("true");
  const [credits, setCredits] = useState<Number>(0);

  const MySwal = withReactContent(Swal);

  useEffect(() => {
    (async () => {
      const response = await api.get("/admin/Parceiro", {
        headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}`, id },
      });
      setName(response.data.name);
      if (response.data.typeSocial !== "PF") {
        setSocial(response.data.corporatename);
        setCpf(response.data.cnpj);
      } else {
        setSocial("Pessoa Física");
        setCpf(response.data.cpf);
      }
      setCredits(response.data.credits);
    })();
  }, [id]);

  async function comeBack() {
    MySwal.fire({
      icon: "warning",
      title: "Tem certeza que deseja cancelar?",
      text: "Os dados informados serão perdidos.",
      showCancelButton: true,
      focusCancel: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then((response) => {
      if (response.isConfirmed) {
        return (window.location.href = "/admin/Parceiro");
      }
    });
  }

  async function handleSubmit(e: FormEvent<HTMLDivElement>) {
    e.preventDefault();
    (async () => {
      const response = await api.put(
        "/admin/Parceiro",
        {
          id,
          credits,
        },
        { headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` } }
      );
      if (response.status === 200) {
        MySwal.fire({
          icon: "success",
          title: "Registro salvo com sucesso",
        }).then((response) => {
          if (response.isConfirmed) {
            window.location.href = "/admin/Parceiro";
          }
        });
      }
    })();
  }

  return (
    <>
      <Header />

      <Flex
        as="form"
        flexDirection="column"
        w="100%"
        mt={4}
        alignItems="center"
        justifyContent="center"
        onSubmit={handleSubmit}
      >
        <Text fontWeight="bold" fontSize="3xl">
          Editar Parceiro
        </Text>

        <Stack spacing={4} w={["90%", "25%"]} flexDirection="column">
          <Box w="100%">
            <label htmlFor="name">Nome</label>
            <Input
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={name}
              id="name"
              placeholder="Nome"
              disabled
            />
          </Box>

          <Box w="100%">
            <label htmlFor="razaosocial">Razão Social</label>
            <Input
              value={social}
              onChange={(e) => {
                setSocial(e.target.value);
              }}
              id="razaosocial"
              placeholder="razão social"
              disabled
            />
          </Box>

          <Box w="100%">
            <label htmlFor="cpf/cnpj">CPF/CNPJ</label>
            <Input
              value={cpf}
              onChange={(e) => {
                setCpf(e.target.value);
              }}
              id="cpf/cnpj"
              placeholder="Nome"
              disabled
            />
          </Box>

          <Box>
            <label htmlFor="status">Situação</label>
            <RadioGroup value={status}>
              <Stack direction="row">
                <Radio value="true" defaultChecked>
                  Ativo
                </Radio>
                <Radio value="false">Inativo</Radio>
              </Stack>
            </RadioGroup>
          </Box>

          <Box>
            <label htmlFor="Credits">Créditos</label>
            <Flex>
              <Input id="Credits" type="number" />
              <IconButton
                ml={2}
                aria-label="Adicionar créditos"
                icon={<AddIcon />}
                colorScheme="green"
                onClick={() => {
                  const inputValue = (
                    document.getElementById("Credits") as HTMLInputElement
                  ).value;

                  let newCreditValue =
                    parseInt(`${credits}`) + parseInt(inputValue);
                  // if (newCreditValue === "NaN") {
                  //   MySwal.fire({
                  //     icon: "error",
                  //     title: "Você digitou algo que não é um numero.",
                  //   });
                  // return;
                  // }
                  console.log(newCreditValue);
                  if (isNaN(newCreditValue)) {
                    MySwal.fire({
                      icon: "error",
                      title: "Você digitou algo que não é um numero.",
                    });
                    return;
                  } else {
                    setCredits(newCreditValue);
                  }
                }}
              />
            </Flex>

            <Flex>
              <Text fontWeight="bold">{credits}</Text>
              <Text ml={2}>créditos ativos</Text>
            </Flex>
          </Box>

          {/* <Box>
            <label htmlFor="Planos">Plano</label>
            <Flex>
              <Input id="Planos" />
              <IconButton
                ml={2}
                aria-label="Adicionar créditos"
                icon={<AddIcon />}
                colorScheme="green"
              />
            </Flex>

            <Flex>
              <Text fontWeight="bold">Plano iniciante</Text>
              <Text ml={2}>Ativo</Text>
            </Flex>
          </Box> */}
          <Stack direction="row" w="100%">
            <Button w="100%" onClick={comeBack}>
              Cancelar
            </Button>
            <Button w="100%" colorScheme="green" type="submit">
              Salvar
            </Button>
          </Stack>
        </Stack>
      </Flex>
    </>
  );
};

export default ParceiroEdit;
