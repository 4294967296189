import { Button } from "@chakra-ui/button";
import { Flex, Grid } from "@chakra-ui/layout";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../../api";
import Header from "../components/Header";
import { MainResponse } from "../types/me";

const CMS: React.FC = () => {
  useEffect(() => {
    (async () => {
      try {
        const response = await api.get<MainResponse>("/auth/me", {
          headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
        });
        if (response.data.userData.type !== "admin") {
          window.location.href = "/login";
        }
      } catch (err) {
        window.location.href = "/login";
      }
    })();
  }, []);

  return (
    <>
      <Header />
      <Flex mt={10} justifyContent="center">
        <Grid
          gridTemplateColumns="repeat(3,1fr)"
          gap={3}
          maxW="1200px"
          w="100%"
          alignItems="center"
        >
          <Button
            as={Link}
            to="/admin/CMS/Home"
            colorScheme="green"
            d="flex"
            justifyContent="center"
            alignItems="center"
            shadow="base"
            p={4}
            rounded="md"
            h="10vh"
          >
            Home
          </Button>
          <Button
            as={Link}
            to="/admin/CMS/PerguntasFrequentes"
            colorScheme="green"
            d="flex"
            justifyContent="center"
            alignItems="center"
            shadow="base"
            p={4}
            rounded="md"
            h="10vh"
          >
            Perguntas Frequentes
          </Button>
          <Button
            as={Link}
            to="/admin/CMS/MissaoValores"
            colorScheme="green"
            d="flex"
            justifyContent="center"
            alignItems="center"
            shadow="base"
            p={4}
            rounded="md"
            h="10vh"
          >
            Missão, Valores e Visão
          </Button>
        </Grid>
      </Flex>
    </>
  );
};

export default CMS;
