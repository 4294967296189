import { CloseIcon } from "@chakra-ui/icons";
import { Box, Heading, HStack } from "@chakra-ui/layout";
import {
  Button,
  IconButton,
  Input,
  Table,
  Tbody,
  Td,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useState } from "react";
import { BiEdit, BiTrash } from "react-icons/bi";
import api from "../../api";
import Header from "../components/Header";
import Pagination from "./Add/components/Pagination";
import { Page } from "../NRs/types/NrPages";

export default function ServicesAdmin() {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [items, setItems] = useState<any[]>([]);
  const [BackupItems, setBackupItems] = useState<any[]>([]);
  const [PrimaryBackupItems, setPrimaryBackupItems] = useState<any[]>([]);
  const [pages, setPages] = useState<Page[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get("/auth/me", {
          headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
        });

        if (response.data.userData.type !== "admin") {
          window.location.href = "/login";
        }
      } catch (err) {
        window.location.href = "/login";
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get("/services", { params: { page: 1 } });
        setItems(response.data.data.data);
        setPages(response.data.pages);
        setPrimaryBackupItems(response.data.data.data);

        const data: any[] = [];
        for (let i = 1; i <= response.data.pages.length; i++) {
          try {
            const response2 = await api.get("/services", {
              params: { page: i },
            });
            data.push(...response2.data.data.data);
          } catch (err) {}
        }
        setBackupItems(data);
      } catch (err) {}
    })();
  }, [setItems, setBackupItems]);

  async function Searching(name: string) {
    if (name !== "") {
      const data: any[] = [];
      for (let i = 0; i < BackupItems.length; i++) {
        const element = BackupItems[i];
        if (element.name.toLowerCase().indexOf(name.toLowerCase()) > -1) {
          data.push(element);
        }
      }
      setItems(data);
    } else {
      // const response = await api.get("/admin/nrs", {
      //   headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
      //   params: { page: 1 },
      // });

      setItems(PrimaryBackupItems);
    }
  }

  return (
    <>
      <Header />
      <Box as="main" position="relative">
        <Box m={[1, 4]}>
          <Heading>Serviço</Heading>
          <Box mt={8}>
            <Box d="flex" justifyContent="space-between">
              <Box
                w={["60%", "60%", "20%", "20%"]}
                d="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Input
                  w="100%"
                  placeholder="Nome do Serviço"
                  onChange={(e) => {
                    Searching(e.target.value);
                  }}
                />
              </Box>
              <Button
                colorScheme="green"
                w={["16%", "16%", "8%", "8%"]}
                onClick={() => {
                  window.location.href = "/admin/services/add";
                }}
              >
                Novo
              </Button>
            </Box>
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Td>Nome</Td>
                  <Td>Situação</Td>
                  <Td>Ação</Td>
                </Tr>
              </Thead>
              <Tbody>
                {items.map((item) => {
                  return (
                    <Tr>
                      <Td>{item.name}</Td>
                      <Td>{item.status ? "Ativo" : "Inativo"}</Td>
                      <Td>
                        <HStack spacing={2}>
                          <IconButton
                            colorScheme="green"
                            aria-label="Edit"
                            onClick={() => {
                              localStorage.setItem(
                                "ServiceIdEdit",
                                `${item.id}`
                              );
                              window.location.href = "/admin/services/edit";
                            }}
                            icon={<BiEdit />}
                          />
                          <IconButton
                            colorScheme="red"
                            aria-label="Delete"
                            onClick={() => {
                              localStorage.setItem("ServiceId", `${item.id}`);
                              setConfirmDelete(true);
                            }}
                            icon={<BiTrash />}
                          />
                        </HStack>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>
          <Box d="flex" justifyContent="right">
            <Pagination pages={pages} setAreas={setItems} />
          </Box>
        </Box>
        {confirmDelete && (
          <Box
            position="fixed"
            top={0}
            w="100vw"
            height="100%"
            bgColor="rgba(0,0,0,0.3)"
            d="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              bgColor="white"
              p={8}
              rounded="md"
              alignItems="center"
              d="flex"
              flexDirection="column"
            >
              <Box
                sx={{ border: "4px solid #E53E53", borderRadius: "50%" }}
                mb={6}
              >
                <CloseIcon color="red.500" w={16} h={16} m={4} />
              </Box>
              <Heading size="md">
                Tem certeza que deseja excluir o registro?
              </Heading>
              <HStack mt={2}>
                <Button
                  colorScheme="gray"
                  onClick={() => {
                    setConfirmDelete(false);
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  colorScheme="red"
                  onClick={() => {
                    (async () => {
                      try {
                        await api.delete("/admin/services", {
                          headers: {
                            id: `${localStorage.getItem("ServiceId")}`,
                            Authorization: `Bearer ${localStorage.getItem(
                              "jwt"
                            )}`,
                          },
                        });
                        window.location.reload();
                      } catch (err) {}
                    })();
                  }}
                >
                  Excluir
                </Button>
              </HStack>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}
