import { Select } from "@chakra-ui/react";
import { useField } from "@unform/core";
import { MutableRefObject } from "react";
import { useEffect } from "react";
import { useRef } from "react";

interface selectOption {
  name: string;
  value: string;
}

interface Props {
  name: string;
  label?: string;
  setValue?: any;
  selectData: selectOption[];
  onChange?: any;
}

type InputProps = JSX.IntrinsicElements["input"] & Props;

export default function SelectForm({
  name,
  label,
  setValue,
  selectData,
  required,
  onChange,
}: InputProps) {
  type NewType = MutableRefObject<HTMLSelectElement>;

  const inputRef = useRef() as NewType;
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return inputRef.current.value;
      },
      setValue: (ref, value) => {
        ref.currency.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = "";
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      {label && <label htmlFor={fieldName}>{label}</label>}
      <Select
        mb={4}
        bgColor="white"
        id={fieldName}
        value={defaultValue}
        ref={inputRef}
        onChange={(e) => {
          if (setValue) setValue(e.target.value);
        }}
        required={required}
      >
        <option value="default" disabled selected>
          Selecione
        </option>
        {selectData.map((item) => (
          <option value={item.value}>{item.name}</option>
        ))}
      </Select>
      {error && <span>{error}</span>}
    </>
  );
}
