import {
  Box,
  Flex,
  HStack,
  List,
  ListItem,
  Text,
  VStack,
} from "@chakra-ui/layout";
import { Button, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import api from "../../api";

interface iPackageComponent {
  children?: React.ReactNode;
  title?: string;
  price: string;
  credits: string;
  demanda?: string;
  id?: number;
}

export const PackageComponent: React.FC<iPackageComponent> = ({
  title,
  price,
  credits,
  demanda,
  id,
}) => {
  async function handleSubmit(id: number | string) {
    const response = await api.post(
      "/checkout",
      {
        product_id: `${id}`,
      },
      { headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` } }
    );
    window.location.href = `http://checkout.conexaonr.com.br/?token=${response.data.token}`;
  }

  return (
    <Box
      shadow="base"
      borderWidth="1px"
      alignSelf={{ base: "center", lg: "flex-start" }}
      borderColor={useColorModeValue("gray.200", "gray.500")}
      borderRadius={"xl"}
      h="100%"
    >
      <Flex
        flexDir="column"
        justifyContent="center"
        py={4}
        px={12}
        h="50%"
        alignItems="center"
      >
        <Text fontWeight="500" fontSize="2xl" textAlign="center">
          {title}
        </Text>
        <HStack justifyContent="center">
          <Text fontSize={["2xl", "2xl"]} fontWeight="600">
            R$
          </Text>
          <Text fontSize={["3xl", "3xl"]} fontWeight="900">
            {new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            })
              .format(parseFloat(price))
              .replace("R$", "")
              .trim()}
          </Text>
        </HStack>
      </Flex>
      <VStack
        bg={useColorModeValue("gray.50", "gray.700")}
        py={4}
        borderBottomRadius={"xl"}
        h="max-content"
      >
        <List>
          <ListItem>{credits} Créditos</ListItem>
          <ListItem>
            {new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(parseFloat(price) / parseFloat(credits))}{" "}
            por crédito
          </ListItem>
          <ListItem>{demanda} Demandas</ListItem>
        </List>
        <Box w="80%" pt={7}>
          <Button
            w="full"
            colorScheme="green"
            variant="outline"
            onClick={() => {
              handleSubmit(`${id}`);
            }}
          >
            Comprar
          </Button>
        </Box>
      </VStack>
    </Box>
  );
};
