import { Box, Flex, HStack } from "@chakra-ui/layout";
import { IconButton, Table, Tbody, Td, Thead, Tr } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BiFolder, BiTrash } from "react-icons/bi";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import api from "../../api";
import Header from "../components/Header";
export function Contatos() {
  const [contatos, setContatos] = useState([]);
  const MySwal = withReactContent(Swal);

  useEffect(() => {
    api.get("/contato").then((response) => setContatos(response.data));
  }, []);

  async function deleteContact(id: string) {
    await api.delete(`/contato/${id}`).then(() => window.location.reload());
  }

  return (
    <>
      <Header />
      <Flex w="100%" justifyContent="center" mt={4}>
        <Box w="100%" maxW="1200px">
          <Table variant="striped">
            <Thead>
              <Tr>
                <Td>Nome</Td>
                <Td>Data de envio</Td>
                <Td>Ação</Td>
              </Tr>
            </Thead>
            <Tbody>
              {contatos.map((item: any) => (
                <>
                  <Tr>
                    <Td>
                      {item.first_name} {item.last_name}
                    </Td>

                    <Td>
                      {new Date(item.created_at).toLocaleDateString()} -{" "}
                      {new Date(item.created_at).toLocaleTimeString()}
                    </Td>

                    <Td>
                      <HStack spacing={2}>
                        <IconButton
                          colorScheme="green"
                          aria-label="View"
                          onClick={() => {
                            MySwal.fire(
                              `${item.first_name} ${item.last_name}`,
                              `
                            Email: ${item.email}
                            <br/> 
                            Telefone: ${item.phone}
                            <br/> 
                            Estado: ${item.uf}
                            <br/> 
                            Cidade: ${item.city}
                            <br/> 
                            Assunto: ${item.subject}
                            <br/>
                            Texto: <br/>
                            ${item.text.replace(new RegExp("\n"), "<br/>")}
                            `,
                              "info"
                            );
                          }}
                          icon={<BiFolder />}
                        />
                        <IconButton
                          colorScheme="red"
                          aria-label="Delete"
                          onClick={() => {
                            deleteContact(item.id);
                          }}
                          icon={<BiTrash />}
                        />
                      </HStack>
                    </Td>
                  </Tr>
                </>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Flex>
    </>
  );
}
