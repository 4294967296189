import { Box, Flex, Stack } from "@chakra-ui/layout";
import { Button, HStack, Image, Link } from "@chakra-ui/react";
import { useState } from "react";
import { Link as Linking } from "react-router-dom";
import Logo from "../../../assets/img/conexaonr2_rgb-pq.png";
import MenuClose from "./MenuClose.svg";
import MenuIcon from "./MenuIcon.svg";

export default function Header() {
  const [toggle, setToggle] = useState(false);

  return (
    <>
      <Box
        id="header"
        p={2}
        w="100%"
        d={["none", "none", "flex", "flex"]}
        as="header"
        bg="#07282B"
        color="white"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          maxW="1200px"
          w="100%"
          d="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <a href="/">
            <img src={Logo} alt="Conexão nr" width="200px" height="43px" />
          </a>
          <HStack spacing={4}>
            <Link
              as={Linking}
              _hover={{
                textDecoration: "none",
                color: "rgba(255,255,255, .7)",
              }}
              size="sm"
              to="/"
            >
              Início
            </Link>
            <Link
              as={Linking}
              _hover={{
                textDecoration: "none",
                color: "rgba(255,255,255, .7)",
              }}
              to="/comofunciona"
            >
              Como Funciona
            </Link>
            <Link
              as={Linking}
              _hover={{
                textDecoration: "none",
                color: "rgba(255,255,255, .7)",
              }}
              to="/signup"
            >
              Seja um Parceiro
            </Link>
            <Link
              _hover={{
                textDecoration: "none",
                color: "rgba(255,255,255, .7)",
              }}
              href="https://blog.conexaonr.com.br"
              target="_blank"
            >
              Blog
            </Link>
            <Link
              as={Linking}
              _hover={{
                textDecoration: "none",
              }}
              bg="white"
              color="rgb(7, 40, 43)"
              to="/login"
              p={2}
              rounded="md"
            >
              Entrar
            </Link>
          </HStack>
        </Box>
      </Box>
      <Flex
        d={["flex", "flex", "none", "none"]}
        bg="#07282B"
        direction="column"
        color="white"
      >
        <Box
          p={2}
          w="100%"
          d={["flex", "flex", "none", "none"]}
          as="header"
          alignItems="center"
          justifyContent="space-between"
        >
          <a href="/">
            <img src={Logo} alt="Conexão nr" width="200px" height="43px" />
          </a>
          <Box>
            <Button
              bg="transparent"
              _focus={{}}
              _active={{}}
              _hover={{}}
              onClick={() => {
                toggle ? setToggle(false) : setToggle(true);
              }}
            >
              <Image src={toggle ? MenuClose : MenuIcon} />
            </Button>
          </Box>
        </Box>
        {toggle && (
          <>
            <Stack direction="column" p={5} spacing={4}>
              <Link
                as={Linking}
                _hover={{
                  textDecoration: "none",
                  color: "rgba(255,255,255, .7)",
                }}
                size="sm"
                to="/"
              >
                Início
              </Link>
              <Link
                as={Linking}
                _hover={{
                  textDecoration: "none",
                  color: "rgba(255,255,255, .7)",
                }}
                to="/comofunciona"
              >
                Como Funciona
              </Link>
              <Link
                as={Linking}
                _hover={{
                  textDecoration: "none",
                  color: "rgba(255,255,255, .7)",
                }}
                to="/signup"
              >
                Seja um Parceiro
              </Link>
              <Link
                _hover={{
                  textDecoration: "none",
                  color: "rgba(255,255,255, .7)",
                }}
                href="https://blog.conexaonr.com.br"
                target="_blank"
              >
                Blog
              </Link>
              <Link
                as={Linking}
                _hover={{
                  textDecoration: "none",
                }}
                bg="white"
                color="rgb(7, 40, 43)"
                to="/login"
                p={2}
                rounded="md"
              >
                Entrar
              </Link>
            </Stack>
          </>
        )}
      </Flex>
    </>
  );
}
