import { Box, Heading, HStack } from "@chakra-ui/layout";
import { Alert, AlertIcon, Button, Input } from "@chakra-ui/react";
import { useState } from "react";
import api from "../../../../api";

interface Db {
  name: string;
  created_at: Date;
  updated_at: Date;
  id: number;
}

interface ResponseData {
  data: string;
  db: Db;
}

export default function Main() {
  const [name, setName] = useState("");
  const [success, setSuccess] = useState({ status: false, data: "" });
  const [error, setError] = useState({ status: false, data: "" });

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e!.preventDefault();
    try {
      const response = await api.post<ResponseData>(
        "/area",
        {
          name,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setSuccess({ status: true, data: response.data.data });
      setTimeout(() => {
        window.location.href = "/admin";
      });
    } catch (err) {
      setError({ status: true, data: err.response.data.data });
    }
  }

  return (
    <Box
      d={["flex", "flex", "flex", "flex"]}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      w="100%"
      mt={2}
    >
      <Box w={["80%", "80%", "20%", "20%"]}>
        <Heading>Cadastrar Área</Heading>
        <form onSubmit={handleSubmit}>
          <Box mt={8}>
            <label htmlFor="Nome">Nome</label>
            <Input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              id="Nome"
              required
            />
          </Box>
          <HStack spacing={["auto", "auto", "auto", "auto"]} mt={18}>
            <Button
              onClick={() => {
                window.location.href = "/admin";
              }}
            >
              Cancelar
            </Button>
            <Button colorScheme="green" type="submit">
              Salvar
            </Button>
          </HStack>

          {success.status && (
            <>
              <Alert
                status="success"
                w={["auto", "auto", "auto", "auto"]}
                mt={2}
              >
                <AlertIcon />
                {success.data}
              </Alert>
            </>
          )}
          {error.status && (
            <>
              <Alert status="error" w={["auto", "auto", "auto", "auto"]} mt={2}>
                <AlertIcon />
                {error.data}
              </Alert>
            </>
          )}
        </form>
      </Box>
    </Box>
  );
}
