export interface iService {
  id: number;
  name: string;
}

export function getServices(): iService[] {
  return JSON.parse(localStorage.getItem("service") ?? "[]");
}

export function setDefaultService(params: iService[]): void {
  localStorage.setItem("service", JSON.stringify(params));
}

export function setService(params: iService): iService[] {
  const array: iService[] =
    JSON.parse(`${localStorage.getItem("service")}`) ?? [];

  let validation = array.filter((item) => {
    return item === params;
  });

  if (validation.length === 0) {
    array.push(params);
    localStorage.setItem("service", JSON.stringify(array));
    return JSON.parse(`${localStorage.getItem("service")}`);
  }
  return array;
}
