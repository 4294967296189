import { Box, Flex, Stack } from "@chakra-ui/layout";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Input,
  Radio,
  RadioGroup,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import api from "../../api";
import Footer from "../../components/Footer";
import uf from "../../components/UF";
import Header from "../components/Header";

export function ProfileEdit() {
  const MySwal = withReactContent(Swal);
  const toast = useToast();

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [street, setStreet] = useState("");
  const [UserUf, setUserUF] = useState<string[]>([]);
  const [UserCity, setUserCity] = useState<string[]>([]);
  const [streetNumber, setStreetNumber] = useState<string>("");

  const [areas, setAreas] = useState<any[]>([]);

  const [citys, setCitys] = useState<string[]>([]);
  const [currencyUf, setCurrencyUf] = useState<string>("");

  const [selectAreas, setSelectAreas] = useState<any>(null);
  const [newAreas, setNewAreas] = useState<any[]>([]);
  const [serviceAreaSelect, setServiceAreaSelectOption] =
    useState<string>("false");

  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [userAreas, setUserAreas] = useState<string[]>([]);
  const [userNrs, setUserNrs] = useState<string[]>([]);
  const [nrs, setNrs] = useState<any[]>([]);
  const [cep, setCep] = useState<string>("");
  const [complemento, setComplemento] = useState<string>("");

  const findCep = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setCep(e.target.value);
    const cep = e.target.value
      .replace(".", "")
      .replace("-", "")
      .replace(new RegExp("_", "g"), "");
    if (cep.length === 8) {
      try {
        const response = await axios.get(
          `https://brasilapi.com.br/api/cep/v1/${cep}`
        );
        setStreet(response.data.street);
        setComplemento("");
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    (async () => {
      const response = await api
        .get("/auth/me", {
          headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
        })
        .then((response) => response.data.userData);
      setName(response.name.trim());
      setSurname(response.surname.trim());
      setPhone(response.phone);
      setEmail(response.email);
      const [cep, currencyStreet, streetNumber, comp] =
        response.street.split(" - ");
      setStreet(currencyStreet);
      setCep(cep);
      setComplemento(comp);
      setStreetNumber(streetNumber);
      setUserNrs(response.nrs?.map((item: string) => item));
      setUserAreas(
        response.areas.sort((a: any, b: any) => {
          if (b.id > a.id) return -1;
          if (a.id > b.id) return 1;
          return 0;
        })
      );
      if (response.uf !== "All" && response.city !== "All") {
        setUserUF(response.uf.split(","));
        setUserCity(response.city.split(","));
      } else {
        setUserUF(uf.map((item) => item.sigla));
        setUserCity(
          uf
            .map((item) => item.cidades.toString())
            .toString()
            .split(",")
        );
      }

      const areas = await api.get("/area", {});
      const nrs = await api.get("/nrs/find", {
        params: { id: 10 },
        headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
      });

      setNrs(
        nrs.data.sort((a: any, b: any) => {
          if (b.id > a.id) return -1;
          if (a.id > b.id) return 1;
          return 0;
        })
      );
      setAreas(areas.data);
    })();
  }, []);

  function isAllCitys(citys: string[]): boolean {
    let allCitys = false;
    for (let i = 0; i < citys.length; i++) {
      const element = citys[i];
      if (UserCity.includes(element)) {
        allCitys = true;
      } else {
        return false;
      }
    }
    return allCitys;
  }

  function setServiceAreaSelect(value: any) {
    console.log(value, selectAreas);
    if (value === "true") {
      setUserAreas([...userAreas, selectAreas]);
      setServiceAreaSelectOption("true");
    } else {
      let local = userAreas;
      local.filter((value, index, arr) => {
        if (value === selectAreas) {
          arr.splice(index, 1);
          return true;
        }
        return false;
      });
      console.log(local);
      setServiceAreaSelectOption("false");
      setUserAreas(local);
    }
  }

  function FindCityByState(state: string): void {
    console.log(UserCity);
    const currencyUf = uf.filter((uf) => uf.sigla === state)[0];
    setCitys(currencyUf.cidades);
    setCurrencyUf(state);
    const isCheked = isAllCitys(currencyUf.cidades);
    console.log(`isCheked ${isChecked}`);
    setIsChecked(isCheked);
  }

  async function update() {
    MySwal.fire({
      title: "Deseja salvar as alterações",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
      confirmButtonText: "Salvar",
    })
      .then(async (response) => {
        if (response.isConfirmed) {
          try {
            await api.put(
              "/auth/me",
              {
                name,
                surname,
                phone,
                uf: UserUf.toString(),
                city: UserCity.toString(),
                street: `${cep.replace(
                  new RegExp("-", "g"),
                  ""
                )} - ${street.replace(
                  new RegExp(" - ", "g"),
                  " "
                )} - ${streetNumber.replace(
                  new RegExp("-", "g"),
                  ""
                )} - ${complemento.replace(new RegExp("-", "g"), " ")}`,
                area: userAreas.toString(),
                nrs: userAreas.find((item) => item === "10")
                  ? userNrs.toString()
                  : [].toString(),
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                },
              }
            );

            toast({
              title: "Dados salvos com sucesso.",
              duration: 2000,
              status: "success",
              isClosable: true,
              position: "top-right",
            });
            setTimeout(() => {
              window.location.href = "/dashboard";
            }, 2100);
          } catch (err) {
            // setTimeout(() => {
            //   window.location.href = "/dashboard";
            // }, 2100);
          }
        }
      })
      .catch((err) => {});
  }

  return (
    <>
      <Header />
      <Flex my={8} flexDir="column" h="100%" w="100%">
        <Box
          w="100%"
          d={["flex", "flex"]}
          justifyContent="center"
          alignContent="center"
          p={2}
        >
          <Box w={["100%", "50%"]}>
            <Box
              w="100%"
              d="flex"
              flexDir="column"
              alignItems="center"
              justifyContent="left"
            >
              <Box my={2} w="100%">
                <label>Primeiro nome</label>
                <Input
                  value={name}
                  type="text"
                  onChange={(e: any) => setName(e.target.value)}
                  required={true}
                />
              </Box>
              <Box my={2} w="100%">
                <label>Último nome</label>
                <Input
                  value={surname}
                  type="text"
                  onChange={(e: any) => setSurname(e.target.value)}
                  required={true}
                />
              </Box>

              <Box my={2} w="100%">
                <label>Telefone</label>
                <Input
                  value={phone}
                  type="tel"
                  onChange={(e: any) => setPhone(e.target.value)}
                  required={true}
                />
              </Box>
              <Box my={2} w="100%">
                <label>Cep</label>
                <Input
                  value={cep}
                  type="text"
                  onChange={(e: any) => findCep(e)}
                  required={true}
                />
              </Box>
              <Flex w="100%">
                <Box my={2} mr={2} w="100%">
                  <label>Endereço</label>
                  <Input
                    value={street}
                    type="text"
                    onChange={(e: any) => setStreet(e.target.value)}
                    required={true}
                    disabled={true}
                  />
                </Box>

                <Box my={2} w="100%">
                  <label>Número</label>

                  <Input
                    value={streetNumber}
                    type="text"
                    onChange={(e: any) => setStreetNumber(e.target.value)}
                    required={true}
                  />
                </Box>
              </Flex>
              <Box my={2} w="100%">
                <label>Complemento</label>
                <Input
                  value={complemento}
                  type="text"
                  onChange={(e: any) => setComplemento(e.target.value)}
                  required={true}
                />
              </Box>
              <Box my={2} w="100%">
                <label>E-mail</label>
                <Input type="mail" value={email} required={true} disabled />
              </Box>
              <Box my={2} w="100%">
                <Text textAlign="left">Inserir/retirar regiões que atende</Text>
              </Box>
              <Box my={2} w="100%">
                <label>Estados</label>
                <Select
                  placeholder="Selecione"
                  onChange={(e) => FindCityByState(e.target.value)}
                >
                  {uf.map((uf, index) => (
                    <option key={index} value={uf.sigla}>
                      {uf.nome}
                    </option>
                  ))}
                </Select>
              </Box>

              {citys.length > 0 && (
                <Box my={2} w="100%">
                  <label>Cidades</label>
                  <Box maxH="20vh" overflowY="scroll" m={2}>
                    <Checkbox
                      value="All"
                      m={2}
                      isChecked={isChecked}
                      onChange={(e) => {
                        if (e.target.checked) {
                          let localCitys = [...UserCity, ...citys];
                          setUserCity([...new Set(localCitys)]);
                          setIsChecked(e.target.checked);
                          setUserUF([...new Set([...UserUf, currencyUf])]);
                        } else {
                          let localCitys = UserCity.filter((userCity) => {
                            if (!citys.find((city) => city === userCity)) {
                              return userCity;
                            }
                          });
                          console.log(localCitys);
                          setUserCity(localCitys);
                          setUserUF(
                            UserUf.filter((userUf) => userUf !== currencyUf)
                          );
                          setIsChecked(e.target.checked);
                        }
                      }}
                    >
                      Todas cidades
                    </Checkbox>
                    <CheckboxGroup
                      value={UserCity}
                      onChange={(e) => {
                        let localCitys: string[] = [
                          ...e.map((city) => city.toString()),
                        ];
                        setUserCity([...new Set(localCitys)]);
                      }}
                    >
                      <Stack m={2}>
                        {citys.map((city, index) => (
                          <Checkbox keu={index} value={city}>
                            {city}
                          </Checkbox>
                        ))}
                      </Stack>
                    </CheckboxGroup>
                  </Box>
                </Box>
              )}
              <Box my={2} w="100%">
                <Text textAlign="left">
                  Inserir/retirar areas/NRs que atende
                </Text>
              </Box>

              <Box my={2} w="100%">
                <label>Areas</label>
                <Select
                  placeholder="Selecione"
                  onChange={(e) => {
                    console.log(userAreas, e.target.value);
                    userAreas.find((item: any) => item == e.target.value)
                      ? setServiceAreaSelectOption("true")
                      : setServiceAreaSelectOption("false");
                    console.log(userAreas);
                    setSelectAreas(e.target.value);
                  }}
                >
                  {areas.map(
                    (area, index) =>
                      area.visible && (
                        <option key={index} value={area.id}>
                          {area.name}
                        </option>
                      )
                  )}
                </Select>
              </Box>
              {selectAreas && (
                <Box my={2} w="100%">
                  <RadioGroup
                    onChange={(e) => setServiceAreaSelect(e)}
                    value={serviceAreaSelect}
                  >
                    <label>Atendo/Não atendo</label>
                    <Stack spacing={5} direction="row">
                      <Radio value={"true"}>Atendo</Radio>
                      <Radio value={"false"}>Não atendo</Radio>
                    </Stack>
                  </RadioGroup>
                </Box>
              )}
              {selectAreas === "10" && serviceAreaSelect === "true" && (
                <Box my={4} w="100%">
                  <label>Selecione as nrs que atende</label>
                  <CheckboxGroup
                    value={userNrs}
                    onChange={(e: string[]) => {
                      setUserNrs(e);
                    }}
                  >
                    <Stack
                      direction="column"
                      maxH="300px"
                      overflow="auto"
                      my={2}
                    >
                      {nrs.map((item) =>
                        item.visible === true ? (
                          <Checkbox value={item.id.toString()} key={item.id}>
                            {item.name}
                          </Checkbox>
                        ) : (
                          <></>
                        )
                      )}
                    </Stack>
                  </CheckboxGroup>
                </Box>
              )}

              <Button colorScheme="green" onClick={update}>
                Salvar Dados
              </Button>
            </Box>
          </Box>
        </Box>
      </Flex>
      <Footer />
    </>
  );
}
