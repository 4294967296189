import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Admin from "./Admin";
import Add from "./Admin/Add";
import Change from "./Admin/Change";
import CMS from "./Admin/CMS";
import CMSPerguntasFrequentes from "./Admin/CMS/Asks";
import EditAsks from "./Admin/CMS/Asks/edit";
import AsksNew from "./Admin/CMS/Asks/new";
import CmsHome from "./Admin/CMS/Home";
import CMSMissionAndValues from "./Admin/CMS/Mission";
import { Contatos } from "./Admin/Contatos";
import Credits from "./Admin/Credits";
import AddCredits from "./Admin/Credits/Add";
import EditCredits from "./Admin/Credits/Edit";
import DemandAdmin from "./Admin/DemandaAdmin";
import DemandaEdit from "./Admin/DemandaAdmin/Edit";
import ParceiroAdmin from "./Admin/FornecedorAdmin";
import NRsPageAdmin from "./Admin/NRs";
import NRsAddPageAdmin from "./Admin/NRs/Add";
import NrsPageChangeAdmin from "./Admin/NRs/Change";
import ServicesAdmin from "./Admin/ServicesAdmin";
import ServiceAdd from "./Admin/ServicesAdmin/Add";
import ServiceEdit from "./Admin/ServicesAdmin/Edit";
import Blog from "./Blog";
import ChangePassword from "./ChangePassword";
import ComoFunciona from "./ComoFunciona";
import AConexaoNR from "./ConexaoNR";
import Dashboard from "./Dashboard";
import { DemandasInscritas } from "./Dashboard/DemandasInscritas";
import ParceiroEdit from "./Dashboard/Fornecedor/Edit";
import Packages from "./Dashboard/Packages";
import { ProfileEdit } from "./Dashboard/ProfileEdit";
import DashboardData from "./Dashboard/Providers/Loading";
import ServiceView from "./Dashboard/ServiceView";
import DemandaPrimary from "./Demanda/primary";
import DemandaSecond from "./Demanda/second";
import DemandaThird from "./Demanda/third";
import { FaleConosco } from "./FaleConosco";
import Home from "./Home";
import Login from "./Login";
import MissaoVisaoEValor from "./MissaoVisaoEValores";
import PerguntasFrequentes from "./PerguntasFrequentes";
import RecoveryPassword from "./RecoveryPassword";
import Register from "./Register";
import SecondState from "./SecondState";
import ThirdState from "./ThirdState";

const theme = extendTheme({
  initialColorMode: "light",
  useSystemColorMode: false,
  colors: {
    green: {
      300: "#35AC5B",
      500: "#198754",
      700: "#07282B",
    },
  },
  components: {
    Link: {
      baseStyle: {
        fontWeight: "bold",
        outline: "none",
      },
    },
  },
});

function App() {
  return (
    <DashboardData>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/faleconosco" component={FaleConosco} exact />
            <Route path="/comofunciona" component={ComoFunciona} exact />

            <Route path="/login" component={Login} exact />
            <Route path="/signup" component={Register} exact />
            <Route
              path="/perguntas-frequentes"
              component={PerguntasFrequentes}
              exact
            />
            <Route
              path="/missao-visao-valores"
              component={MissaoVisaoEValor}
              exact
            />
            <Route path="/conexaonr" component={AConexaoNR} exact />
            <Route path="/recovery" component={RecoveryPassword} exact />
            <Route path="/change/password" component={ChangePassword} exact />
            <Route path="/dashboard" component={Dashboard} exact />
            <Route path="/dashboard/profile" component={ProfileEdit} exact />
            <Route path="/dashboard/pacotes" component={Packages} exact />
            <Route
              path="/dashboard/service/:id"
              component={ServiceView}
              exact
            />

            <Route path="/second-state" component={SecondState} exact />
            <Route path="/third-state" component={ThirdState} exact />

            <Route path="/admin" component={Admin} exact />
            <Route path="/admin/contatos" component={Contatos} exact />
            <Route
              path="/demandasinscritas"
              component={DemandasInscritas}
              exact
            />
            <Route path="/admin/area/add" component={Add} exact />
            <Route path="/admin/area/edit" component={Change} exact />
            <Route path="/admin/nrs" component={NRsPageAdmin} exact />
            <Route path="/admin/nrs/add" component={NRsAddPageAdmin} exact />
            <Route
              path="/admin/nrs/edit"
              component={NrsPageChangeAdmin}
              exact
            />

            <Route path="/admin/cms" component={CMS} exact />
            <Route path="/admin/cms/home" component={CmsHome} exact />
            <Route
              path="/admin/cms/MissaoValores"
              component={CMSMissionAndValues}
              exact
            />
            <Route
              path="/admin/cms/PerguntasFrequentes"
              component={CMSPerguntasFrequentes}
              exact
            />

            <Route
              path="/admin/cms/PerguntasFrequentes/edit"
              component={EditAsks}
              exact
            />

            <Route
              path="/admin/cms/PerguntasFrequentes/new"
              component={AsksNew}
              exact
            />

            <Route path="/admin/credits" component={Credits} exact />
            <Route path="/admin/credits/add" component={AddCredits} exact />
            <Route path="/admin/credits/edit" component={EditCredits} exact />

            <Route path="/admin/services" component={ServicesAdmin} exact />
            <Route path="/admin/services/add" component={ServiceAdd} exact />
            <Route path="/admin/services/edit" component={ServiceEdit} exact />

            {/* <Route path="/admin/blog" component={DadosBlogAdmin} exact />
            <Route path="/admin/blog/add" component={DadosBlogAdd} exact />
            <Route path="/admin/blog/edit" component={EditBlog} exact /> */}

            <Route path="/admin/demanda" component={DemandAdmin} exact />
            <Route path="/admin/demanda/edit" component={DemandaEdit} exact />

            <Route path="/admin/Parceiro" component={ParceiroAdmin} exact />
            <Route
              path="/admin/Parceiro/edit/:id"
              component={ParceiroEdit}
              exact
            />
            <Route path="/blog" component={Blog} exact />

            <Route path="/demanda" component={DemandaPrimary} exact />
            <Route
              path="/demanda/second-state"
              component={DemandaSecond}
              exact
            />
            <Route path="/demanda/third-state" component={DemandaThird} exact />
          </Switch>
        </BrowserRouter>
      </ChakraProvider>
    </DashboardData>
  );
}

export default App;
