import { Button } from "@chakra-ui/button";
import { Flex, Grid, GridItem, Heading, Text } from "@chakra-ui/layout";
import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

const ComoFunciona: React.FC = () => {
  return (
    <Flex flexDir="column" minH="100vh" h="auto">
      <Header />
      <Flex
        w="100%"
        textAlign="center"
        alignItems="center"
        mt={8}
        mb={8}
        flexDir="column"
      >
        <Heading size="lg">Como funciona a Conexão NR</Heading>
        <Text w={["90%", "70%", "50%"]} mt={3} fontSize="lg">
          Através de um sistema especializado em SST - Saúde e Segurança do
          Trabalho, nossa plataforma conecta empresas e prestadores de serviços
          através de um único Pedido de Orçamento.
        </Text>
        <Text w={["90%", "70%", "60%"]} mt={3} fontSize="lg">
          Dessa forma, levamos às empresas, Parceiros capacitados tecnicamente
          para atendê-las, e, aos prestadores de serviços, levamos apenas
          empresas relevantes e que estejam os mais próximos de seu lead (lead:
          potencial consumidor da sua empresa).
        </Text>
        <Heading size="lg" mt={8} mb={4}>
          Vantagens e Benefícios
        </Heading>
        <Grid
          w="100%"
          h="1fr"
          gridTemplateColumns={[
            "repeat(1, 1fr)",
            "repeat(1, 1fr)",
            "repeat(1, 1fr)",
            "repeat(1, 1fr)",
            "repeat(2, 1fr)",
          ]}
          gridTemplateRows="1fr"
        >
          <GridItem w="100%" h="100%">
            <Heading size="lg" mt={6}>
              Para Empresas
            </Heading>
            <Text w={["100%"]} fontSize="lg">
              Gratuito: Não pague nada para fazer seu Pedido de Orçamento
            </Text>

            <Text w={["100%"]} fontSize="lg">
              Agilidade: Receba rapidamente até 5 orçamentos com apenas 1 pedido
            </Text>

            <Text w={["100%"]} fontSize="lg">
              Precisão: Recebe apenas contatos de prestadores que podem atender
              sua demanda
            </Text>

            <Text w={["100%"]} fontSize="lg">
              Redução de custos: Escolha o Parceiro conforme suas necessidades e
              budget
            </Text>

            <Text w={["100%"]} fontSize="lg">
              Sem burocracia: Feche negócio diretamente
            </Text>

            <Button
              onClick={() => {
                window.location.href = "/#header";
              }}
              colorScheme="yellow"
              fontSize="lg"
              mt={4}
            >
              Faça agora seu pedido de orçamento
            </Button>
          </GridItem>

          <GridItem w="100%" h="100%" mt={[6, 0]}>
            <Heading size="lg" mt={6}>
              Para Parceiros
            </Heading>
            <Text w={["100%"]} fontSize="lg">
              Faça seu cadastro gratuitamente
            </Text>

            <Text w={["100%"]} fontSize="lg">
              Custo zero para receber os Pedidos de Orçamento
            </Text>

            <Text w={["100%"]} fontSize="lg">
              Se inscreva apenas nas oportunidades que achar interessantes
            </Text>

            <Text w={["100%"]} fontSize="lg">
              Saiba o que as empresas estão procurando e se mantenha competitivo
            </Text>

            <Text w={["100%"]} fontSize="lg">
              Feche negócio sem intermediários e fique com 100% do valor orçado
              ao cliente
            </Text>

            <Button
              onClick={() => {
                window.location.href = "/signup/#header";
              }}
              colorScheme="green"
              fontSize="lg"
              mt={4}
            >
              Cadastre-se agora gratuitamente
            </Button>
          </GridItem>
        </Grid>
      </Flex>
      <Footer />
    </Flex>
  );
};

export default ComoFunciona;
