import { useRef } from "react";

import { SubmitHandler, FormHandles } from "@unform/core";

import { Form } from "@unform/web";

import Input from "../Input";
import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Heading,
} from "@chakra-ui/react";
import Select from "../Select";
import { useState } from "react";

interface FormData {
  branchOfTheCompany: string;
  companyType: string;
  maxDate: string;
  numberOfemployees: string;
}

export default function MyForm() {
  const formRef = useRef<FormHandles>(null);
  const [error, setError] = useState({ status: false, data: "" });
  const [success, setSuccess] = useState({ status: false, data: "" });
  const [branchOfTheTypeCompany, setbranchOfTheTypeCompany] =
    useState<string>();

  function validation(params: FormData) {
    if (params.branchOfTheCompany === "default") {
      setError({
        status: true,
        data: "Selecione o ramo da sua empresa",
      });

      return false;
    }
    if (params.numberOfemployees === "default") {
      setError({
        status: true,
        data: "Selecione a quantidade de colaborades",
      });

      return false;
    }

    if (params.maxDate === "default") {
      setError({
        status: true,
        data: "Selecione para quando você necessita deste(s) serviço(s)",
      });

      return false;
    }
    return true;
  }

  const handleSubmit: SubmitHandler<FormData> = (data) => {
    setError({
      status: false,
      data: "",
    });
    if (validation(data)) {
      setSuccess({
        status: true,
        data: "Você vai ser redicionado(a) em instantes",
      });
      localStorage.setItem("primary", JSON.stringify(data));
      setTimeout(() => {
        window.location.href = "/demanda/second-state";
      }, 1600);
    }
  };

  return (
    <Box
      maxW={["90vw", "40vw"]}
      // maxH={["70vh", "100vh"]}
      w="100%"
      h="auto"
      bgColor="gray.100"
      rounded="md"
      boxShadow="sm"
      p={2}
      mt={2}
      mb={2}
    >
      <Breadcrumb fontWeight="medium" fontSize="sm">
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href="#">Informações Principais</BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink href="#" color="grey">
            Localização
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink href="#" color="grey">
            Contato
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Box d="flex" justifyContent="center">
        <Heading size="lg" mb={4} mt={2}>
          Informações Principais
        </Heading>
      </Box>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Select
          name="branchOfTheCompany"
          label="Qual é o ramo da sua empresa?"
          value={branchOfTheTypeCompany}
          setValue={setbranchOfTheTypeCompany}
          selectData={[
            { name: "Comércio", value: "Comércio" },
            { name: "Indústria", value: "Indústria" },
            { name: "Prestação de Serviços", value: "Prestação de Serviços" },
            { name: "Não encontrei meu ramo de atividade", value: "other" },
          ]}
        />

        {branchOfTheTypeCompany === "other" && (
          <Input name="other" label="Informe seu Ramo de Atividade" />
        )}

        <Input
          name="companyType"
          label="Qual tipo da empresa?"
          placeholder="escritório, restaurante, loja, fábrica..."
          required={true}
        />

        <Select
          name="numberOfemployees"
          label="Qual o número de colaboradores?"
          selectData={[
            { name: "Entre 01 e 20", value: "Entre 01 e 20" },
            { name: "Entre 21 e 50", value: "Entre 21 e 50" },
            { name: "Entre 51 e 100", value: "Entre 51 e 100" },
            { name: "Entre 101 e 200", value: "Entre 101 e 200" },
            { name: "Entre 201 e 500", value: "Entre 201 e 500" },
            { name: "Mais de 500", value: "Mais de 500" },
          ]}
          required={true}
        />

        <Select
          name="maxDate"
          label="Para quando você necessita deste(s) serviço(s)?"
          selectData={[
            { name: "O mais breve possível", value: "O mais breve possível" },
            { name: "Em até 30 dias", value: "Em até 30 dias" },
            { name: "Em até 60 dias", value: "Em até 60 dias" },
            {
              name: "Ainda não tenho previsão",
              value: "Ainda não tenho previsão",
            },
            {
              name: "Estou com fiscalização/auditoria",
              value: "Estou com fiscalização/auditoria",
            },
          ]}
        />

        {error.status && (
          <Box w="100%" mt={2} mb={2}>
            <Alert status="error" rounded="md">
              <AlertIcon />
              {error.data}
            </Alert>
          </Box>
        )}

        {success.status && (
          <Box w="100%" mt={2} mb={2}>
            <Alert status="success" rounded="md">
              <AlertIcon />
              {success.data}
            </Alert>
          </Box>
        )}

        <Box w="100%" d="flex" justifyContent="right">
          <Button type="submit" colorScheme="green">
            Avançar
          </Button>
        </Box>
      </Form>
    </Box>
  );
}
