import { Image } from "@chakra-ui/image";
import { Flex, Grid, GridItem, Heading, Text } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";
import api from "../api";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Loading from "../components/Loading";
import Person01 from "./person01.svg";

const MissaoVisaoEValor: React.FC = () => {
  const [itens, setItens] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  async function getDataOfPage() {
    try {
      const response = await api.get("/pages", { params: { page: "quest" } });
      setItens(response.data.reverse());
      setLoading(false);
    } catch (err) {}
  }

  useEffect(() => {
    getDataOfPage();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Header />
      <Flex
        mt={8}
        w="100%"
        h="100%"
        flexDir="column"
        alignItems="center"
        textAlign="center"
        justifyContent="center"
      >
        <Heading size="lg">Missão, Visão e Valores</Heading>
        <Text fontSize="xl" fontWeight="bold" mt={4}>
          Missões da empresa
        </Text>
        <Text size="lg" fontWeight="bold" mt={2} mb={2}>
          Às empresas que necessitam de prestadores de Saúde e Segurança do
          Trabalho
        </Text>

        <Text>
          Ser uma facilitadora, ajudando empresas que necessitem de serviços de
          SST, por mais específicas que sejam, a encontrarem empresas e
          profissionais capacitados de forma rápida e fácil.
        </Text>

        <Text size="lg" fontWeight="bold" mt={2} mb={2}>
          Aos nossos Parceiros
        </Text>

        <Text>
          Levar aos nossos parceiros, prospects relevantes e os mais próximos do
          seu lead (lead: potencial consumidor da sua empresa), auxiliando na
          conquista de
        </Text>
        <Text>
          novos clientes e mantendo-os em contato constante com o mercado de
          SST.
        </Text>

        <Text fontSize="xl" fontWeight="bold" mt={4} mb={2}>
          Visão
        </Text>

        <Text>
          Ser conhecida como a plataforma pioneira e definitiva para busca de
          prestadores de serviços de Saúde e Segurança do Trabalho. Através da
          informação,
        </Text>
        <Text>
          manter o mercado sempre ativo para os profissionais da área, bem como
          auxiliar empresas a manterem seus ambientes de trabalho seguros e
          saudáveis.
        </Text>

        <Text fontSize="xl" fontWeight="bold" mt={4} mb={2}>
          Valores
        </Text>

        <Text mb={8}>
          Valorização do Ser Humano, Respeito aos Profissionais, Inovação, Ética
          e Meritocracia.
        </Text>
      </Flex>

      <Footer />
    </>
  );
};

export default MissaoVisaoEValor;
