import { Box, Text } from "@chakra-ui/layout";
import { Alert, AlertIcon, Button, Input, Select } from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import api from "../../api/index";

interface SocialItem {
  id: number;
  name: string;
  created_at: Date;
  updated_at: Date;
}

interface Props {
  userName: string;
  userSurname: string;
}

export default function Fisica({ userName, userSurname }: Props) {
  const [cpf, setCpf] = useState("");
  const [name, setName] = useState(userName);
  const [surname, setSurname] = useState(userSurname);
  const [cep, setCep] = useState("");
  const [PublicPlace, setPublicPlace] = useState("");
  const [StreetNumber, setStreetNumber] = useState("");
  const [Complement, setComplement] = useState("");
  const [DDD, setDDD] = useState(localStorage.getItem("ddd") || "");
  const [Phone, setPhone] = useState(
    localStorage.getItem("phone-number") || ""
  );
  const [Profession, setProfession] = useState("");
  const [OtherProfission, setOtherProfission] = useState("");
  const [Register, setRegister] = useState("");
  const [SocialNetwork, setSocialNetwork] = useState("");
  const [OtherSocialNetWork, setOtherSocialNetWork] = useState("");
  const [socialData, setSocialData] = useState<SocialItem[]>([]);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const findCep = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setCep(e.target.value);
    const cep = e.target.value
      .replace(".", "")
      .replace("-", "")
      .replace(new RegExp("_", "g"), "");
    if (cep.length === 8) {
      try {
        const response = await axios.get(
          `https://brasilapi.com.br/api/cep/v1/${cep}`
        );
        setPublicPlace(response.data.street);
      } catch (err) {
        console.log(err);
      }
    }
  };

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (Profession !== "Outro" && SocialNetwork !== "Outro") {
      try {
        await api.post(
          "/details/pf",
          {
            cpf,
            name,
            surname,
            street: `${cep} - ${PublicPlace.replace(
              new RegExp(" - ", "g"),
              " "
            )} - ${StreetNumber.replace(
              new RegExp("-", "g"),
              ""
            )} - ${Complement}`,
            DDD,
            Phone: `${DDD}${Phone}`,
            profission: Profession,
            register: Register,
            socialnetwork: SocialNetwork,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );

        setSuccess(true);
        await delay(1500);
        window.location.href = "/third-state";
      } catch (err) {
        setError(true);
      }
    } else {
      if (Profession === "Outro") {
        setProfession(OtherProfission);
      }
      if (SocialNetwork === "Outro") {
        setSocialNetwork(OtherSocialNetWork);
      }

      try {
        const response = await api.post(
          "/details/pf",
          {
            cpf,
            name,
            surname,
            street: `${cep} - ${PublicPlace} ${StreetNumber} - ${Complement}`,
            DDD,
            Phone: `${DDD}${Phone}`,
            profission: Profession,
            register: Register,
            socialnetwork: SocialNetwork,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );

        if (response.status !== 201) {
          setError(true);
        } else {
          setSuccess(true);
          await delay(1500);
          window.location.href = "/third-state";
        }
      } catch (err) {
        setError(true);
      }
    }

    console.log(`${PublicPlace} ${StreetNumber} - ${Complement}`);
  }

  useEffect(() => {
    (async () => {
      const data = (await api.get("/social")).data;
      setSocialData(data);
      console.log(data);
    })();
  }, []);

  return (
    <Box w="100%">
      <Text>Dados Adicionais</Text>
      <form onSubmit={handleSubmit}>
        <Box m={2}>
          {error && (
            <Alert status="error">
              <AlertIcon />
              Houve um erro, tente novamente!
            </Alert>
          )}
          {success && (
            <Alert status="success">
              <AlertIcon />
              Detalhes adicionados com sucesso!
            </Alert>
          )}

          <Box mb={2}>
            <label htmlFor="cpf">CPF *</label>
            <Input
              value={cpf}
              onChange={(e) => {
                setCpf(e.target.value);
              }}
              id="cpf"
              as={InputMask}
              mask="999.999.999-99"
              required
            />
          </Box>
          <Box mb={2}>
            <label htmlFor="name">Nome *</label>
            <Input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              id="name"
              required
            />
          </Box>
          <Box mb={2}>
            <label htmlFor="surname">Sobrenome *</label>
            <Input
              value={surname}
              onChange={(e) => {
                setSurname(e.target.value);
              }}
              id="surname"
              required
            />
          </Box>
          <Box mb={2}>
            <label htmlFor="cep">CEP *</label>
            <Input
              value={cep}
              onChange={findCep}
              id="cep"
              as={InputMask}
              mask="99.999-999"
              required
            />
          </Box>
          <Box mb={2}>
            <label htmlFor="street">Logradouro *</label>
            <Input
              value={PublicPlace}
              onChange={(e) => {
                setPublicPlace(e.target.value);
              }}
              id="street"
              required
            />
          </Box>
          <Box d="flex" w="100%" mb={2}>
            <Box w={["30%", "30%", "30%", "30%"]}>
              <label htmlFor="number">Numero *</label>
              <Input
                value={StreetNumber}
                onChange={(e) => {
                  setStreetNumber(e.target.value);
                }}
                id="number"
                required
              />
            </Box>
            <Box w="100%" ml={2} mb={2}>
              <label htmlFor="complement">Complemento</label>
              <Input
                value={Complement}
                onChange={(e) => {
                  setComplement(e.target.value);
                }}
                id="complement"
              />
            </Box>
          </Box>
          <Box d="flex" w="100%" mb={2}>
            <Box w={["30%", "30%", "30%", "30%"]}>
              <label htmlFor="DDD">Telefone *</label>
              <Input
                value={DDD}
                onChange={(e) => {
                  setDDD(e.target.value);
                }}
                id="DDD"
                as={InputMask}
                mask="99"
                required
              />
            </Box>
            <Box w="100%" ml={2} mt={6}>
              <Input
                value={Phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                as={InputMask}
                mask="99999-9999"
                required
              />
            </Box>
          </Box>
          <Box mb={2}>
            <label htmlFor="Profission">Profissão *</label>
            <Select
              id="Profission"
              value={Profession}
              onChange={(e) => {
                setProfession(e.target.value);
              }}
              placeholder="Selecione"
              required
            >
              <option value="Auxiliar de Enfermagem do Trabalho">
                Auxiliar de Enfermagem do Trabalho
              </option>
              <option value="Analista de sustentabilidade">
                Analista de sustentabilidade
              </option>
              <option value="Bombeiro(a) Civil">Bombeiro(a) Civil</option>
              <option value="Comerciante">Comerciante</option>
              <option value="Consultor(a) Ambiental">
                Consultor(a) Ambiental
              </option>
              <option value="Coordenador(a) de Meio Ambiente">
                Coordenador(a) de Meio Ambiente
              </option>
              <option value="Coordenador(a) de Sustentabilidade">
                Coordenador(a) de Sustentabilidade
              </option>
              <option value="Enfermeiro(a) do Trabalho">
                Enfermeiro(a) do Trabalho
              </option>
              <option value="Engenheiro(a) de Segurança do Trabalho">
                Engenheiro(a) de Segurança do Trabalho
              </option>
              <option value="Ergonomista">Ergonomista</option>
              <option value="Fisioterapeuta Ocupacional">
                Fisioterapeuta Ocupacional
              </option>
              <option value="Fonoaudiólogo(a)">Fonoaudiólogo(a)</option>
              <option value="Gestor(a) Ambiental">Gestor(a) Ambiental</option>
              <option value="Higienista Ocupacional">
                Higienista Ocupacional
              </option>
              <option value="Médico(a) do Trabalho">
                Médico(a) do Trabalho
              </option>
              <option value="Sanitarista">Sanitarista</option>
              <option value="Técnico(a) de Segurança do Trabalho">
                Técnico(a) de Segurança do Trabalho
              </option>
              <option value="Tecnólogo(a) em Gestão Ambiental">
                Tecnólogo(a) em Gestão Ambiental
              </option>
              <option value="Outro">Outro</option>
            </Select>
          </Box>
          {Profession === "Outro" && (
            <Box mb={2}>
              <label htmlFor="Other-one">Informe sua profissão</label>
              <Input
                onChange={(e) => {
                  setOtherProfission(e.target.value);
                }}
                value={OtherProfission}
                id="Other-one"
                required
              />
            </Box>
          )}

          <Box mb={2}>
            <label htmlFor="register">Registro</label>
            <Input
              onChange={(e) => {
                setRegister(e.target.value);
              }}
              value={Register}
              placeholder="CREA, Registro TST, CRM, OAB..."
              id="register"
              required
            />
          </Box>
          <Box d="flex" flexDir={["column", "row"]} mb={2}>
            <Box w="100%" maxW="50%">
              <label htmlFor="ke-knew">Como nos conheceu? *</label>
              <Select
                onChange={(e) => {
                  setSocialNetwork(e.target.value);
                }}
                id="ke-knew"
                value={SocialNetwork}
                placeholder="Selecione"
                required
              >
                {socialData.map((item) => (
                  <option value={item.name}>{item.name}</option>
                ))}
              </Select>
            </Box>
            {SocialNetwork === "Outro" && (
              <Box w="100%" ml={[0, 2]} mt={[2, 0]}>
                <label htmlFor="Other-two">Informe como nos conheceu</label>
                <Input
                  onChange={(e) => {
                    setOtherSocialNetWork(e.target.value);
                  }}
                  value={OtherSocialNetWork}
                  id="Other-two"
                  required
                />
              </Box>
            )}
          </Box>
          <Box d="flex" w="100%" justifyContent="right" mt={2}>
            <Button colorScheme="blue" type="submit">
              Avançar
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
}
