import { Box, Flex, Stack } from "@chakra-ui/layout";
import { Button, Textarea } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import api from "../../../api";
import Header from "../../components/Header";

// import { Container } from './styles';

const CMSMissionAndValues: React.FC = () => {
  const MySwal = withReactContent(Swal);
  const [mission, setMission] = useState("");
  const [missionID, setMissionID] = useState("");

  const [values, setValues] = useState("");
  const [valuesID, setValuesID] = useState("");

  const [vision, setVision] = useState("");
  const [visionID, setVisionID] = useState("");

  function CloseWindow() {
    MySwal.fire({
      icon: "warning",
      title: "Tem certeza que deseja cancelar?",
      text: "Os dados informados serão perdidos.",
      confirmButtonText: "Continuar",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
    }).then((response) => {
      if (response.isConfirmed) {
        return (window.location.href = "/admin/cms");
      }
    });
  }

  async function getDataOfPage() {
    try {
      const response = await api.get("/pages", { params: { page: "quest" } });
      for (let i = 0; i < response.data.length; i++) {
        const element = response.data[i];
        switch (element.type) {
          case "vision":
            setVision(element.description);
            setVisionID(element.id);
            break;
          case "value":
            setValues(element.description);
            setValuesID(element.id);

            break;
          case "mission":
            setMission(element.description);
            setMissionID(element.id);

            break;
        }
      }
    } catch (err) {}
  }

  useEffect(() => {
    getDataOfPage();
  }, []);

  async function handleSubmit(e: React.FormEvent<HTMLDivElement>) {
    e.preventDefault();
    try {
      const data = [
        { id: missionID, description: mission },
        { id: visionID, description: vision },
        { id: valuesID, description: values },
      ];

      for (let i = 0; i < data.length; i++) {
        await api.put(
          "/pages",
          { description: data[i].description, questID: data[i].id },
          {
            params: { page: "quest" },
            headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
          }
        );
      }
      MySwal.fire({
        icon: "success",
        text: "Dados salvos com sucesso.",
      }).then((response) => {
        if (response.isConfirmed) {
          return (window.location.href = "/admin/cms");
        }
      });
    } catch (err) {}
  }

  return (
    <>
      <Header />
      <Flex justifyContent="center" mt={3}>
        <Flex
          as="form"
          maxW="1200px"
          w="100%"
          justifyContent="center"
          onSubmit={handleSubmit}
        >
          <Stack direction="column" w={["90%", "100%"]} spacing={3}>
            <Box>
              <label>Missão</label>
              <Textarea
                value={mission}
                onChange={(e) => {
                  setMission(e.target.value);
                }}
              />
            </Box>
            <Box>
              <label>Visão</label>
              <Textarea
                value={vision}
                onChange={(e) => {
                  setVision(e.target.value);
                }}
              />
            </Box>
            <Box>
              <label>Valores</label>
              <Textarea
                value={values}
                onChange={(e) => {
                  setValues(e.target.value);
                }}
              />
            </Box>

            <Stack direction={["column", "row"]}>
              <Button w="100%" colorScheme="green" type="submit">
                Salvar
              </Button>
              <Button w="100%" colorScheme="red" onClick={CloseWindow}>
                Cancelar
              </Button>
            </Stack>
          </Stack>
        </Flex>
      </Flex>
    </>
  );
};

export default CMSMissionAndValues;
