import { Box, Flex } from "@chakra-ui/layout";
import { Button, Input } from "@chakra-ui/react";
import React, { useState } from "react";
import Logo from "../../assets/img/conexaonr_rgb_preferencial.png";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// import { Container } from './styles';
import { Heading } from "@chakra-ui/layout";
import api from "../api";
import axios from "axios";

const RecoveryPassword: React.FC = () => {
  const [email, setEmail] = useState<string>("");

  const MySwal = withReactContent(Swal);

  async function handleSubmit(e: any) {
    e.preventDefault();

    if (email.length === 0) {
      return MySwal.fire({
        title: "Campo email está vazio.",
        icon: "warning",
      });
    }
    try {
      const response = await api.post("/recovery/password", {
        email,
      });

      await MySwal.fire({
        title: response.data.message,
        icon: "success",
      });

      return (window.location.href = "/change/password");
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 404) {
          console.log(err.response.data);
          return MySwal.fire({
            title: err.response?.data.message,
            icon: "error",
          });
        }
      } else {
        return MySwal.fire({
          title: "Algo deu errado, tente novamente.",
          icon: "error",
        });
      }
    }
  }
  return (
    <Flex
      as="form"
      w="100vw"
      h="100vh"
      bgColor="#fff"
      flexDir="column"
      onSubmit={handleSubmit}
    >
      <Flex
        w="100vw"
        height="100%"
        color="white"
        flexDir="column"
        alignItems="center"
        justifyContent="center"
      >
        <a href="/">
          <img src={Logo} alt="Conexão nr" width="200px" height="43px" />
        </a>
        <Box
          d="flex"
          mt={4}
          flexDirection="column"
          alignItems="center"
          color="black"
          w={["80%", "80%", "60%", "30%", "25%"]}
          p={8}
          bgColor="gray.100"
        >
          <Box alignItems="left" w="100%" mb={4}>
            <Heading size="md">Redefinir senha</Heading>
            <Heading size="xs">Insira seu email abaixo</Heading>
          </Box>

          <Box alignItems="left" w="100%" mb={2}>
            <label
              style={{
                alignItems: "left",
                fontWeight: "bold",
                fontSize: ".75rem",
              }}
              htmlFor="email"
            >
              E-mail
            </label>
            <Input
              mt={4}
              placeholder="exemplo@conexaonr.com.br"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              type="email"
              required
              bgColor="white"
            />
          </Box>

          <Box w="100%" mb={2} mt={4}>
            <Button w="100%" colorScheme="green" type="submit">
              Redefinir senha
            </Button>
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
};

export default RecoveryPassword;
