import { Box, Flex } from "@chakra-ui/layout";
import { Button, Input } from "@chakra-ui/react";
import React, { useState } from "react";
import Logo from "../../assets/img/conexaonr2_rgb-pq.png";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// import { Container } from './styles';
import { Heading } from "@chakra-ui/layout";
import api from "../api";
import axios from "axios";

const ChangePassword: React.FC = () => {
  const [code, setCode] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const MySwal = withReactContent(Swal);

  async function handleSubmit(e: any) {
    e.preventDefault();

    if (code.length === 0) {
      return MySwal.fire({
        title: "Campo código está vazio.",
        icon: "warning",
      });
    }
    try {
      const response = await api.put("/change/password", {
        code,
        password,
      });

      await MySwal.fire({
        title: response.data.message,
        icon: "success",
      });

      return (window.location.href = "/login");
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 404) {
          console.log(err.response.data);
          return MySwal.fire({
            title: err.response?.data.message,
            icon: "error",
          });
        }
      } else {
        return MySwal.fire({
          title: "Algo deu errado, tente novamente.",
          icon: "error",
        });
      }
    }
  }
  return (
    <Flex
      as="form"
      w="100vw"
      h="100vh"
      bgColor="#07282B"
      flexDir="column"
      onSubmit={handleSubmit}
    >
      <Flex
        w="100vw"
        height="100%"
        color="white"
        flexDir="column"
        alignItems="center"
        justifyContent="center"
      >
        <a href="/">
          <img src={Logo} alt="Conexão nr" width="200px" height="43px" />
        </a>
        <Box
          d="flex"
          mt={8}
          flexDirection="column"
          alignItems="center"
          bgColor="white"
          color="black"
          w={["80%", "80%", "60%", "30%", "25%"]}
          p={8}
        >
          <Box alignItems="left" w="100%" mb={2}>
            <Heading size="md">Redefinir senha</Heading>
            <Heading size="xs">
              Ultilize código envio ao seu email para redefinir sua senha
            </Heading>
          </Box>

          <Box alignItems="left" w="100%" mb={2}>
            <label
              style={{
                alignItems: "left",
                fontWeight: "bold",
                fontSize: ".75rem",
              }}
              htmlFor="email"
            >
              Código
            </label>
            <Input
              mt={4}
              onChange={(e) => setCode(e.target.value)}
              value={code}
              maxLength={20}
              type="text"
              required
            />
          </Box>

          <Box alignItems="left" w="100%" mb={2}>
            <label
              style={{
                alignItems: "left",
                fontWeight: "bold",
                fontSize: ".75rem",
              }}
              htmlFor="email"
            >
              Senha
            </label>
            <Input
              mt={4}
              placeholder="exemplo@conexaonr.com.br"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              type="password"
              required
            />
          </Box>

          <Box w="100%" mb={2} mt={4}>
            <Button w="100%" colorScheme="green" type="submit">
              Redefinir senha
            </Button>
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
};

export default ChangePassword;
