import { Box, HStack } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";
import { useEffect } from "react";
import { useState } from "react";
import api from "../../../../api";

interface Meta {
  total: number;
  per_page: number;
  current_page?: any;
  last_page: number;
  first_page: number;
  first_page_url: string;
  last_page_url: string;
  next_page_url?: any;
  previous_page_url?: any;
}

interface Datum {
  id: number;
  name: string;
  area_id: number;
  status: boolean;
  created_at: Date;
  updated_at: Date;
}

interface Data {
  meta: Meta;
  data: Datum[];
}

interface Page {
  url: string;
  page: number;
  isActive: boolean;
}

interface RootObject {
  data: Data;
  pages: Page[];
}

export default function Pagination({
  pages,
  setAreas,
}: {
  pages: Page[];
  setAreas: any;
}) {
  const [Pages, setPages] = useState<Page[]>([]);
  const [EndPage, setEndPage] = useState("");
  useEffect(() => {
    if (pages.length > 0) {
      let value: Page[] = [...pages];
      value.pop();
      value.shift();
      setPages(value);
      setEndPage(`${pages[pages.length - 1].page}`);
    }
  }, [setPages, pages]);

  async function getAreas(id: string): Promise<string> {
    const response = await api.get<{ name: string }>("/admin/area/show", {
      params: {
        id: id,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    return response.data.name;
  }

  async function requestArea(id: number) {
    try {
      const response = await api.get<RootObject>("/admin/nrs", {
        headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
        params: { page: id },
      });
      var newData: any[] = [];
      for (let i = 0; i < response.data.data.data.length; i++) {
        const element = response.data.data.data[i];
        const area_id = await getAreas(
          response.data.data.data[i].area_id.toString()
        );
        newData.push({
          id: element.id,
          name: element.name,
          area_id: area_id,
          status: element.status,
        });
      }
      setAreas(newData);
    } catch (err) {}
  }

  return (
    <>
      <Box>
        {pages.length === 1 && (
          <HStack spacing={0} mt={2}>
            <Button sx={{ borderRadius: ".375rem .375rem .375rem .375rem" }}>
              1
            </Button>
          </HStack>
        )}
        {pages.length === 2 && (
          <HStack spacing={0} mt={2}>
            <Button
              sx={{ borderRadius: ".375rem .0 .0 .375rem" }}
              onClick={() => {
                requestArea(1);
              }}
            >
              1
            </Button>
            <Button
              sx={{ borderRadius: ".0 .375rem .375rem .0" }}
              onClick={() => {
                requestArea(2);
              }}
            >
              2
            </Button>
          </HStack>
        )}
        {pages.length > 2 && (
          <HStack spacing={0} mt={2}>
            <Button
              sx={{ borderRadius: ".375rem 0px 0px .375rem" }}
              onClick={() => {
                requestArea(1);
              }}
            >
              1
            </Button>
            {Pages.map((item) => (
              <Button
                key={item.page}
                onClick={() => {
                  requestArea(item.page);
                }}
                sx={{ borderRadius: "0px" }}
              >
                {item.page}
              </Button>
            ))}
            <Button
              onClick={() => {
                requestArea(parseInt(EndPage));
              }}
              sx={{ borderRadius: " 0px .375rem .375rem 0px" }}
            >
              {EndPage}
            </Button>
          </HStack>
        )}
      </Box>
    </>
  );
}
