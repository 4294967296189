import { CloseIcon } from "@chakra-ui/icons";
import { Box, Heading, HStack } from "@chakra-ui/layout";
import {
  Button,
  IconButton,
  Input,
  Table,
  Tbody,
  Td,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useState } from "react";
import { useEffect } from "react";
import { BiEdit, BiTrash } from "react-icons/bi";
import api from "../../api";
import Header from "../components/Header";
import { MainResponse } from "../types/me";
import Pagination from "./components/Pagination";
import { Datum, Page, RootObject } from "./types/NrPages";

export default function NRsPageAdmin() {
  const [Nrs, setNrs] = useState<Datum[]>([]);
  const [BackupNrs, setBackupNrs] = useState<Datum[]>([]);
  const [PrimaryBackupNrs, setPrimaryBackupNrs] = useState<Datum[]>([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [pages, setPages] = useState<Page[]>([]);

  async function getAreas(id: string): Promise<string> {
    const response = await api.get<{ name: string }>("/admin/area/show", {
      params: {
        id: id,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    return response.data.name;
  }

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get<MainResponse>("/auth/me", {
          headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
        });
        if (response.data.userData.type !== "admin") {
          window.location.href = "/login";
        }
      } catch (err) {
        window.location.href = "/login";
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get<RootObject>("/admin/nrs", {
          headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
        });
        const newData: Datum[] = [];
        for (let i = 0; i < response.data.data.data.length; i++) {
          const element = response.data.data.data[i];
          element.area_id = await getAreas(element.area_id);
          newData.push(element);
        }

        const data: Datum[] = [];
        for (let i = 1; i <= response.data.pages.length; i++) {
          try {
            const response2 = await api.get("/admin/nrs", {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
              params: { page: i },
            });
            console.log(response2.data.data.data);
            for (let o = 0; o < response2.data.data.data.length; o++) {
              const element = response2.data.data.data[o];
              data.push(element);
            }
          } catch (err) {}
        }
        setNrs(newData);
        setPages(response.data.pages);

        const finalData: Datum[] = [];
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          const response = await getAreas(element.area_id);
          element.area_id = response;
          finalData.push(element);
        }

        setBackupNrs(finalData);
        setPrimaryBackupNrs(newData);
      } catch (err) {}
    })();
  }, []);

  async function deleteArea() {
    try {
      await api.delete("/admin/nrs", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          id: localStorage.getItem("nrID"),
        },
      });

      window.location.reload();
    } catch (err) {}
  }

  async function Searching(name: string) {
    if (name !== "") {
      const data: Datum[] = [];
      for (let i = 0; i < BackupNrs.length; i++) {
        const element = BackupNrs[i];
        if (element.name.toLowerCase().indexOf(name.toLowerCase()) > -1) {
          data.push(element);
        }
      }
      setNrs(data);
    } else {
      // const response = await api.get("/admin/nrs", {
      //   headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
      //   params: { page: 1 },
      // });

      setNrs(PrimaryBackupNrs);
    }
  }

  return (
    <>
      <Header />
      <Box as="main" position="relative">
        <Box m={[1, 4]}>
          <Heading>NRs</Heading>
          <Box mt={8}>
            <Box d="flex" justifyContent="space-between">
              <Box
                w={["60%", "60%", "20%", "20%"]}
                d="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Input
                  w="100%"
                  placeholder="Nome da NR"
                  onChange={(e) => {
                    Searching(e.target.value);
                  }}
                />
              </Box>
              <Button
                colorScheme="green"
                w={["16%", "16%", "8%", "8%"]}
                onClick={() => {
                  window.location.href = "/admin/nrs/add";
                }}
              >
                Novo
              </Button>
            </Box>
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Td>Nome</Td>
                  <Td>Área</Td>
                  <Td>Situação</Td>
                  <Td>Ação</Td>
                </Tr>
              </Thead>
              <Tbody>
                {Nrs.map((item) => (
                  <Tr>
                    <Td>{item.name}</Td>
                    <Td>{item.area_id}</Td>
                    <Td>{item.status ? "Ativo" : "Inativo"}</Td>
                    <Td>
                      <HStack spacing={2}>
                        <IconButton
                          colorScheme="green"
                          aria-label="Edit"
                          onClick={() => {
                            localStorage.setItem("itemId", `${item.id}`);
                            window.location.href = "/admin/nrs/edit";
                          }}
                          icon={<BiEdit />}
                        />
                        <IconButton
                          colorScheme="red"
                          aria-label="Delete"
                          onClick={() => {
                            localStorage.setItem("nrID", `${item.id}`);
                            setConfirmDelete(true);
                          }}
                          icon={<BiTrash />}
                        />
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
          <Box d="flex" justifyContent="right">
            <Pagination pages={pages} setAreas={setNrs} />
          </Box>
        </Box>
        {confirmDelete && (
          <Box
            position="fixed"
            top={0}
            w="100vw"
            height="100%"
            bgColor="rgba(0,0,0,0.3)"
            d="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              bgColor="white"
              p={8}
              rounded="md"
              alignItems="center"
              d="flex"
              flexDirection="column"
            >
              <Box
                sx={{ border: "4px solid #E53E53", borderRadius: "50%" }}
                mb={6}
              >
                <CloseIcon color="red.500" w={16} h={16} m={4} />
              </Box>
              <Heading size="md">
                Tem certeza que deseja excluir o registro?
              </Heading>
              <HStack mt={2}>
                <Button
                  colorScheme="gray"
                  onClick={() => {
                    setConfirmDelete(false);
                  }}
                >
                  Cancelar
                </Button>
                <Button colorScheme="red" onClick={deleteArea}>
                  Excluir
                </Button>
              </HStack>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}
