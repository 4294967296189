import { CloseIcon } from "@chakra-ui/icons";
import { Box, Heading, HStack } from "@chakra-ui/layout";
import {
  Button,
  IconButton,
  Input,
  Select,
  Table,
  Tbody,
  Td,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BiEdit, BiTrash } from "react-icons/bi";
import api from "../../api";
import Header from "../components/Header";

export default function ParceiroAdmin() {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [service, setService] = useState<any[]>([]);
  const [backup, setBackup] = useState<any[]>([]);

  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [cpfCnpj, setCpfCnpj] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get("/admin/users", {
          headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
        });
        setService(response.data);
        setBackup(response.data);
      } catch (err) {}
    })();
  }, []);

  const filter = () => {
    let services: any[] = backup;

    services = services
      .filter((service) => {
        if (name !== "") {
          if (
            service.name
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
              .indexOf(
                name
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toLowerCase()
              ) !== -1
          ) {
            return service;
          }
        } else {
          return service;
        }
      })
      .filter((service) => {
        if (type !== "") {
          if (service.razao_social === type) {
            return service;
          }
        } else {
          return service;
        }
      })
      .filter((service) => {
        if (cpfCnpj !== "") {
          if (service.cpf === cpfCnpj) {
            return service;
          }
        } else {
          return service;
        }
      });

    setService(services);
  };

  return (
    <>
      <Header />
      <Box as="main" position="relative">
        <Box m={[1, 4]}>
          <Heading>Parceiro</Heading>
          <Box mt={8}>
            <Box d={["flex"]} justifyContent="center" alignContent="center">
              <Box
                w={["70%"]}
                d="flex"
                flexDir="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box d="flex" flexDir="column">
                  <Input
                    placeholder="Nome"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </Box>
                <Box d="flex" flexDir="column">
                  <Select
                    placeholder="Tipo de pessoa"
                    value={type}
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                  >
                    <option value="Física">Física</option>
                    <option value="Jurídica">Jurídica</option>
                  </Select>
                </Box>
                <Box d="flex" flexDir="column">
                  <Input
                    placeholder="CPF/CNPJ"
                    value={cpfCnpj}
                    onChange={(e) => {
                      setCpfCnpj(e.target.value);
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box d={["flex"]} justifyContent="center" alignContent="center">
              <Box
                w={["70%"]}
                d="flex"
                flexDir="row"
                justifyContent="space-between"
                alignItems="center"
                mt={2}
              >
                <Button colorScheme="green" onClick={() => filter()}>
                  Filtrar
                </Button>
              </Box>
            </Box>
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Td>Nome</Td>
                  <Td>Tipo de Pessoa</Td>
                  <Td>CPF/CNPJ</Td>
                  <Td>Créditos</Td>
                  <Td>Ação</Td>
                </Tr>
              </Thead>
              <Tbody>
                {service.map((item) => (
                  <>
                    <Tr>
                      <Td>{item.name}</Td>
                      <Td>{item.razao_social}</Td>
                      <Td>{item.cpf}</Td>
                      <Td>{item.credits}</Td>
                      <Td>
                        <HStack spacing={2}>
                          <IconButton
                            colorScheme="green"
                            aria-label="Edit"
                            onClick={() => {
                              // localStorage.setItem("DemandaID", ``);
                              window.location.href =
                                "/admin/Parceiro/edit/" + item.id;
                              localStorage.setItem(
                                "personType",
                                `${item.razao_social}`
                              );
                            }}
                            icon={<BiEdit />}
                          />
                          <IconButton
                            colorScheme="red"
                            aria-label="Delete"
                            onClick={() => {
                              localStorage.setItem("ParceiroId", item.id);
                              setConfirmDelete(true);
                            }}
                            icon={<BiTrash />}
                          />
                        </HStack>
                      </Td>
                    </Tr>
                  </>
                ))}
              </Tbody>
            </Table>
          </Box>
          <Box d="flex" justifyContent="right">
            {/* <Pagination /> */}
          </Box>
        </Box>
        {confirmDelete && (
          <Box
            position="fixed"
            top={0}
            w="100vw"
            height="100%"
            bgColor="rgba(0,0,0,0.3)"
            d="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              bgColor="white"
              p={8}
              rounded="md"
              alignItems="center"
              d="flex"
              flexDirection="column"
            >
              <Box
                sx={{ border: "4px solid #E53E53", borderRadius: "50%" }}
                mb={6}
              >
                <CloseIcon color="red.500" w={16} h={16} m={4} />
              </Box>
              <Heading size="md">
                Tem certeza que deseja excluir o registro?
              </Heading>
              <HStack mt={2}>
                <Button
                  colorScheme="gray"
                  onClick={() => {
                    setConfirmDelete(false);
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  colorScheme="red"
                  onClick={() => {
                    (async () => {
                      try {
                        await api.delete("/admin/Parceiro", {
                          headers: {
                            ParceiroId: `${localStorage.getItem("ParceiroId")}`,
                            Authorization: `Bearer ${localStorage.getItem(
                              "jwt"
                            )}`,
                          },
                        });
                        window.location.reload();
                      } catch (err) {}
                    })();
                  }}
                >
                  Excluir
                </Button>
              </HStack>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}
