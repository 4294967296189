import { IconButton } from "@chakra-ui/button";
import { Flex, Stack, Text } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BiEdit, BiTrash } from "react-icons/bi";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import api from "../../../api";
import Header from "../../components/Header";

// import { Container } from './styles';

const Asks: React.FC = () => {
  const [perguntas, setPerguntas] = useState<any[]>([]);

  const MySwal = withReactContent(Swal);

  async function getDataOfPage() {
    try {
      const response = await api.get("/pages", {
        params: { page: "common_questions" },
      });
      setPerguntas(response.data);
    } catch (err) {}
  }

  async function deleteQuestion(id: string) {
    MySwal.fire({
      icon: "warning",
      text: "Tem certeza que deseja excluir o registro?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Confirmar",
    }).then(async (response) => {
      if (response.isConfirmed) {
        await api.delete("/pages", {
          params: { common_questions_id: id },
          headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
        });
        window.location.reload();
      }
    });
  }

  useEffect(() => {
    getDataOfPage();
  }, []);

  return (
    <>
      <Header />
      <Flex justifyContent="center" alignItems="center" flexDir="column" mt={3}>
        <Flex w={["90%", "50%"]} justifyContent="end" mb={2}>
          <Button
            colorScheme="green"
            onClick={() => {
              window.location.href = "/admin/cms/PerguntasFrequentes/new";
            }}
          >
            Novo
          </Button>
        </Flex>
        <Stack w={["90%", "50%"]}>
          {perguntas.map((item) => (
            <>
              <Flex
                shadow="base"
                p={5}
                rounded="md"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text fontWeight="bold">{item.title}</Text>
                <Stack direction="row" spacing={2}>
                  <IconButton
                    colorScheme="green"
                    aria-label="Edit"
                    onClick={() => {
                      localStorage.setItem("question_id", `${item.id}`);
                      window.location.href =
                        "/admin/cms/PerguntasFrequentes/edit";
                    }}
                    icon={<BiEdit />}
                  />
                  <IconButton
                    colorScheme="red"
                    aria-label="Delete"
                    onClick={() => {
                      deleteQuestion(`${item.id}`);
                    }}
                    icon={<BiTrash />}
                  />
                </Stack>
              </Flex>
            </>
          ))}
        </Stack>
      </Flex>
    </>
  );
};

export default Asks;
