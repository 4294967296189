import { CloseIcon } from "@chakra-ui/icons";
import { Box, Heading, HStack } from "@chakra-ui/layout";
import {
  Button,
  IconButton,
  Input,
  Select,
  Table,
  Tbody,
  Td,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useState } from "react";
import { BiEdit, BiTrash } from "react-icons/bi";
import api from "../../api";
import Header from "../components/Header";

export default function DemandAdmin() {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [servicesData, setServices] = useState<any[]>([]);
  const [BackupServicesData, setBackupServices] = useState<any[]>([]);
  const [dateA, setDateA] = useState("");
  // eslint-disable-next-line
  const [dateB, setDateB] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get("/auth/me", {
          headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
        });

        if (response.data.userData.type !== "admin") {
          window.location.href = "/login";
        }
      } catch (err) {
        window.location.href = "/login";
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get("/demanda", {
          headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
        });
        console.log(response.data.data[0]);
        const data: any[] = [];
        for (let i = 0; i < response.data.data.length; i++) {
          const element = response.data.data[i];
          const date = new Date(element.createdAt);
          element.created_at =
            date.getDate() +
            "/" +
            (date.getMonth() + 1) +
            "/" +
            date.getFullYear();
          data.push(element);
        }
        setServices(data);
        setBackupServices(data);
      } catch (err) {}
    })();
  }, []);

  async function Searching(name: string) {
    if (name !== "") {
      const data: any[] = [];
      for (let i = 0; i < BackupServicesData.length; i++) {
        const element = BackupServicesData[i];
        if (
          element.corporate_name.toLowerCase().indexOf(name.toLowerCase()) > -1
        ) {
          data.push(element);
        }
      }
      setServices(data);
    } else {
      // const response = await api.get("/admin/nrs", {
      //   headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
      //   params: { page: 1 },
      // });
      setServices(BackupServicesData);
    }
  }

  function SearchingDate(minDate: string, maxDate: string) {
    if (!maxDate) {
      const data: any[] = [];
      for (let i = 0; i < BackupServicesData.length; i++) {
        const element = BackupServicesData[i];
        const parseDate = element.created_at.split("/");
        const date = new Date(element.createdAt);

        if (date >= new Date(minDate)) {
          data.push(element);
        }
      }

      setServices(data);
    } else {
      const data: any[] = [];
      for (let i = 0; i < BackupServicesData.length; i++) {
        const element = BackupServicesData[i];
        const parseDate = element.created_at.split("/");
        const date = new Date(parseDate[2], parseDate[1] - 1, parseDate[0]);

        if (date >= new Date(minDate) && date <= new Date(maxDate)) {
          data.push(element);
        }
      }

      setServices(data);
    }
  }

  const selectedStatus = (status: string) => {
    console.log(status);
    if (status === "true") {
      setServices(
        BackupServicesData.filter((element) => element.status === true)
      );
    } else {
      setServices(
        BackupServicesData.filter((element) => element.status === false)
      );
    }
  };

  return (
    <>
      <Header />
      <Box as="main" position="relative">
        <Box m={[1, 4]}>
          <Heading>Demanda</Heading>
          <Box mt={8}>
            <Box d={["flex", "none"]} flexDirection="column">
              <Box
                w={["100%", "20%"]}
                d="flex"
                justifyContent="center"
                alignItems="center"
                mb={2}
              >
                <Input
                  w="100%"
                  placeholder="Nome da Demanda"
                  onChange={(e) => {
                    Searching(e.target.value);
                  }}
                />
              </Box>
              <Box
                w={["100%", "20%"]}
                d="flex"
                justifyContent="center"
                alignItems="center"
                mb={2}
              >
                <Input
                  w="100%"
                  type="date"
                  onChange={(e) => {
                    setDateA(e.target.value);
                  }}
                  mr={2}
                />
                a
                <Input
                  w="100%"
                  type="date"
                  onChange={(e) => {
                    setDateB(e.target.value);
                  }}
                  ml={2}
                />
              </Box>
              <Box
                w={["100%", "10%"]}
                d="flex"
                justifyContent="center"
                alignItems="center"
                mb={2}
              >
                <Select
                  placeholder="Selecione"
                  onChange={(e) => selectedStatus(e.target.value)}
                >
                  <option value="true">Em Aberto</option>
                  <option value="false">Fechado</option>
                </Select>
              </Box>
            </Box>

            <Box d={["none", "flex"]} justifyContent="space-between">
              <Box
                w={["60%", "60%", "20%", "20%"]}
                d="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Input
                  w="100%"
                  placeholder="Nome da Demanda"
                  onChange={(e) => {
                    Searching(e.target.value);
                  }}
                />
              </Box>
              <Box
                w={["60%", "30%"]}
                d="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Input
                  w="100%"
                  type="date"
                  onChange={(e) => {
                    if (e.target.value === "") {
                      setServices(BackupServicesData);
                    } else {
                      setDateA(e.target.value);
                      SearchingDate(e.target.value, "");
                    }
                  }}
                  mr={2}
                />
                a
                <Input
                  w="100%"
                  type="date"
                  onChange={(e) => {
                    setDateB(e.target.value);
                    SearchingDate(dateA, e.target.value);
                  }}
                  ml={2}
                />
              </Box>
              <Box
                w={["60%", "13%"]}
                d="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Select
                  placeholder="Selecione"
                  onChange={(e) => selectedStatus(e.target.value)}
                >
                  <option value="true">Em Aberto</option>
                  <option value="false">Fechado</option>
                </Select>
              </Box>
            </Box>
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Td>Nome da Empresa</Td>
                  <Td>Serviço</Td>
                  <Td>Situação</Td>
                  <Td>Data</Td>
                  <Td>Ação</Td>
                </Tr>
              </Thead>
              <Tbody>
                {servicesData.map((item) => (
                  <>
                    <Tr>
                      <Td>{item.company_name}</Td>
                      <Td>{item.title}</Td>
                      <Td>{item.status ? "Em Aberto" : "Fechado"}</Td>
                      <Td>{item.created_at}</Td>
                      <Td>
                        <HStack spacing={2}>
                          <IconButton
                            colorScheme="green"
                            aria-label="Edit"
                            onClick={() => {
                              localStorage.setItem("DemandaID", `${item.id}`);
                              localStorage.setItem(
                                "companyId",
                                `${item.companyId}`
                              );
                              window.location.href = "/admin/demanda/edit";
                            }}
                            icon={<BiEdit />}
                          />
                          <IconButton
                            colorScheme="red"
                            aria-label="Delete"
                            onClick={() => {
                              localStorage.setItem(
                                "deleteDemanda",
                                `${item.id}`
                              );
                              setConfirmDelete(true);
                            }}
                            icon={<BiTrash />}
                          />
                        </HStack>
                      </Td>
                    </Tr>
                  </>
                ))}
              </Tbody>
            </Table>
          </Box>
          <Box d="flex" justifyContent="right">
            {/* <Pagination /> */}
          </Box>
        </Box>
        {confirmDelete && (
          <Box
            position="fixed"
            top={0}
            w="100vw"
            height="100%"
            bgColor="rgba(0,0,0,0.3)"
            d="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              bgColor="white"
              p={8}
              rounded="md"
              alignItems="center"
              d="flex"
              flexDirection="column"
            >
              <Box
                sx={{ border: "4px solid #E53E53", borderRadius: "50%" }}
                mb={6}
              >
                <CloseIcon color="red.500" w={16} h={16} m={4} />
              </Box>
              <Heading size="md">
                Tem certeza que deseja excluir o registro?
              </Heading>
              <HStack mt={2}>
                <Button
                  colorScheme="gray"
                  onClick={() => {
                    setConfirmDelete(false);
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  colorScheme="red"
                  onClick={() => {
                    (async () => {
                      try {
                        await api.delete("/admin/demanda", {
                          headers: {
                            id: `${localStorage.getItem("deleteDemanda")}`,
                            Authorization: `Bearer ${localStorage.getItem(
                              "jwt"
                            )}`,
                          },
                        });
                        window.location.reload();
                      } catch (err) {}
                    })();
                  }}
                >
                  Excluir
                </Button>
              </HStack>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}
