import { Box, Flex, Stack } from "@chakra-ui/layout";
import { Button, Input, Textarea } from "@chakra-ui/react";
import React, { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import api from "../../../api";
import Header from "../../components/Header";

// import { Container } from './styles';

const AsksNew: React.FC = () => {
  const MySwal = withReactContent(Swal);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  async function handleSubmit(e: React.FormEvent<HTMLDivElement>) {
    e.preventDefault();
    try {
      await api.post(
        "/pages",
        {
          title: title,
          description: description,
        },
        { headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` } }
      );
      MySwal.fire({
        icon: "success",
        text: "Dados salvos com sucesso.",
      }).then((response) => {
        if (response.isConfirmed) {
          return (window.location.href = "/admin/cms/PerguntasFrequentes");
        }
      });
    } catch (err) {}
  }

  function CloseWindow() {
    MySwal.fire({
      icon: "warning",
      title: "Tem certeza que deseja cancelar?",
      text: "Os dados informados serão perdidos.",
      confirmButtonText: "Continuar",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
    }).then((response) => {
      if (response.isConfirmed) {
        return (window.location.href = "/admin/cms/PerguntasFrequentes");
      }
    });
  }

  return (
    <>
      <Header />

      <Flex justifyContent="center" mt={3}>
        <Stack
          as="form"
          w={["90%", "50%"]}
          direction="column"
          spacing={3}
          onSubmit={handleSubmit}
        >
          <Box>
            <label>Titulo</label>
            <Input
              value={title}
              isRequired={true}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </Box>
          <Box>
            <label>Descrição</label>
            <Textarea
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </Box>
          <Stack direction={["column", "row"]}>
            <Button w="100%" colorScheme="green" type="submit">
              Salvar
            </Button>
            <Button w="100%" colorScheme="red" onClick={CloseWindow}>
              Cancelar
            </Button>
          </Stack>
        </Stack>
      </Flex>
    </>
  );
};

export default AsksNew;
