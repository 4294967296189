import { Box, Flex, Stack } from "@chakra-ui/layout";
import { Button, HStack, Image, Link } from "@chakra-ui/react";
import { useState } from "react";
import { Link as Linking } from "react-router-dom";
import Logo from "../../../../assets/img/conexaonr2_rgb-pq.png";
import MenuClose from "./MenuClose.svg";
import MenuIcon from "./MenuIcon.svg";

export default function Header() {
  const [toggle, setToggle] = useState(false);

  return (
    <>
      <Box
        p={2}
        w="100%"
        d={["none", "none", "flex", "flex"]}
        as="header"
        bg="#07282B"
        color="white"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          maxW="1200px"
          w="100%"
          d="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <img
            src={Logo}
            alt="Conexão nr"
            width="200px"
            height="43px"
            onClick={() => (window.location.href = "/")}
            style={{ cursor: "pointer" }}
          />

          <HStack spacing={4}>
            <Link
              as={Linking}
              _hover={{
                textDecoration: "none",
                color: "rgba(255,255,255, .7)",
              }}
              size="sm"
              to="/admin/cms"
            >
              CMS
            </Link>
            <Link
              as={Linking}
              _hover={{
                textDecoration: "none",
                color: "rgba(255,255,255, .7)",
              }}
              size="sm"
              to="/admin"
            >
              Areas
            </Link>
            <Link
              as={Linking}
              _hover={{
                textDecoration: "none",
                color: "rgba(255,255,255, .7)",
              }}
              to="/admin/nrs"
            >
              Nrs
            </Link>
            <Link
              as={Linking}
              _hover={{
                textDecoration: "none",
                color: "rgba(255,255,255, .7)",
              }}
              to="/admin/credits"
            >
              Créditos
            </Link>
            <Link
              as={Linking}
              _hover={{
                textDecoration: "none",
                color: "rgba(255,255,255, .7)",
              }}
              to="/admin/services"
            >
              Serviços
            </Link>
            <Link
              as={Linking}
              _hover={{
                textDecoration: "none",
                color: "rgba(255,255,255, .7)",
              }}
              to="/admin/Parceiro"
            >
              Parceiro
            </Link>
            <Link
              as={Linking}
              _hover={{
                textDecoration: "none",
                color: "rgba(255,255,255, .7)",
              }}
              to="/admin/demanda"
            >
              Demandas
            </Link>
            <Link
              as={Linking}
              _hover={{
                textDecoration: "none",
                color: "rgba(255,255,255, .7)",
              }}
              to="/admin/contatos"
            >
              Contatos
            </Link>
            <Link
              as={Linking}
              _hover={{
                textDecoration: "none",
                color: "rgba(255,255,255, .7)",
              }}
              onClick={() => {
                localStorage.clear();
              }}
              to="/login"
            >
              Sair
            </Link>
          </HStack>
        </Box>
      </Box>
      <Flex
        d={["flex", "flex", "none", "none"]}
        bg="#015478"
        direction="column"
        color="white"
      >
        <Box
          p={2}
          w="100%"
          d={["flex", "flex", "none", "none"]}
          as="header"
          alignItems="center"
          justifyContent="space-between"
        >
          <a href="/">
            <img src={Logo} alt="Conexão nr" width="200px" height="43px" />
          </a>
          <Box>
            <Button
              bg="transparent"
              _focus={{}}
              _active={{}}
              _hover={{}}
              onClick={() => {
                toggle ? setToggle(false) : setToggle(true);
              }}
            >
              <Image src={toggle ? MenuClose : MenuIcon} />
            </Button>
          </Box>
        </Box>
        {toggle && (
          <>
            <Stack direction="column" p={5} spacing={4}>
              <Link
                as={Linking}
                _hover={{
                  textDecoration: "none",
                  color: "rgba(255,255,255, .7)",
                }}
                size="sm"
                to="/admin"
              >
                Areas
              </Link>
              <Link
                as={Linking}
                _hover={{
                  textDecoration: "none",
                  color: "rgba(255,255,255, .7)",
                }}
                to="/admin/nrs"
              >
                Nrs
              </Link>
              <Link
                as={Linking}
                _hover={{
                  textDecoration: "none",
                  color: "rgba(255,255,255, .7)",
                }}
                to="/admin/credits"
              >
                Créditos
              </Link>
              <Link
                as={Linking}
                _hover={{
                  textDecoration: "none",
                  color: "rgba(255,255,255, .7)",
                }}
                to="/admin/services"
              >
                Serviços
              </Link>
              <Link
                as={Linking}
                _hover={{
                  textDecoration: "none",
                  color: "rgba(255,255,255, .7)",
                }}
                to="/admin/Parceiro"
              >
                Parceiro
              </Link>
              <Link
                as={Linking}
                _hover={{
                  textDecoration: "none",
                  color: "rgba(255,255,255, .7)",
                }}
                to="/admin/demanda"
              >
                Demandas
              </Link>
              <Link
                as={Linking}
                _hover={{
                  textDecoration: "none",
                  color: "rgba(255,255,255, .7)",
                }}
                to="/admin/contatos"
              >
                Contatos
              </Link>
              <Link
                as={Linking}
                _hover={{
                  textDecoration: "none",
                  color: "rgba(255,255,255, .7)",
                }}
                onClick={() => {
                  localStorage.clear();
                }}
                to="/login"
              >
                Sair
              </Link>
            </Stack>
          </>
        )}
      </Flex>
    </>
  );
}
