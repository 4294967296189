import { Box, Divider, Grid, HStack, Text } from "@chakra-ui/layout";
import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Checkbox,
  Input,
  Select,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import api from "../../api";
import Loading from "../../components/Loading";
import uf from "../../components/UF";
import { useLoading } from "../Providers/Loading";
import ItemCard from "./Item";
import { iUF } from "./iUF";
import { IAreasOfCompany } from "./IAreasOfCompany";

interface IArea {
  id: number;
  name: string;
  created_at: Date;
  updated_at: Date;
}

interface Meta {
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
  first_page: number;
  first_page_url: string;
  last_page_url: string;
  next_page_url: string;
  previous_page_url?: any;
}

export interface Pages {
  meta: Meta;
  data: IAreasOfCompany[];
}

interface Page {
  atual: number;
  total: number;
}

export default function ParceiroDashboard() {
  const { services, setUFAll, UFAll } = useLoading();
  const [StateAll, setStateAll] = useState<string[]>([]);

  const [items, setItems] = useState<IAreasOfCompany>(services);
  const [_, setMeta] = useState<Meta>();
  const [pages, setPages] = useState<Page>({ atual: 1, total: 1 });

  const [Areas, setAreasOflist] = useState<IArea[]>([]);

  const [query, setQuery] = useState("");
  const [area, setArea] = useState<string[]>([]);
  const [ufSelected, setUfSelected] = useState<string>();
  const [regionSelected, setRegionSelected] = useState<string>();
  const [loading, setLoading] = useState(true);
  const [loadingItems, setLoadingItems] = useState(false);

  const [success, setSuccess] = useState({ stats: false, data: "" });
  const [error, setError] = useState({ stats: false, data: "" });

  async function sendFilter() {
    try {
      setLoadingItems(true);
      if (!ufSelected && !regionSelected && !query && !area) {
        window.location.reload();
      } else if (!ufSelected && !regionSelected && query) {
        const newItems: any[] = items.data.filter((item) => {
          const itemTitle = item.title
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");

          const queryTitle = query
            .toLocaleLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");

          if (itemTitle.indexOf(queryTitle) !== -1) {
            return item;
          }
        });
        items.data = newItems;
        setItems({ ...items });
        setPages({ atual: 0, total: 0 });
        setLoadingItems(false);
      } else {
        const queryData = Buffer.from(query).toString("base64");
        const response = await api.get<IAreasOfCompany>(
          "/companys/service/search",
          {
            params: {
              area: area.toString(),
              uf: ufSelected,
              city: regionSelected,
              query: queryData,
            },
            headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
          }
        );
        if (response.data.data.length === 0) {
          setPages({ atual: 1, total: 1 });
          setItems({ data: [], filterArea: [] });
          setLoadingItems(false);
          return;
        }
        let newData: any[] = [];

        const response2 = await api.get("/subs/multi", {
          params: { id: response.data.data.map((item: any) => item.id) },
        });
        for (let i = 0; i < response.data.data.length; i++) {
          const element = response.data.data[i];

          newData.push({ ...element, subs: response2.data.subs[element.id] });
        }

        items.data = newData;
        setItems({ ...items });
        setPages({ atual: 1, total: 1 });
        setLoadingItems(false);
      }
    } catch (err) {
      setLoadingItems(false);
    }
  }

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get("/companys/service", {
          params: {
            page: 1,
          },
          headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
        });
        let newData: any[] = [];
        const promises: any[] = [];

        setAreasOflist(response.data.filterArea);
        const response2 = await api.get("/subs/multi", {
          params: { id: response.data.data.map((item: any) => item.id) },
        });
        for (let i = 0; i < response.data.data.length; i++) {
          const element = response.data.data[i];

          newData.push({ ...element, subs: response2.data.subs[element.id] });
        }

        console.log(response.data.data.data);
        items.data = newData;
        setItems(items);
        setMeta(response.data.data.meta);
        setPages(response.data.pages);
        setLoading(false);
      } catch (err) {}
    })();
  }, []);

  async function getServicesByPage(page: number) {
    try {
      setLoading(true);
      const response = await api.get("/companys/service", {
        params: {
          page: page,
        },
        headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
      });
      let newData: any[] = [];

      setAreasOflist(response.data.filterArea);
      const response2 = await api.get("/subs/multi", {
        params: { id: response.data.data.map((item: any) => item.id) },
      });
      for (let i = 0; i < response.data.data.length; i++) {
        const element = response.data.data[i];

        makeNewData(response2.data.subs[element.id], element, newData);
      }

      console.log(response.data.data.data);
      items.data = newData;
      setItems(items);
      setMeta(response.data.data.meta);
      setPages(response.data.pages);
      setLoading(false);
    } catch (err) {}
  }

  function ChangeStateOfUF(sigla: string) {
    for (let i = 0; i < UFAll.length; i++) {
      const element = UFAll[i];
      if (element.sigla === sigla) {
        console.log(element);
        setStateAll(element.cidades);
      }
    }
  }

  function reverseArr(input: any) {
    // eslint-disable-next-line
    var ret = new Array();
    for (var i = input.length - 1; i >= 0; i--) {
      ret.push(input[i]);
    }
    return ret;
  }

  if (loading) {
    return (
      <>
        <Loading />
      </>
    );
  }

  const Pagination: React.FC<{ pages: number; atualPage: number }> = ({
    atualPage,
    pages,
  }) => {
    const pagesArray = [];
    for (let i = 0; i < pages; i++) {
      const pageId = i + 1;
      pagesArray.push(
        <Button
          onClick={() => getServicesByPage(pageId)}
          variant={pageId === atualPage ? "solid" : "outline"}
          disabled={pageId === atualPage}
        >
          {pageId}
        </Button>
      );
    }
    return (
      <>
        <ButtonGroup size="sm" isAttached variant="outline">
          {pagesArray}
        </ButtonGroup>
      </>
    );
  };

  return (
    <Box position="relative" as="main" h="100%" minH="100% !important">
      <Grid
        templateColumns={["1fr", "1fr", "1fr 4fr", "1fr 4fr"]}
        h="100%"
        gap={2}
      >
        <Box minH="100px" m={4}>
          <Box p={2} border="1px" borderColor="gray.300" rounded="md">
            <Text fontWeight="bold">Palavras-chaves</Text>
            <Input
              placeholder="Ex: controle, monitoramento..."
              value={query}
              onChange={(e: any) => {
                setQuery(e.target.value);
              }}
            />

            <Divider mt={6} mb={4} />

            <Text fontWeight="bold">Áreas</Text>

            <Box>
              <Text color="#12A0FF">Áreas</Text>
              {Areas.map((item) => (
                <Checkbox
                  key={item.id}
                  value={item.id}
                  onChange={(e: any) => {
                    if (e.target.checked) {
                      setArea([...area, e.target.value]);
                    } else {
                      const array = area;
                      const index = array.indexOf(e.target.value);
                      if (index > -1) {
                        array.splice(index, 1);
                      }
                      setArea(array);
                    }
                  }}
                >
                  {item.name}
                  <br />
                </Checkbox>
              ))}
            </Box>

            <Divider mt={6} mb={4} />

            <Box>
              <Text fontWeight="bold">Região</Text>
              <Text color="#12A0FF">Todas as Regiões</Text>
              <Select
                onChange={(e: any) => {
                  ChangeStateOfUF(e.target.value.split("/")[0]);
                  setUfSelected(e.target.value.split("/")[1]);
                }}
              >
                <option value="" selected disabled>
                  UF
                </option>
                {UFAll.map((item) => (
                  <option value={`${item.sigla}/${item.nome}`} key={item.nome}>
                    {item.sigla}
                  </option>
                ))}
              </Select>
              <Select
                onChange={(e: any) => {
                  setRegionSelected(e.target.value);
                }}
                mt={2}
              >
                <option selected disabled>
                  Cidade
                </option>
                {StateAll.map((item) => (
                  <option value={item} key={item}>
                    {item}
                  </option>
                ))}
              </Select>
            </Box>

            <Divider mt={2} />

            <HStack mt={2}>
              <Button
                bgColor="#12A0FF"
                color="white"
                _hover={{ background: "#43b4ff" }}
                _active={{ background: "#43b4ff" }}
                onClick={sendFilter}
              >
                Filtrar
              </Button>
              <Button
                bgColor="#12A0FF"
                color="white"
                _hover={{ background: "#43b4ff" }}
                _active={{ background: "#43b4ff" }}
                onClick={() => {
                  window.location.reload();
                }}
              >
                Limpar
              </Button>
            </HStack>
          </Box>
        </Box>

        <Box minH="100px" m={4}>
          <Box
            d="flex"
            justifyContent={[
              "center",
              "center",
              "space-between",
              "space-between",
            ]}
            flexDirection={["column", "column", "row", "row"]}
          >
            <Select
              w={["100%", "100%", "25%", "25%"]}
              mb={["2", "2", "4", "4"]}
              defaultValue="0"
              onChange={(e: any) => {
                if (e.target.value === "0") {
                  items.data = reverseArr(items.data);
                  setItems({ ...items });
                } else if (e.target.value === "2") {
                  items.data = reverseArr(items.data);
                  setItems({ ...items });
                }
              }}
            >
              <option value="0" selected>
                Mais recentes
              </option>
              <option value="1">Mais relevantes</option>
              <option value="2">Mais antigos</option>
            </Select>
          </Box>
          {loadingItems && <Loading />}
          {!loadingItems && items.data.length === 0 && (
            <>
              <Text>Nenhum serviço foi encontrado.</Text>
            </>
          )}
          {!loadingItems &&
            items.data.length > 0 &&
            items.data.map((item) => (
              <ItemCard
                item={item}
                setSuccess={setSuccess}
                setError={setError}
                key={item.id}
              />
            ))}
          <Pagination pages={pages.total} atualPage={pages.atual} />
        </Box>
      </Grid>

      {success.stats && (
        <Alert
          status="success"
          position="fixed"
          top={2}
          right={2}
          rounded="md"
          w="auto"
        >
          <AlertIcon />
          {success.data}
        </Alert>
      )}
      {error.stats && (
        <Alert
          status="error"
          position="fixed"
          top={2}
          right={2}
          rounded="md"
          w="auto"
        >
          <AlertIcon />
          {error.data}
        </Alert>
      )}
    </Box>
  );
}

function makeNewData(
  subs: number,
  element: {
    id: number;
    title: string;
    description: string;
    type: string;
    uf: string;
    region: string;
    status: boolean;
    contributors: string;
    urgency: string;
    published_date: Date;
    expires_date: Date;
    price: number;
    subs: number;
    created_at?: Date | undefined;
    updated_at?: Date | undefined;
  },
  newData: any[]
) {
  newData.push({ ...element, subs });
}
