import { CheckIcon, WarningTwoIcon } from "@chakra-ui/icons";
import { Box, Heading, HStack } from "@chakra-ui/layout";
import {
  Alert,
  AlertIcon,
  Button,
  Input,
  NumberInput,
  NumberInputField,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import api from "../../../api";
import Header from "../../components/Header";
// @ts-ignore
import IntlCurrencyInput from "react-intl-currency-input";

export default function AddCredits() {
  const [success, setSuccess] = useState({ status: false, data: "" });
  const [cancel, setCancel] = useState({ status: false, data: "" });
  const [error, setError] = useState({ status: false, data: "" });

  const [name, setName] = useState("");
  const [credits, setCredits] = useState("");
  const [ndemandas, setNDemandas] = useState("");
  const [price, setPrice] = useState("100000.00");

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get("/auth/me", {
          headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
        });
        if (response.data.userData.type !== "admin") {
          window.location.href = "/login";
        }
      } catch (err) {
        window.location.href = "/login";
      }
    })();
  }, []);

  const currencyConfig = {
    locale: "pt-BR",
    formats: {
      number: {
        BRL: {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  };

  const handleChange = (event: any, value: any, maskedValue: any) => {
    event.preventDefault();

    setPrice(value);
    console.log(value); // value without mask (ex: 1234.56)
    console.log(maskedValue); // masked value (ex: R$1234,56)
  };

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e!.preventDefault();
    (async () => {
      setError({ status: false, data: "" });
      if (name.length === 0) {
        setError({ status: true, data: "Prencha o campo nome" });
        return;
      }
      if (credits === "0") {
        setError({
          status: true,
          data: "O numero de creditos não pode ser zero",
        });
        return;
      }
      if (ndemandas === "0") {
        setError({
          status: true,
          data: "O numero de demandas não pode ser zero",
        });
        return;
      }
      if (price === "0.00") {
        setError({
          status: true,
          data: "O valor não pode ser zero",
        });
        return;
      }

      try {
        const response = await api.post(
          "/admin/packages",
          {
            name,
            credits,
            ndemanda: ndemandas,
            price,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );

        if (response.status === 200) {
          setSuccess({ status: true, data: "" });
        }
      } catch (err) {}
    })();
  }

  return (
    <>
      <Header />

      <Box
        d={["flex", "flex", "flex", "flex"]}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        w="100%"
        mt={2}
      >
        <Box w={["80%", "80%", "40%", "40%"]}>
          <Heading>Cadastrar Pacote</Heading>
          <form onSubmit={handleSubmit}>
            <Box mt={8}>
              <label htmlFor="Nome">Nome</label>
              <Input
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
                id="Nome"
                required
              />
            </Box>

            <Box mt={8}>
              <label htmlFor="Credits">Créditos</label>
              <Input
                onChange={(e) => {
                  setCredits(e.target.value);
                }}
                value={credits}
                type="number"
                id="Credits"
                required
              />
            </Box>

            <Box mt={8}>
              <label htmlFor="NDemanda">N° de demandas</label>
              <Input
                onChange={(e) => {
                  setNDemandas(e.target.value);
                }}
                value={ndemandas}
                id="NDemanda"
                required
              />
            </Box>

            <Box mt={8}>
              <label htmlFor="Valor">Valor</label>

              <NumberInput
                onChange={(e) => setPrice(e.toString())}
                defaultValue={0}
                precision={2}
                step={0.2}
              >
                <NumberInputField />
              </NumberInput>

              {/* <InputGroup>
                <InputLeftAddon
                  children="R$"
                  sx={{ borderRadius: ".375rem 0px 0px .375rem" }}
                />
                <NumberInput step={0.01} min={1} precision={2} defaultValue={1}>
                  <NumberInputField
                    sx={{
                      borderRadius: "0px .375rem .375rem 0px",
                    }}
                  />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </InputGroup> */}
            </Box>

            <HStack spacing={["auto", "auto", "auto", "auto"]} mt={18}>
              <Button
                onClick={() => {
                  setCancel({ status: true, data: "" });
                }}
              >
                Cancelar
              </Button>
              <Button colorScheme="green" type="submit">
                Salvar
              </Button>
            </HStack>

            {error.status && (
              <>
                <Alert
                  status="error"
                  w={["auto", "auto", "auto", "auto"]}
                  mt={2}
                >
                  <AlertIcon />
                  {error.data}
                </Alert>
              </>
            )}
          </form>
        </Box>
      </Box>
      {cancel.status && (
        <>
          <Box
            position="fixed"
            top={0}
            background="rgba(0,0,0,0.2)"
            w="100%"
            height="100%"
            d="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              bgColor="white"
              p={[4, 8]}
              m={[2, 0]}
              mt="-28"
              d="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              rounded="md"
            >
              <Box
                sx={{ border: "4px solid #ECC94B", borderRadius: "50%" }}
                mb={6}
              >
                <WarningTwoIcon color="yellow.400" w={16} h={16} m={4} />
              </Box>
              <Heading size="md" textAlign="center">
                Tem certeza que deseja cancelar?
                <br /> Os dados informados serão perdidos.
              </Heading>

              <HStack mt={2}>
                <Button
                  colorScheme="gray"
                  onClick={() => {
                    setCancel({ status: false, data: "" });
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  colorScheme="red"
                  onClick={() => {
                    window.location.href = "/admin/credits";
                  }}
                >
                  Sair
                </Button>
              </HStack>
            </Box>
          </Box>
        </>
      )}
      {success.status && (
        <>
          <Box
            position="fixed"
            top={0}
            w="100vw"
            height="100%"
            bgColor="rgba(0,0,0,0.3)"
            d="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              bgColor="white"
              p={8}
              rounded="md"
              alignItems="center"
              d="flex"
              flexDirection="column"
            >
              <Box
                sx={{ border: "4px solid #198754", borderRadius: "50%" }}
                mb={6}
              >
                <CheckIcon color="green.500" w={16} h={16} m={4} />
              </Box>
              <Heading size="md">Registro salvo com sucesso</Heading>
              <HStack mt={2}>
                <Button
                  colorScheme="green"
                  w="100%"
                  onClick={() => {
                    window.location.href = "/admin/credits";
                  }}
                >
                  OK
                </Button>
              </HStack>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
