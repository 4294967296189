import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Header from "./components/Header";
import Footer from "../components/Footer";
import Parceiro from "./Fornecedor";
import { LoadingContext } from "./Providers/Loading";
import Loading from "../components/Loading";
import api from "../api";
import { useContext } from "react";
import { AxiosResponse } from "axios";
import uf from "../components/UF";
import { iUF } from "./Fornecedor/iUF";

interface UserData {
  id: number;
  name: string;
  surname: string;
  phone: string;
  email: string;
  created_at: Date;
  updated_at: Date;
}

interface IUser {
  userData: UserData;
  credits: number;
}

interface IDetails {
  id: number;
  user_id: number;
  cpf: string;
  name: string;
  surname: string;
  street: string;
  ddd: string;
  phone: string;
  profission: string;
  register: string;
  socialnetwork: string;
  created_at: Date;
  updated_at: Date;
}

interface IArea {
  id: number;
  name: string;
  created_at: Date;
  updated_at: Date;
}

interface IAreasOfCompany {
  id: number;
  title: string;
  description: string;
  type: string;
  uf: string;
  region: string;
  status: boolean;
  contributors: string;
  urgency: string;
  published_date: Date;
  expires_date: Date;
  price: number;
  created_at?: Date;
  updated_at?: Date;
}

interface Meta {
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
  first_page: number;
  first_page_url: string;
  last_page_url: string;
  next_page_url?: any;
  previous_page_url?: any;
}

export interface Pages {
  meta: Meta;
  data: IAreasOfCompany[];
}

export default function Dashboard() {
  let history = useHistory();
  const {
    setCredits,
    setName,
    loading,
    setLoading,
    setRole,
    setAreas,
    setServices,
    setUFAll,
  } = useContext(LoadingContext);

  const fetchUFs = async (data: iUF[], response: AxiosResponse) => {
    const arrayUf = response.data.userData.uf.split(",");
    for (let i = 0; i < arrayUf.length; i++) {
      const element = arrayUf[i];
      uf.forEach((item: iUF, index: number) => {
        if (item.sigla === element || element === "All") {
          if (!data.some((element2) => element2.sigla === item.sigla))
            data.push({
              id: index,
              sigla: item.sigla,
              nome: item.nome,
              cidades: [],
            });
        }
      });
    }
    setUFAll(data);
  };
  const fetchCitys = async (data: iUF[], response: AxiosResponse) => {
    const citys = response.data.userData.city.split(",");
    for (let i = 0; i < citys.length; i++) {
      const city = citys[i];
      data.forEach((item: any, index: number) => {
        if (
          uf[item.id].cidades.filter(
            (element) => element === city || city === "All" || city === "all"
          )
        ) {
          data[index].cidades.push(
            ...uf[item.id].cidades.filter(
              (element) => element === city || city === "All" || city === "all"
            )
          );
        }
      });
    }
    setUFAll(data);
  };

  useEffect(() => {
    (async () => {
      let data: iUF[] = [];
      const response = await api.get<IUser>("/auth/me", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      await fetchUFs(data, response);
      await fetchCitys(data, response);
      console.log(data);
      setCredits(response.data.credits);

      setName(
        `${response.data.userData.name} ${response.data.userData.surname}`
      );
    })();

    (async () => {
      try {
        const response = await api.get<IDetails>("/details/pf", {
          headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
        });
        setRole(response.data.profission);
        setLoading(false);
      } catch (err) {
        try {
          const response = await api.get<IDetails>("/details/pj", {
            headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
          });
          setRole(response.data.profission);
          setLoading(false);
        } catch (err) {
          window.location.href = "/second-state";
        }
      }
    })();
  }, [
    history,
    setAreas,
    setCredits,
    setLoading,
    setName,
    setRole,
    setServices,
  ]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Header />
      <Parceiro />
      <Footer />
    </>
  );
}
