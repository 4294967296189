import { Flex, Grid, Text } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";
import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

const AConexaoNR: React.FC = () => {
  const maxW = ["100%", "90%", "90%", "60%", "72%", "70%"];
  return (
    <>
      <Header />
      <Flex
        mt={8}
        w="100%"
        h="100%"
        flexDir="column"
        alignItems="center"
        textAlign="center"
        justifyContent="center"
      >
        <Text maxW={maxW} size="lg" mt={2} mb={2}>
          <b>Conexão NR</b> é a 1ª plataforma do Brasil especializada na
          contratação de serviços de Saúde e Segurança do Trabalho.
        </Text>

        <Text maxW={maxW}>
          Somos uma organização feita de seres humanos e acreditamos que o
          principal objetivo desta área é, de fato, garantir a saúde e segurança
          dos colaboradores das empresas.
        </Text>

        <Text maxW={maxW}>
          Para isso, conectamos empresas e profissionais de SST, com empresas
          que necessitam de seus serviços. <br />
          Tudo de forma rápida e fácil.
        </Text>

        <Text maxW={maxW} size="lg" fontWeight={"bold"} mt={2} mb={2}>
          Para as empresas que necessitam de serviços de SST
        </Text>

        <Text maxW={maxW}>
          Entendemos o quão lento e trabalhoso é o processo de seleção de bons
          prestadores de serviços: Utiliza-se buscadores, entra-se em contato
          com as empresas, explica-se diversas vezes as necessidades, e aí então
          parte-se para as propostas...
        </Text>
        <Text maxW={maxW}>
          Resolvemos todas essas etapas com apenas um Pedido de Orçamento!
          <br />
        </Text>

        <Text maxW={maxW} size="lg" fontWeight={"bold"} mt={2} mb={2}>
          Para nossos Prestadores
        </Text>

        <Text maxW={maxW}>
          Sabemos o quão difícil e onerosa é a busca por novos clientes, aumento
          da carteira e, consequentemente, de faturamento.
        </Text>

        <Text maxW={maxW}>
          Oportunidades de negócios chegarão a você e poderá se inscrever apenas
          nas que achar interessantes.
        </Text>

        <Text maxW={maxW}>
          Saiba quais são os serviços mais solicitados, esteja em constante
          contato com o mercado e mantenha-se competitivo!
        </Text>

        <Text maxW={maxW} mt={4} mb={2}>
          Desta forma, após anos de experiência na área, somados à vontade de
          transforma-la através da tecnologia e inovação, criamos a{" "}
          <b>Conexão NR</b>.
        </Text>

        <Text maxW={maxW} size="lg" fontWeight={"bold"} mt={4} mb={2}>
          Bem-vindos e bons negócios!
        </Text>

        <Text maxW={maxW} size="lg" fontWeight={"bold"} mt={2} mb={2}>
          Equipe Conexão NR
        </Text>

        <Grid
          templateColumns={["1fr", "1fr", "1fr 1fr"]}
          gap="20"
          mt="15"
          mb="20"
        >
          <Flex
            flexDir="column"
            alignItems="center"
            textAlign="center"
            justifyContent="center"
          >
            <Text fontWeight="bold">Necessita de serviços de SST?</Text>
            <Button
              onClick={() => {
                window.location.href = "/#header";
              }}
              colorScheme="yellow"
              fontSize="lg"
              mt={4}
            >
              Faça agora seu pedido de orçamento
            </Button>
          </Flex>
          <Flex
            flexDir="column"
            alignItems="center"
            textAlign="center"
            justifyContent="center"
          >
            <Text fontWeight="bold">
              É prestador de serviços e quer ser parceiro da Conexão NR?
            </Text>
            <Button
              onClick={() => {
                window.location.href = "/signup/#header";
              }}
              colorScheme="green"
              fontSize="lg"
              mt={4}
            >
              Cadastre-se agora gratuitamente
            </Button>
          </Flex>
        </Grid>
      </Flex>

      <Footer />
    </>
  );
};

export default AConexaoNR;
