import { useContext } from "react";
import { useState } from "react";
import { createContext } from "react";
import { iUF } from "../Fornecedor/iUF";
import { IAreasOfCompany } from "../Fornecedor/IAreasOfCompany";

// function State() {
//   const [name, setName] = useState<string>("");
//   const [credits, setCredits] = useState<number>(0.0);
//   const [role, setRole] = useState<string>("");
//   const [areas, setAreas] = useState<IArea[]>([]);
//   const [loading, setLoading] = useState<boolean>(true);

//   return {
//     name,
//     setName,
//     credits,
//     setCredits,
//     loading,
//     setLoading,
//     role,
//     setRole,
//     areas,
//     setAreas,
//   };
// }

interface IArea {
  id: number;
  name: string;
  created_at: Date;
  updated_at: Date;
}

interface Meta {
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
  first_page: number;
  first_page_url: string;
  last_page_url: string;
  next_page_url?: any;
  previous_page_url?: any;
}

type ReactState<T> = React.Dispatch<React.SetStateAction<T>>;
type ContextType = {
  name: string;
  setName: ReactState<string>;
  credits: number;
  setCredits: ReactState<number>;
  loading: boolean;
  setLoading: ReactState<boolean>;
  role: string;
  setRole: ReactState<string>;
  areas: IArea[];
  setAreas: ReactState<IArea[]>;
  services: IAreasOfCompany;
  setServices: ReactState<IAreasOfCompany>;
  meta: Meta | undefined;
  setMeta: ReactState<Meta | undefined>;
  UFAll: iUF[];
  setUFAll: ReactState<iUF[]>;
};

export const LoadingContext = createContext<ContextType>({} as ContextType);

export default function LoadingProvider({
  children,
}: {
  children: JSX.Element;
}) {
  const [name, setName] = useState<string>("");
  const [credits, setCredits] = useState<number>(0.0);
  const [role, setRole] = useState<string>("");
  const [areas, setAreas] = useState<IArea[]>([]);
  const [meta, setMeta] = useState<Meta>();
  const [services, setServices] = useState<IAreasOfCompany>({
    data: [],
    filterArea: [],
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [UFAll, setUFAll] = useState<iUF[]>([]);

  const data: ContextType = {
    name,
    setName,
    credits,
    setCredits,
    loading,
    setLoading,
    role,
    setRole,
    areas,
    setAreas,
    services,
    setServices,
    meta,
    setMeta,
    UFAll,
    setUFAll,
  };

  return (
    <LoadingContext.Provider value={data}>{children}</LoadingContext.Provider>
  );
}

export function useLoading() {
  const context = useContext(LoadingContext);
  const {
    name,
    setName,
    credits,
    setCredits,
    loading,
    setLoading,
    role,
    setRole,
    areas,
    setAreas,
    services,
    setServices,
    meta,
    setMeta,
    UFAll,
    setUFAll,
  } = context;
  return {
    name,
    setName,
    credits,
    setCredits,
    loading,
    setLoading,
    role,
    setRole,
    areas,
    setAreas,
    services,
    setServices,
    meta,
    setMeta,
    UFAll,
    setUFAll,
  };
}
