import { Box, Heading, Text } from "@chakra-ui/layout";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ReactMarkdown from "react-markdown";
import { useState } from "react";
import { useEffect } from "react";
import api from "../api";

export default function Blog() {
  const [itens, setItens] = useState<any[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get("/blog/all");
        setItens(response.data.data.data);
      } catch (err) {}
    })();
  }, []);

  return (
    <>
      <Header />
      <Box d="flex" w="100%" alignItems="center" flexDirection="column">
        {itens.length === 0 && (
          <>
            <Box
              d="flex"
              justifyContent="center"
              alignItems="center"
              w="100vw"
              h="100vh"
            >
              <Text>Nenhum post disponivel</Text>
            </Box>
          </>
        )}
        {itens.map((item) => (
          <>
            {item.type === "Blog" && (
              <Box
                m={4}
                p={2}
                w="100%"
                background="gray.200"
                maxW={["90%", "40%"]}
                rounded="md"
                borderColor="gray.400"
                boxShadow="base"
              >
                <Heading
                  size="md"
                  color="blue.400"
                  sx={{ cursor: "pointer" }}
                  onClick={() => {}}
                >
                  {item.titulo}
                </Heading>
                <ReactMarkdown children={item.code} />
              </Box>
            )}
          </>
        ))}
      </Box>
      <Footer />
    </>
  );
}
