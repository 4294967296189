import api from "../../api";
import { MainResponse } from "./types/me";

export default async function Verify(token: string) {
  try {
    const response = await api.get<MainResponse>("/auth/me", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status !== 200) {
      return false;
    }

    if (response.data.userData.type !== "admin") {
      return (window.location.href = "/dashboard");
    } else {
      return (window.location.href = "/admin");
    }
  } catch (err) {
    return false;
  }
}
