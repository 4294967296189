import { CheckIcon, WarningTwoIcon } from "@chakra-ui/icons";
import { Box, Heading, HStack } from "@chakra-ui/layout";
import { Alert, AlertIcon, Button, Input, Select } from "@chakra-ui/react";
import { useEffect } from "react";
import { useState } from "react";
import api from "../../../../../api";

export default function Main() {
  const [name, setName] = useState("");
  const [surname, setSurName] = useState("");
  const [corporate_name, setCorporateName] = useState("");
  const [corporate_type, setCorporateType] = useState("");
  const [Email, setEmail] = useState("");
  const [Bairro, setBairro] = useState("");
  const [Telefone, setTelefone] = useState("");
  const [About, setAbout] = useState("");
  const [Status, setStatus] = useState("");

  const [MessageCancel, setMessageCancel] = useState(false);

  const [error, setError] = useState({ status: false, data: "" });
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get("/demanda/show", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            id: `${localStorage.getItem("DemandaID")}`,
          },
        });
        setName(response.data.user_name);
        setSurName(response.data.user_surname);
        setCorporateName(response.data.corporate_name);
        setCorporateType(response.data.company_type);
        setEmail(response.data.email);
        setBairro(response.data.street);
        setTelefone(response.data.phone);
        setAbout(response.data.about);
      } catch (err) { }
    })();
  }, []);

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    try {
      e.preventDefault();
      console.log(Status);
      let status;
      if (Status === 'true') {
        status = true
      } else {
        status = false
      }
      setError({ status: false, data: "" });
      await api.put(
        "/admin/demanda",
        {
          name,
          surname,
          corporate_name,
          corporate_type,
          email: Email,
          telefone: Telefone,
          about: About,
          companyId: `${localStorage.getItem('companyId')}`,
          status,
          id: `${localStorage.getItem("DemandaID")}`,
        },
        { headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` } }
      );
      setSuccess(true);
    } catch (err) { }
  }

  return (
    <Box
      d={["flex", "flex", "flex", "flex"]}
      flexDirection="column"
      // justifyContent="center"
      alignItems="center"
      w="100%"
      maxH="100vh"
    >
      <Box w={["80%", "80%", "40%", "40%"]}>
        <Heading>Editar Demanda</Heading>
        <form onSubmit={handleSubmit}>
          <Box mt={8}>
            <label htmlFor="Nome">Nome</label>
            <Input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              id="Nome"
              required
            />
          </Box>

          <Box mt={8}>
            <label htmlFor="Sobrenome">Sobrenome</label>
            <Input
              value={surname}
              onChange={(e) => {
                setSurName(e.target.value);
              }}
              id="Surname"
              required
            />
          </Box>

          <Box mt={8} d="flex">
            <Box mr={2}>
              <label htmlFor="CorporateName">Nome da Empresa</label>
              <Input
                value={corporate_name}
                onChange={(e) => {
                  setCorporateName(e.target.value);
                }}
                id="CorporateName"
                required
              />
            </Box>
            <Box>
              <label htmlFor="CoporateType">Tipo de Empresa</label>

              <Input
                value={corporate_type}
                onChange={(e) => {
                  setCorporateType(e.target.value);
                }}
                id="CoporateType"
                required
              />
            </Box>
          </Box>

          <Box mt={8}>
            <label htmlFor="Email">E-mail</label>
            <Input
              value={Email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              id="Email"
              required
            />
          </Box>

          <Box mt={8} d="flex">
            <Box mr={2}>
              <label htmlFor="Street">Bairro</label>
              <Input
                value={Bairro}
                onChange={(e) => {
                  setBairro(e.target.value);
                }}
                id="Street"
                required
              />
            </Box>
            <Box>
              <label htmlFor="Phone">Telefone</label>
              <Input
                value={Telefone}
                onChange={(e) => {
                  setTelefone(e.target.value);
                }}
                id="Phone"
                required
              />
            </Box>
          </Box>
          <Box mt={8}>
            <label htmlFor="About">Observação Adicional</label>
            <Input
              value={About}
              onChange={(e) => {
                setAbout(e.target.value);
              }}
              id="About"
            />
          </Box>

          <Box mt={8}>
            <label htmlFor="Status">Situação</label>
            <Select
              value={Status}
              onChange={(e) => {

                setStatus(e.target.value);
              }}
              id="About"
              placeholder="Selecione"
              required
            >
              <option value="true">Em Aberto</option>
              <option value="false">Fechado</option>
            </Select>
          </Box>

          {error.status && (
            <Alert status="error" mt={2} rounded="md">
              <AlertIcon />
              {error.data}
            </Alert>
          )}
          <HStack spacing={["auto", "auto", "auto", "auto"]} mt={2}>
            <Button
              onClick={() => {
                // window.location.href = "/admin/nrs";
                setMessageCancel(true);
              }}
            >
              Cancelar
            </Button>
            <Button colorScheme="green" type="submit">
              Salvar
            </Button>
          </HStack>
        </form>
      </Box>

      {MessageCancel && (
        <Box
          position="fixed"
          background="rgba(0,0,0,0.2)"
          w="100%"
          height="100%"
          d="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            bgColor="white"
            p={[4, 8]}
            m={[2, 0]}
            mt="-28"
            d="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            rounded="md"
          >
            <Box
              sx={{ border: "4px solid #ECC94B", borderRadius: "50%" }}
              mb={6}
            >
              <WarningTwoIcon color="yellow.400" w={16} h={16} m={4} />
            </Box>
            <Heading size="md" textAlign="center">
              Tem certeza que deseja cancelar?
              <br /> Os dados informados serão perdidos.
            </Heading>

            <HStack mt={2}>
              <Button
                colorScheme="gray"
                onClick={() => {
                  setMessageCancel(false);
                }}
              >
                Cancelar
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  window.location.href = "/admin/demanda";
                }}
              >
                Sair
              </Button>
            </HStack>
          </Box>
        </Box>
      )}
      {success && (
        <>
          <Box
            position="fixed"
            top={0}
            w="100vw"
            height="100%"
            bgColor="rgba(0,0,0,0.3)"
            d="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              bgColor="white"
              p={8}
              rounded="md"
              alignItems="center"
              d="flex"
              flexDirection="column"
            >
              <Box
                sx={{ border: "4px solid #198754", borderRadius: "50%" }}
                mb={6}
              >
                <CheckIcon color="green.500" w={16} h={16} m={4} />
              </Box>
              <Heading size="md">Registro salvo com sucesso</Heading>
              <HStack mt={2}>
                <Button
                  colorScheme="green"
                  w="100%"
                  onClick={() => {
                    window.location.href = "/admin/demanda";
                  }}
                >
                  OK
                </Button>
              </HStack>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}
