import { Box, Flex, Heading, Text } from "@chakra-ui/layout";
import {
  Button,
  Input,
  Select,
  Spinner,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import api from "../api";
import Footer from "../components/Footer";
import Header from "../components/Header";
import UF from "../components/UF";

export function FaleConosco() {
  const toast = useToast();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");
  const [uf, setUF] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");

  const [ufs, setUfs] = useState<any[]>([]);
  const [citys, setCitys] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const ufs: any[] = UF.map((item) =>
      Object({ name: item.nome, uf: item.sigla })
    );

    setUfs(ufs);
  }, []);

  function setCityByUf(name: string) {
    const uf = UF.filter((item) => item.nome === name)[0];
    setCitys(uf.cidades);
    setUF(name);
  }

  async function handle(
    e: React.FormEvent<HTMLFormElement> | React.FormEvent<HTMLDivElement>
  ) {
    e.preventDefault();
    setLoading(true);

    api
      .post("/contato", {
        firstName,
        lastName,
        email,
        subject,
        text,
        phone,
        uf,
        city,
      })
      .then(() => {
        toast({
          title: "Recebemos seu contato!",
          status: "success",
          position: "top-right",
          duration: 2000,
        });

        setTimeout(() => {
          window.location.href = "/";
        }, 2500);
      })
      .catch(async () => {
        toast({
          title: "Tente novamente!",
          status: "error",
          position: "top-right",
          duration: 2000,
        });

        setTimeout(() => {
          window.location.reload();
        }, 2500);
      });
  }

  return (
    <>
      <Header />

      <Flex justifyContent="center" my={4} flexDir="column" alignItems="center">
        <Heading>Fale conosco</Heading>
        <Text
          textAlign="center"
          maxW={["80%", "80%", "60%", "30%"]}
          mt={2}
          mb={2}
        >
          Sugestões? Dúvidas? Críticas ou elogios?
          <br /> Preencha o formulário abaixo e retornaremos o mais breve
          possível.
        </Text>

        <Box maxW={["80%", "80%", "60%", "30%"]} as="form" onSubmit={handle}>
          <Flex my={2}>
            <Box mr={2}>
              <label>Primeiro nome</label>
              <Input
                value={firstName}
                type="text"
                onChange={(e: any) => setFirstName(e.target.value)}
                required={true}
              />
            </Box>
            <Box>
              <label>Último nome</label>
              <Input
                value={lastName}
                type="text"
                onChange={(e: any) => setLastName(e.target.value)}
                required={true}
              />
            </Box>
          </Flex>

          <Box mt={2} mb={2}>
            <label>E-mail</label>
            <Input
              value={email}
              type="email"
              onChange={(e: any) => setEmail(e.target.value)}
              required={true}
            />
          </Box>

          <Box mt={2} mb={2}>
            <label>Telefone</label>
            <Input
              value={phone}
              type="tel"
              onChange={(e: any) => setPhone(e.target.value)}
              required={true}
            />
          </Box>

          <Box mt={2} mb={2}>
            <label>Estado</label>
            <Select
              placeholder="Selecione"
              type="uf"
              onChange={(e: any) => setCityByUf(e.target.value)}
              required={true}
            >
              {ufs.map((item) => (
                <>
                  <option value={item.name}>{item.name}</option>
                </>
              ))}
            </Select>
          </Box>

          <Box mt={2} mb={2}>
            <label>Cidade</label>
            <Select
              type="city"
              onChange={(e: any) => setCity(e.target.value)}
              required={true}
              placeholder="Selecione"
            >
              {citys.map((item) => (
                <>
                  <option value={item}>{item}</option>
                </>
              ))}
            </Select>
          </Box>

          <Box mt={2} mb={2}>
            <label>Assunto</label>
            <Input
              value={subject}
              type="text"
              onChange={(e: any) => setSubject(e.target.value)}
              required={true}
            />
          </Box>

          <Box>
            <label>Mensagem</label>
            <Textarea
              value={text}
              onChange={(e: any) => setText(e.target.value)}
              required={true}
            />
          </Box>

          <Button
            w="100%"
            colorScheme="green"
            mt={2}
            type="submit"
            disabled={loading}
          >
            Enviar
            {loading ? <Spinner ml={2} /> : <></>}
          </Button>
        </Box>
      </Flex>

      <Footer />
    </>
  );
}
