import { Box, Text } from "@chakra-ui/layout";
import { Alert, AlertIcon, Button, Input, Select } from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import api from "../../api/index";

interface SocialItem {
  id: number;
  name: string;
  created_at: Date;
  updated_at: Date;
}
interface Props {
  userName: string;
  userSurname: string;
}

export default function Juridico({ userName, userSurname }: Props) {
  const [fantasyname, setFantasyName] = useState("");
  const [ComportateName, setComportateName] = useState("");
  const [CNPJ, setCNPJ] = useState("");
  const [CEP, setCEP] = useState("");
  const [Street, setStreet] = useState("");
  const [StreetNumber, setStreetNumber] = useState("");
  const [StreetComplement, setStreetComplement] = useState("");
  const [DDD, setDDD] = useState(localStorage.getItem("ddd") || "");
  const [Phone, setPhone] = useState(
    localStorage.getItem("phone-number") || ""
  );
  const [Department, setDepartment] = useState("");
  const [OtherDepartment, setOtherDepartment] = useState("");
  const [Role, setRole] = useState("");
  const [OtherRole, setOtherRole] = useState("");
  const [Register, setRegister] = useState("");
  const [SocialNetWork, setSocialNetWork] = useState("");
  const [Name, setName] = useState(userName);
  const [SurName, setSurName] = useState(userSurname);
  const [OtherSocialNetWork, setOtherSocialNetWork] = useState("");
  const [SocialNetworkDefault, setSocialNetworkDefault] = useState<
    SocialItem[]
  >([]);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const data = (await api.get("/social")).data;
        setSocialNetworkDefault(data);
      } catch (err) {}
    })();
  }, []);

  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const findCep = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setCEP(e.target.value);
    const cep = e.target.value
      .replace(".", "")
      .replace("-", "")
      .replace(new RegExp("_", "g"), "");
    if (cep.length === 8) {
      try {
        const response = await axios.get(
          `https://brasilapi.com.br/api/cep/v1/${cep}`
        );
        setStreet(response.data.street);
      } catch (err) {
        console.log(err);
      }
    }
  };

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (
      Department !== "Outro" &&
      Role !== "Outro" &&
      SocialNetWork !== "Outro"
    ) {
      try {
        await api.post(
          "/details/pj",
          {
            fantasyname,
            corporatename: ComportateName,
            cnpj: CNPJ,
            cep: CEP,
            street: `${CEP} - ${Street} - ${StreetNumber.replace(
              new RegExp("-", "g"),
              ""
            )} - ${StreetComplement}`,
            ddd: DDD,
            phone: Phone,
            name: Name,
            surname: SurName,
            department: Department,
            role: Role,
            register: Register,
            socialnetwork: SocialNetWork,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );

        setSuccess(true);
        await delay(1500);
        window.location.href = "/third-state";
      } catch (err) {
        setError(true);
      }
    } else {
      if (Department === "Outro") {
        setDepartment(OtherDepartment);
      }

      if (Role === "Outro") {
        setRole(OtherRole);
      }

      if (SocialNetWork === "Outro") {
        setSocialNetWork(OtherSocialNetWork);
      }

      try {
        await api.post(
          "/details/pj",
          {
            fantasyname,
            corporatename: ComportateName,
            cnpj: CNPJ,
            cep: CEP,
            street: `${CEP} - ${Street} - ${StreetNumber.replace(
              new RegExp("-", "g"),
              ""
            )} - ${StreetComplement}`,
            ddd: DDD,
            phone: Phone,
            name: Name,
            surname: SurName,
            department: Department,
            role: Role,
            register: Register,
            socialnetwork: SocialNetWork,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );

        setSuccess(true);
        await delay(1500);
        window.location.href = "/third-state";
      } catch (err) {
        setError(true);
      }
    }
  }

  return (
    <Box w="100%">
      <Text>Dados Adicionais</Text>
      <form onSubmit={handleSubmit}>
        <Box m={2}>
          {error && (
            <Alert status="error">
              <AlertIcon />
              Houve um erro, tente novamente!
            </Alert>
          )}
          {success && (
            <Alert status="success">
              <AlertIcon />
              Detalhes adicionados com sucesso!
            </Alert>
          )}

          <Box mb={2}>
            <label htmlFor="fantasy-name">Nome Fantasia *</label>
            <Input
              id="fantasy-name"
              value={fantasyname}
              onChange={(e) => {
                setFantasyName(e.target.value);
              }}
              required
            />
          </Box>

          <Box w="100%" mb={2}>
            <label htmlFor="corporate-name">Razão Social *</label>
            <Input
              id="corporate-name"
              value={ComportateName}
              onChange={(e) => {
                setComportateName(e.target.value);
              }}
              required
            />
          </Box>

          <Box w={["100%", "50%"]} mb={2}>
            <label htmlFor="cnpj">CNPJ *</label>
            <Input
              id="cnpj"
              value={CNPJ}
              as={InputMask}
              mask="99.999.999/9999/99"
              onChange={(e) => {
                setCNPJ(e.target.value);
              }}
              required
            />
          </Box>

          <Box w={["100%", "50%"]} mb={2}>
            <label htmlFor="cep">CEP *</label>
            <Input
              id="cep"
              as={InputMask}
              mask="99.999-999"
              value={CEP}
              onChange={findCep}
              required
            />
          </Box>

          <Box w="100%" mb={2}>
            <label htmlFor="street">Logradouro *</label>
            <Input
              id="street"
              value={Street}
              onChange={(e) => {
                setStreet(e.target.value);
              }}
              required
            />
          </Box>

          <Box w="100%" d="flex" mb={2}>
            <Box w={["30%", "20%"]} mr={2}>
              <label htmlFor="streetnumber">Numero *</label>
              <Input
                id="streetnumber"
                value={StreetNumber}
                onChange={(e) => {
                  setStreetNumber(e.target.value);
                }}
                required
              />
            </Box>

            <Box w="100%" mb={2}>
              <label htmlFor="streetcomplement">Complemento</label>
              <Input
                id="streetcomplement"
                value={StreetComplement}
                onChange={(e) => {
                  setStreetComplement(e.target.value);
                }}
              />
            </Box>
          </Box>
          <Box w={["100%", "50%"]} d="flex" mb={2}>
            <Box w={["30%", "30%", "30%", "70%"]} mr={2}>
              <label htmlFor="ddd">Telefone *</label>
              <Input
                maxW="100%"
                id="ddd"
                value={DDD}
                onChange={(e) => {
                  setDDD(e.target.value);
                }}
                as={InputMask}
                mask="99"
                required
              />
            </Box>

            <Box w="100%">
              <Input
                mt={6}
                value={Phone}
                as={InputMask}
                mask="99999-9999"
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                required
              />
            </Box>
          </Box>
          <Box w="100%" mb={2}>
            <label htmlFor="name">Nome *</label>
            <Input
              id="name"
              value={Name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              required
            />
          </Box>
          <Box w="100%" mb={2}>
            <label htmlFor="surname">Sobrenome *</label>
            <Input
              id="surname"
              value={SurName}
              onChange={(e) => {
                setSurName(e.target.value);
              }}
              required
            />
          </Box>
          <Box w="100%" mb={2}>
            <label htmlFor="department">Departamento *</label>

            <Select
              id="department"
              onChange={(e) => {
                setDepartment(e.target.value);
              }}
              required
            >
              <option disabled selected>
                Selecione
              </option>
              <option value="Proprietário(a) / CEO">
                Proprietário(a) / CEO
              </option>
              <option value="Administrativo">Administrativo</option>
              <option value="Atendimento">Atendimento</option>
              <option value="Comercial">Comercial</option>
              <option value="Compras">Compras</option>
              <option value="Diretoria">Diretoria</option>
              <option value="Facilities">Facilities</option>
              <option value="Financeiro">Financeiro</option>
              <option value="Jurídico">Jurídico</option>
              <option value="Logística">Logística</option>
              <option value="Manutenção">Manutenção</option>
              <option value="Marketing">Marketing</option>
              <option value="Produção">Produção</option>
              <option value="Operacional">Operacional</option>
              <option value="RH - Recursos Humanos">
                RH - Recursos Humanos
              </option>
              <option value="Segurança e/ou Medicina do Trabalho / SESMT">
                Segurança e/ou Medicina do Trabalho / SESMT
              </option>
              <option value="TI – Tecnologia da Informação">
                TI – Tecnologia da Informação
              </option>
              <option value="Outro">Outro</option>
            </Select>
          </Box>
          {Department === "Outro" && (
            <Box w="100%" mb={[2, 0]}>
              <label htmlFor="Department">Informe seu departamento</label>
              <Input
                id="Department"
                value={OtherDepartment}
                onChange={(e) => {
                  setOtherDepartment(e.target.value);
                }}
                required
              />
            </Box>
          )}
          {Department === "Segurança e/ou Medicina do Trabalho / SESMT" && (
            <>
              <Box w="100%" mb={2}>
                <label htmlFor="Role">Cargo *</label>

                <Select
                  id="Role"
                  onChange={(e) => {
                    setRole(e.target.value);
                  }}
                  required
                >
                  <option disabled selected>
                    Selecione
                  </option>
                  <option value="Auxiliar de Enfermagem do Trabalho">
                    Auxiliar de Enfermagem do Trabalho
                  </option>
                  <option value="Enfermeiro(a) do Trabalho">
                    Enfermeiro(a) do Trabalho
                  </option>
                  <option value="Engenheiro(a) de Segurança do Trabalho">
                    Engenheiro(a) de Segurança do Trabalho
                  </option>
                  <option value="Ergonomista">Ergonomista</option>
                  <option value="Fisioterapeuta Ocupacional">
                    Fisioterapeuta Ocupacional
                  </option>
                  <option value="Fonoaudiólogo(a)">Fonoaudiólogo(a)</option>
                  <option value="Higienista Ocupacional">
                    Higienista Ocupacional
                  </option>
                  <option value="Médico(a) do Trabalho">
                    Médico(a) do Trabalho
                  </option>
                  <option value="Técnico(a) de Segurança do Trabalho">
                    Técnico(a) de Segurança do Trabalho
                  </option>
                  <option value="Outro">Outro</option>
                </Select>
              </Box>
            </>
          )}
          {Department !== "Segurança e/ou Medicina do Trabalho / SESMT" &&
            Department !== "Proprietário(a) / CEO" && (
              <Box w="100%" mb={2}>
                <label htmlFor="Role">Cargo *</label>

                <Select
                  id="Role"
                  onChange={(e) => {
                    setRole(e.target.value);
                  }}
                  required
                >
                  <option disabled selected>
                    Selecione
                  </option>
                  <option value="Analista">Analista</option>
                  <option value="Auxiliar">Auxiliar</option>
                  <option value="Consultor(a)">Consultor(a)</option>
                  <option value="Coordenador(a)">Coordenador(a)</option>
                  <option value="Diretor(a)">Diretor(a)</option>
                  <option value="Especialista">Especialista</option>
                  <option value="Estagiário(a)">Estagiário(a)</option>
                  <option value="Executivo(a)">Executivo(a)</option>
                  <option value="Gerente">Gerente</option>
                  <option value="Gestor(a)">Gestor(a)</option>
                  <option value="Operador">Operador</option>
                  <option value="Presidente">Presidente</option>
                  <option value="Secretário(a)">Secretário(a)</option>
                  <option value="Supervisor(a)">Supervisor(a)</option>
                  <option value="Suporte">Suporte</option>
                  <option value="Técnico(a)">Técnico(a)</option>
                  <option value="Trainee">Trainee</option>
                  <option value="Outro">Outro</option>
                </Select>
              </Box>
            )}
          {Role === "Outro" && Department !== "Proprietário(a) / CEO" && (
            <Box w="100%">
              <label htmlFor="OtherRole">Informe seu cargo</label>
              <Input
                id="OtherRole"
                mb={2}
                value={OtherRole}
                onChange={(e) => {
                  setOtherRole(e.target.value);
                }}
                required
              />
            </Box>
          )}
          {Department === "Segurança e/ou Medicina do Trabalho / SESMT" && (
            <>
              <Box w="100%" mb={2}>
                <label htmlFor="Register">Registro</label>
                <Input
                  id="Register (campo não obrigatório para cadastro de Pessoa Jurídica)"
                  value={Register}
                  placeholder="CREA, Registro TST, CRM, OAB..."
                  onChange={(e) => {
                    setRegister(e.target.value);
                  }}
                  required
                />
              </Box>
            </>
          )}
          <Box d={["block", "flex"]} w="100%" mb={2}>
            <Box w={["100%", "50%"]}>
              <label htmlFor="Register">Como nos conheceu? *</label>
              <Select
                id="Role"
                onChange={(e) => {
                  setSocialNetWork(e.target.value);
                }}
                required
              >
                <option selected disabled>
                  Selecione
                </option>
                {SocialNetworkDefault.map((item) => (
                  <option value={item.name}>{item.name}</option>
                ))}
              </Select>
            </Box>
            {SocialNetWork === "Outro" && (
              <Box w={["100%", "50%"]} ml={[0, 2]} mt={[2, 0]}>
                <label htmlFor="Register">Informe como nos conheceu</label>
                <Input
                  mb={2}
                  id="Register"
                  value={OtherSocialNetWork}
                  onChange={(e) => {
                    setOtherSocialNetWork(e.target.value);
                  }}
                  required
                />
              </Box>
            )}
          </Box>
          <Box d="flex" w="100%" justifyContent="right" mt={2}>
            <Button colorScheme="blue" type="submit">
              Avançar
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
}
