import { Flex, Heading, Stack, Text } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import CardDePerguntas from "../components/CardDePerguntas";
import api from "../api";
import Footer from "../components/Footer";

const PerguntasFrequentes: React.FC = () => {
  const [questions, setQuestions] = useState<any[]>([]);

  async function getDataOfPage() {
    try {
      const response = await api.get("/pages", {
        params: { page: "common_questions" },
      });
      setQuestions(response.data.sort((a: any, b: any) => a.id - b.id));
    } catch (err) {}
  }

  useEffect(() => {
    getDataOfPage();
  }, []);

  return (
    <Flex flexDir="column" justifyContent="space-between">
      <Header />
      <Flex minH="71vh" h="auto" justifyContent="center">
        <Flex
          maxW="1200px"
          m={[2, 0]}
          flexDir="column"
          w="100%"
          justifyContent="left"
        >
          <Heading size="lg" mt={3} mb={5}>
            Perguntas Frequentes
          </Heading>
          <Text></Text>
          <Stack direction="column" spacing={6}>
            {questions.map((item) => (
              <CardDePerguntas name={item.title}>
                {item.description.split("\n").map((text: any) => (
                  <>
                    <Text>{text}</Text>
                  </>
                ))}
              </CardDePerguntas>
            ))}
          </Stack>

          <Flex w="100%" mt={6}></Flex>
        </Flex>
      </Flex>

      <Footer />
    </Flex>
  );
};

export default PerguntasFrequentes;
