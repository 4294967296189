import { Flex } from "@chakra-ui/layout";
import { Text, Spinner } from "@chakra-ui/react";

export default function Loading() {
  return (
    <>
      <Flex h="100vh" justifyContent="center" alignItems="center">
        <Flex flexDir="column" alignItems="center">
          <Spinner thickness="4px" speed="0.65s" color="green.500" size="xl" />
          <Text mt={2}>Carregando...</Text>
        </Flex>
      </Flex>
    </>
  );
}
